import { Box } from "@mui/material";
import { Member } from "~/assets";
import { ButtonAdd } from "../../ButtonAdd/button-add";

export type TMembersHome = {
  totalNumbers: number;
};

function MembersHome({ totalNumbers }: TMembersHome) {
  return (
    <div className="info-card first-row-card margin-card">
      <div className="members-heading">
        <h3 className="home-active-licenses">MEMBERS</h3>
        <div className="person-icon-image">
          <Member />
        </div>
      </div>
      <h4 className="home-licenses-counter">{totalNumbers}</h4>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <div className="member-info-home">
          <p className="counter-active-home">{totalNumbers}</p>
          <p className="active-text-home">Active Since last 7 days</p>
        </div>
        {/* <ButtonAdd color="#1781E2"/> */}
      </Box>
    </div>
  );
}

export default MembersHome;
