import { Box, Grid, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { DeployBaasStepHeading } from "./DeployBaasStepHeading";
import { SelectBox } from "./SelectBox";
import { WizardContext } from "../../../../../context/wizardContext";

export const DeployBaasMode = () => {
  //@ts-ignore
  const { deployData, setDeployData } = useContext(WizardContext);

  const selectedMode = deployData.selectedMode;
  const selectedOption = deployData.selectedOption;

  const handleSelectMode = (mode: string) => {
    setDeployData({
      ...deployData,
      selectedMode: mode,
      buttonColor: "#1781E2",
    });
  };

  const handleSelectOption = (option: string) => {
    setDeployData({ ...deployData, selectedOption: option });
  };

  return (
    <Box>
      <DeployBaasStepHeading
        title="Deployment Mode"
        description="Choose the way your infrastrucutre will be managed"
      />
      <Typography
        fontFamily="Montserrat"
        fontWeight="600"
        color="#3E474D"
        fontSize={"13px"}
        sx={{
          paddingBottom: "31.41px",
        }}
      >
        Mode
      </Typography>
      <Grid container md={12}>
        <Grid item md={6}>
          <SelectBox
            heading="Provisioned"
            provisioner="By Multiledgers"
            handleSelect={() => {}}
            selected={selectedMode === "Mode provisioned"}
            enabled={false}
          />
        </Grid>
        <Grid item md={6}>
          <SelectBox
            heading="Imported"
            provisioner="By User"
            handleSelect={() => handleSelectMode("Mode imported")}
            selected={selectedMode === "Mode imported"}
            enabled={true}
          />
        </Grid>
      </Grid>
      <Typography
        fontFamily="Montserrat"
        fontWeight="600"
        color="#3E474D"
        fontSize={"13px"}
        sx={{
          paddingBottom: "31.41px",
          paddingTop: "31.41px",
        }}
      >
        Options
      </Typography>
      <Grid container md={12}>
        <Grid item md={6}>
          <SelectBox
            heading="Managed"
            provisioner="By Multiledgers"
            handleSelect={() => {}}
            selected={selectedOption === "Option 1"}
            enabled={false}
          />
        </Grid>
        <Grid item md={6}>
          <SelectBox
            heading="Self"
            provisioner="Managed"
            handleSelect={() => handleSelectOption("Option 2")}
            selected={selectedOption === "Option 2"}
            enabled={false}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
