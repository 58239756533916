import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { Box, DialogTitle, Divider, Typography } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { CloseModal } from '~/assets';
import { UseFormReturn, UseFormSetValue } from 'react-hook-form';
import { AddMembers } from './add-members';

interface IMemberFormView {
  firstName: string;
  lastName: string;
  jobTitle: string;
  phone: string;
  email: string;
  password: string;
  confirmPassword: string;
  permission: string
}

export type TModal = {
  title?: string;
  form: UseFormReturn<IMemberFormView>;
  onSubmit: () => void;
  setOpen: (value: boolean) => void;
  handleCloseRemove: () => void;
  open: boolean;
  memberFound: boolean;
  handleClose: any;
  idMember?: string;
  idOrg?: string;
  memberInformation: any;
  idMemberVerifed?: any;
  noMatchPass: boolean;
  tokenUser: string;
  loadingMember: boolean;
};

export const MemberFormView = ({
  title,
  form,
  onSubmit,
  open,
  handleClose,
  idMember,
  idOrg,
  memberInformation,
  handleCloseRemove,
  idMemberVerifed,
  noMatchPass,
  setOpen,
  tokenUser,
  loadingMember,
  memberFound,
}: TModal) => {
  const [memberEmailField, setMemberEmailField] = useState<boolean>(false);
  const [memberEmailState, setMemberEmailState] = useState<string | null>('');
  useEffect(() => {
    setMemberEmailState(localStorage.getItem('memberEmail'))
    if (memberEmailState && open) {
      setMemberEmailField(true);
      form.setValue('email', memberEmailState);
      localStorage.removeItem('memberEmail');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, memberEmailState])

  const defaultValue = {
    firstName: '',
    lastName: '',
    jobTitle: '',
    phone: '',
    email: '',
    password: '',
    confirmPassword: '',
    permission: 'stakeholder',
  }

  const [statusMember, setStatusMember] = useState<string>('')

  useEffect(() => {
    if ((open && !idMember) || !idMemberVerifed) {
      form.reset(defaultValue);
    }

    if (idMemberVerifed) {
      memberInformation = [ ...idMemberVerifed ][0];
    }
    const { attributes } = memberInformation;
    setStatusMember(memberInformation?.permission)
    if (idMember || idMemberVerifed) {
      form.setValue('firstName', memberInformation.firstName);
      form.setValue('lastName', memberInformation.lastName);
      form.setValue('email', memberInformation.email);
      form.setValue('phone', memberInformation.phone);
      form.setValue('jobTitle', memberInformation.jobTitle);
      form.setValue('permission', memberInformation.permission ? memberInformation.permission : 'stakeholder');
    }
  }, [memberInformation, idMemberVerifed])

  return (
    <Dialog
      aria-describedby="dialogo de confirmação"
      fullWidth
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          backgroundColor: '#F1F3F4',
          borderRadius: '18px',
        },
      }}
    >
      <DialogTitle
        sx={{
          '& .MuiTypography-root': {
            fontFamily: 'Montserrat',
            fontWeight: '500',
            fontSize: '25px',
            color: '#5F6368',
          }
        }}>
        <Typography fontWeight={600} fontSize={25} alignSelf="center">
          {title}
        </Typography>
      </DialogTitle>
      <Box
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: '16px',
          top: '20px',
          cursor: 'pointer',
          padding: '8px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CloseModal />
      </Box>
      <Divider />
      <DialogContent>
        <AddMembers
          form={form}
          onSubmit={onSubmit}
          idMember={idMember}
          idOrg={idOrg}
          handleCloseRemove={handleCloseRemove}
          idMemberVerifed={idMemberVerifed}
          noMatchPass={noMatchPass}
          tokenUser={tokenUser}
          loadingMember={loadingMember}
          statusMember={statusMember}
          memberEmailFiel={memberEmailField}
        />
      </DialogContent>
    </Dialog>
  );
};
