import { Box, LinearProgress, Typography } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { DeployStepHeading } from "../../../components/Platform/Deploy/DeployWizard/WizardSteps/DeployStepHeading";
import { ProcessStepper } from "./ProcessStepper/ProcessStepper";
import { WizardContext } from "../../../context/wizardContext";
import { OrderService } from "../../../services/order.service";

interface Props {
  tokenUser: string;
}

const orderService = new OrderService();

export const DeployProcess = ({ tokenUser }: Props) => {
  let INITIAL_STATE = 0;
  const [progress, setProgress] = useState();
  const [processPercent, setProcessPercent] = useState(0);
  const [doneArray, setDoneArray] = useState([]);

  const [error, setError] = useState(false);
  const [errorTicket, setErrorTicket] = useState("");

  // @ts-ignore
  const { deployData, setDeployData } = useContext(WizardContext);

  const [teste] = useState(false);

  useEffect(() => {
    const order = {
      resourceGroup: {
        protocol: "corda",
        version: "1.0",
        stack: "large",
      },
      vms: deployData.vms,
      enterpriseId: deployData.enterpriseId,
      enterpriseWallet: "0x000000000000000000000000000000000000dEaD",
      organizationId: deployData.orgId,
      licenseId: deployData.licenseId,
      signedTx: deployData.signedTx,
      networkName: deployData.networkName
    };

    const createOrder = async (order: any) => {
      const orderRes = await orderService.createOrder(order, tokenUser);
      return orderRes.data;
    };

    const createIdsArray = async (order: any) => {
      const orderRes = await createOrder(order);
      return orderRes;
    };

    const fetchStatus = async (idsArray: any) => {
      const orderStatusRes = await orderService.fetchOrderStatus(
        idsArray,
        tokenUser
      );
      return orderStatusRes.data;
    };

    const loopStatus = async (idsArray: any) => {
      let intervalId = setInterval(() => {
        fetchStatus(idsArray).then((resposta) => {
          setProgress(resposta.jobs);

          const resp = resposta.jobs.every((el: string) => el !== 'running' && el !== 'pending');
          setDeployData({...deployData, statusButtonDone: resp })

          if (resposta.jobs.every((p: any) => p === "successful")) {
            clearInterval(intervalId);
          }

          if (resposta.jobs.some((p: any) => p === "failed" || p === "error")) {
            setErrorTicket(resposta.supportTicket);
            setError(true);

            resposta.jobs.forEach((p: any, index: number) => {
              if (p === "failed" || p === "error") {
                setProcessPercent(index * 25);
              }
            });

            clearInterval(intervalId);
          }

          let count = resposta.jobs.filter(
            (status: any) => status === "successful"
          ).length;

          // Calculo para generalizar os steps 
          const playbookProgress: number = (count / resposta.jobs.length) * 75;

          const finalProgress = playbookProgress + INITIAL_STATE;
          setProcessPercent(finalProgress);
        });
      }, 10000);
    };

    const requests = async (order: any) => {
      const idsArray = await createIdsArray(order);
      setTimeout(() => {
        if(INITIAL_STATE === 0) {

          setProcessPercent(25);
          INITIAL_STATE = 25;
        }
      }, 5000)
      const resultLoopSatus = await loopStatus(idsArray);
      return resultLoopSatus;
    };

    requests(order);
  }, []);

  return (
    <>
      <Box>
        <DeployStepHeading
          title="Deploying your new Network"
          description="This usualy takes between 4 and 8 minutes"
        />
        <Box sx={{ paddingTop: "30px", paddingBottom: "30px" }}>
          <LinearProgress
            variant="determinate"
            value={processPercent}
            color="info"
          />
        </Box>
        <ProcessStepper
          percent={processPercent}
          error={error}
          errorTicket={errorTicket}
        />
        <Box sx={{ paddingTop: "70px" }}>
          <Typography
            fontFamily="Montserrat"
            fontSize="12px"
            fontWeight="500"
            lineHeight="15px"
            letterSpacing="1px"
            textAlign="left"
          >
            After completing the deployment, you can check out your network using the monitoring services.
          </Typography>
        </Box>
      </Box>
    </>
  );
};
