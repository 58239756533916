import nodeConnectIcon from "~/assets/nodeConnectIcon.png";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Box, Typography } from "@mui/material";
import { Resource } from "~/assets";
// import { LinearProgress } from '@mui/material';
export type TNodes = {
  sizeResource: number;
};

function Nodes({ sizeResource }: TNodes) {
  return (
    <div className="info-card first-row-card">
      <div className="members-heading">
        <h3 className="home-active-licenses">RESOURCES</h3>
        <div className="node-icon-image">
          <Resource />
        </div>
      </div>
      <h4 className="home-licenses-counter">{sizeResource}</h4>
      <div className="linear-progress-bar">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 9,
          }}
        >
          <Typography
            fontFamily="Montserrat"
            fontSize={11.5}
            fontWeight="500"
            color="#48535C"
            marginLeft={"6px"}
          >
            0%
          </Typography>
          <Typography
            fontFamily="Montserrat"
            fontSize="9.17px"
            fontWeight="500"
            color="#7B8A93"
          >
            Online
          </Typography>
          <Typography
            fontFamily="Montserrat"
            fontSize={11.5}
            fontWeight="500"
            color="#48535C"
            marginRight={"6px"}
          >
            100%
          </Typography>
        </div>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" value={100} />
        </Box>
      </div>
    </div>
  );
}

export default Nodes;
