// Core
import React, { FC } from 'react';
import { Button, ButtonProps, colors as colorsM } from '@mui/material';

// Assets
import { fonts, colors, border } from '../../style/themes';

export const ButtonOutlined: FC<ButtonProps> = ({ children, sx, ...props }) => {
    return (
        <Button
            sx = {{
                padding:      '4px 32px',
                borderRadius: border.radius.quaternary,
                borderColor:  colors.primary[ 700 ],
                fontSize:     fonts.size[ 600 ],
                fontFamily:   fonts.family.primary,
                fontWeight:   fonts.weight.secondary,
                color:        colors.primary[ 700 ],
                '&:hover':    {
                    color: colorsM.blue[ 600 ],
                },
                ...sx,
            }}
            { ...props }
            variant = 'outlined'>
            {children}
        </Button>
    );
};
