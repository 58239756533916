import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { profileUserStatus } from "../../../stores/profileUserStatus/profileUser.actions";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "../Platform.css";
// @ts-ignore
import Header from "../Header/Header.tsx";
// @ts-ignore
import { StyledOrgManagementContainer } from "./Styles/OrgManegementContainer.styled";
import returnLogo from "~/assets/returnLogo.png";
import nodeIcon from "~/assets/nodeIcon.png";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { keycloak } from "../../../utils/Keycloak";
import addIcon from "~/assets/Add.png";

import { CircularProgress, SelectChangeEvent, Tooltip } from "@mui/material";

import { OrganizationService } from "../../../services/organization.service";
import { MemberService } from "../../../services/member.service";
import { LicenseService } from "../../../services/license.service";
import { UserService } from "../../../services/user.services";

import peviewQuickFinancial from "~/assets/financial_comming.png";
import previewOrgProcess from "~/assets/process_comming.png";
import previewNodes from "~/assets/nodes_comming.png";
import {
  MemberFormContainer,
  NexMember,
  OrgFormContainer,
} from "../../moda-generic";
import { useSnackbar } from "notistack";
import {
  Select,
  styled,
  InputBase,
  MenuItem,
  Box,
  Grid,
  Typography,
  Card,
  OutlinedInput,
  InputAdornment,
  Button,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FilterRange, FindFilter } from "~/assets";
import QuickFinancial from "../QuickFinancial/QuickFinancial";
import NetworkGraph from "../Network/network";
import { FinancialService } from "../../../services/financial.service";

interface TOrganizationList {
  id: string;
  name: string;
}
interface IOrgInformation {
  id: string;
  city: Array<string>;
  country: Array<string>;
  email: Array<string>;
  sponsorName: Array<string>;
}
interface IMember {
  id: string;
  firstName: string;
  lastName: string;
}

const OrgManagement = ({ tokenUser, orgData, userName, user }: any) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { idOrg } = location?.state || "";
  const USER_ID = localStorage.getItem("idUser") || "";
  const [organizationNetworks, setOrganizationNetworks] = useState<any[]>([]);

  const [organizationList, setOrganizationList] = useState<
    Array<TOrganizationList>
  >([]);
  const [orgInformation, setOrgInformation] = useState<IOrgInformation>();

  const [licenseOrg, setLicenseOrg] = useState<Array<any>>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [successMember, setSuccessMember] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openEditMember, setOpenEditMember] = useState<boolean>(false);
  const [dataMember, setDataMember] = useState<Array<any>>([]);
  const [dataMemberOri, setDataMemberOri] = useState<Array<any>>([]);
  const [successOrg, setSuccessOrg] = useState<boolean>(false);
  const [idMember, setIdMember] = useState<string>();
  const [idOrganization, setIdOrganization] = useState<string | any>(idOrg);
  const [idUserEnterprise, setIdUserEnterprise] = useState<string>("");
  const [openMember, setOpenMember] = useState<boolean>(false);
  const [newMember, setNewMember] = useState<any>("member");
  const [memberFound, setMemberFound] = useState<boolean>(false);
  const [tokenValidate, setTokenValidate] = useState<string>(tokenUser);
  const [loadingMember, setLoadingMember] = useState<boolean>(true);
  const [loadingOrgInfo, setLoadinOrgInfo] = useState<boolean>(true);
  const [loadingOrgLicenses, setLoadinOrgLicenses] = useState<boolean>(true);
  const [pageNotFound, setPageNotFound] = useState<boolean>(false);
  const [dataTableQUickFinancial, setDataTableQUickFinancial] = useState<any>();

  const organizationService = new OrganizationService();
  const memberService = new MemberService();
  const licenseService = new LicenseService();
  const userService = new UserService();
  const financialService = new FinancialService();
  const { enqueueSnackbar } = useSnackbar();

  const parentRef = useRef(null);
  const [parentDimensions, setParentDimensions] = useState({ width: 745, height: 398 });


  useEffect(()=>{
    const getEnterpriseNets = async () => {
      if (orgInformation && orgInformation.id) {
        const enterpriseNet = await organizationService.getOrganizationNetworks(orgInformation.id,tokenValidate);
        if (enterpriseNet.status == 200){
          setOrganizationNetworks(enterpriseNet.data)
        }
      }
    }

    if (orgInformation && orgInformation.id && tokenUser) {
      getEnterpriseNets()
    }

  }, [orgInformation])

  useLayoutEffect(() => {
    const measureParent = () => {
      if (parentRef.current) {
        const { offsetWidth, offsetHeight } = parentRef.current;
        setParentDimensions({ width: offsetWidth, height: offsetHeight });
      }
    };

    measureParent(); // Initial measurement

    // Re-measure if the window is resized
    window.addEventListener('resize', measureParent);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', measureParent);
    };
  }, []);

  const labels = [
    " 10 Jan",
    " 14 Fev",
    " 16 Marc",
    " 07 Apr",
    " 11 May",
    " 01 Augst",
    " 02 SET",
    " 14 Nov",
    " 24 Dez",
    " 12 Mar",
  ];
  const dataTable = [
    88303, 83333, 82333, 87333, 81100, 85100, 80100, 90100, 80100, 92100,
  ];

  const permission: any = useSelector((state: any) => state.profileUser);

  let owner: any;

  try {
    owner = localStorage.getItem("owner") !== "";
  } catch (error) {
    owner = false;
  }

  useEffect(() => {
    const getDataQuickFinancial = async () => {
      try {
        if (!orgInformation) return;
        const respDataQuickFinancial = await financialService.getQuickFinancialOrg(tokenUser, orgInformation.id);
        if (respDataQuickFinancial.status === 200) {
          setDataTableQUickFinancial(respDataQuickFinancial.data.reverse());
        }
      } catch (error) {
        console.error(error);
      }
    };
    getDataQuickFinancial();
  }, [orgInformation]);

  useEffect(() => {
    if (newMember === "") {
      setNewMember("member");
    }
  }, [newMember]);

  useEffect(() => {
    if (tokenUser) {
      setTokenValidate(tokenUser);
    }
  }, [tokenUser]);

  useEffect(() => {
    if (orgData && !idOrg) {
      setIdOrganization(orgData[0]?.id);
    }
  }, [orgData]);

  const getInformationOrg = async (idorgSelect?: string) => {
    try {
      if (idorgSelect || idOrganization) {
        setLoadinOrgInfo(true);
        const respOrgInformation =
          await organizationService.getOrganizationInfo(
            idorgSelect || idOrganization,tokenValidate
          );
        if (respOrgInformation.data) {
          dispatch(profileUserStatus(respOrgInformation.data.permission));

          const attributes = respOrgInformation.data;
          setLoadinOrgInfo(false);
          if (Object.keys(attributes).length) {
            setOrgInformation(attributes);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getLicenseOrganization = async (idorgLicense?: string) => {
    try {
      if (idorgLicense || idOrganization) {
        setLoadinOrgLicenses(true);
        const respGetLicense = await licenseService.getOrganizationLicense(
          tokenUser,
          idorgLicense || idOrganization
        );
        if (respGetLicense.status === 200) {
          setLoadinOrgLicenses(false);
          setLicenseOrg(respGetLicense.data);
        }
      }
    } catch (error: any) {
      if ([404, 403].includes(error?.response?.data?.statusCode)) {
        enqueueSnackbar("error to get license", {
          variant: "error",
        });
        setLoadinOrgLicenses(false);
      }
    }
  };

  const getUserEnteprise = async (idUser: string) => {
    try {
      if (idUser) {
        const resUserEnterprise = await memberService.getUserEnterprise(
          idUser,
          tokenUser
        );
        if (resUserEnterprise.status === 200) {
          const { id } = resUserEnterprise.data[0];
          setIdUserEnterprise(id);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getOrgList = async () => {
    try {
      if (USER_ID && tokenValidate) {
        const respOrganizations = await userService.getUserOrgList(
          USER_ID,
          tokenValidate
        );
        if (respOrganizations.status === 401) {
          const logoutUser = async () => {
            localStorage.removeItem("owner");
            localStorage.removeItem("idEnterprise");
            localStorage.removeItem("idUser");
            localStorage.removeItem("enterpriseId");
            localStorage.removeItem("userWallet");
            localStorage.removeItem("entepriseServiceAuthId");
            localStorage.removeItem("idUser");
            localStorage.removeItem("ow");
            await keycloak.logout();
          };
          logoutUser();
        }
        if (respOrganizations.data.length) {
          await getUserEnteprise(USER_ID);
          setOrganizationList(respOrganizations.data);
        }
      }
    } catch (error) {}
  };

  const getMembersOrg = async (idorgSelect?: string) => {
    try {
      if (idorgSelect || idOrganization) {
        setLoadingMember(true);
        const respMemberOrg = await memberService.getMemberOrganization(
          idorgSelect || idOrganization,
          tokenValidate
        );
        if (respMemberOrg.status === 401) {
          const logoutUser = async () => {
            localStorage.removeItem("owner");
            localStorage.removeItem("idEnterprise");
            localStorage.removeItem("idUser");
            localStorage.removeItem("enterpriseId");
            localStorage.removeItem("userWallet");
            localStorage.removeItem("entepriseServiceAuthId");
            localStorage.removeItem("idUser");
            localStorage.removeItem("ow");
            await keycloak.logout();
          };
          logoutUser();
        }
        if (respMemberOrg.status === 403) {
          enqueueSnackbar(respMemberOrg.statusText, {
            variant: "error",
          });
          setLoadingMember(false);
          setPageNotFound(true);
        }
        if (respMemberOrg.data.members.length) {
          setDataMember(respMemberOrg.data.members);
          setDataMemberOri(respMemberOrg.data.members);
          setSuccessMember(false);
          setLoadingMember(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const openModalEdit = (id: string) => {
    setOpenEditMember(true);
    setIdMember(id);
  };

  const filterMembers = (event: any) => {
    const nameMember = event.target.value;

    const memberFilter = dataMember.filter((fil: any) => {
      return fil.firstName.toLowerCase().indexOf(nameMember.toLowerCase()) > -1;
    });

    memberFilter.length && nameMember
      ? setDataMember(memberFilter)
      : setDataMember(dataMemberOri);
  };

  useEffect(() => {
    getOrgList();
    getInformationOrg();
    getLicenseOrganization();
    getMembersOrg();
  }, [USER_ID, idOrganization]);

  const changeOrganization = (event: SelectChangeEvent) => {
    const idOrgSelect = event.target.value;
    setIdOrganization(idOrgSelect);
    getInformationOrg(idOrgSelect);
    getLicenseOrganization(idOrgSelect);
    getMembersOrg(idOrgSelect);
  };

  useEffect(() => {
    if (successOrg) {
      getOrgList();
      getInformationOrg();
      setSuccessOrg(false);
    }
  }, [successOrg]);

  useEffect(() => {
    if (successMember) {
      getMembersOrg();
    }
  }, [successMember]);

  const BootstrapInput = styled(InputBase)(() => ({
    "& .MuiInputBase-input": {
      display: "block",
      justifyContent: "center",
      textAlign: "center",
      borderRadius: "30px",
      backgroundColor: "#FAFAFA",
      border: "1px solid #979797",
      fontWeight: "600",
      fontFamily: "Montserrat",
      fontSize: "24px",
      color: "#5F6368",
      letterSpacing: ".11px",
      paddingTop: "14px",
      paddingLeft: "4px",
      "&:focus": {
        borderRadius: "30px",
      },
    },
    ".MuiSvgIcon-root": {
      right: "10.5px",
    },
  }));

  return (
    <div className="platform-body">
      <Header userName={userName} />
      <div className="platform-dropdown"></div>
      <StyledOrgManagementContainer>
        <Grid
          container
          md={12}
          sx={{ margin: "73px auto 30px", padding: "0 153px" }}
        >
          <Grid item md={12} sx={{ marginBottom: "22px" }}>
            <div className="org-management-heading">
              <h2>{`${
                userName
                  ? userName.charAt(0).toUpperCase() + userName.slice(1)
                  : "Error"
              }'s view`}</h2>
            </div>
          </Grid>
          <Grid item md={3.28}>
            <Box
              sx={{
                height: "100%",
                margin: "0px",
              }}
            >
              <div className="org-management-body-left">
                <div className="org-management-info-container">
                  <Grid container md={12} xs={12}>
                    <Grid item md={12} xs={12}>
                      <Box
                        sx={{
                          padding: "45px 11px 59px 29px",
                        }}
                      >
                        <Select
                          fullWidth
                          defaultValue={1}
                          input={<BootstrapInput />}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          variant="standard"
                          value={idOrganization}
                          onChange={(e) => changeOrganization(e)}
                          endAdornment={
                            <InputAdornment
                              position="end"
                              sx={{
                                position: "absolute",
                                right: "35px",
                              }}
                            >
                              <span
                                style={{
                                  transform: "rotate(180deg)",
                                  display: "block",
                                  height: "0px",
                                  borderRight: "1px solid #979797",
                                }}
                              ></span>
                            </InputAdornment>
                          }
                          sx={{
                            ":before": {
                              borderRight: "1px solid #A2CBFE",
                              marginRight: "50px",
                              height: "100%",
                            },
                          }}
                        >
                          {organizationList.map(({ id, name }) => (
                            <MenuItem key={id} value={id}>
                              {name.length > 40
                                ? name.slice(4).substring(0, 40) + "..."
                                : name.slice(4)}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    </Grid>
                  </Grid>
                  {loadingOrgInfo ? (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          paddingTop: "10px",
                          margin: "auto",
                          width: "150px",
                          height: "142px",
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    </>
                  ) : (
                    <div className="org-management-info-body">
                      <div>
                        <div className="info-container">
                          <Box sx={{ marginRight: "25px", width: "150px" }}>
                            <Typography
                              fontFamily="Montserrat"
                              fontWeight={500}
                              fontSize="13px"
                              lineHeight="20px"
                              color="#5F6368"
                            >
                              SPONSOR:
                            </Typography>
                            <Typography
                              fontFamily="Montserrat"
                              fontWeight={500}
                              fontSize="13px"
                              lineHeight="20px"
                              color="#3E474D"
                            >
                              {orgInformation?.sponsorName}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              fontFamily="Montserrat"
                              fontWeight={500}
                              fontSize="13px"
                              lineHeight="20px"
                              color="#5F6368"
                            >
                              COUNTRY:
                            </Typography>
                            <Typography
                              fontFamily="Montserrat"
                              fontWeight={500}
                              fontSize="13px"
                              lineHeight="20px"
                              color="#3E474D"
                            >
                              {orgInformation?.country}
                            </Typography>
                          </Box>
                        </div>
                        <div className="info-container">
                          <Box
                            sx={{
                              marginRight: "25px",
                              // wordBreak: "break-all",
                              width: "150px",
                            }}
                          >
                            <Typography
                              fontFamily="Montserrat"
                              fontWeight={500}
                              fontSize="13px"
                              lineHeight="20px"
                              color="#5F6368"
                            >
                              E-MAIL:
                            </Typography>
                            {orgInformation &&
                            orgInformation?.email.length > 15 ? (
                              <Tooltip
                                title={orgInformation?.email}
                                arrow
                                placement="left"
                              >
                                <Typography
                                  fontFamily="Montserrat"
                                  fontWeight={500}
                                  height="20px"
                                  fontSize="13px"
                                  lineHeight="20px"
                                  color="#3E474D"
                                  width="150px"
                                  overflow={"hidden"}
                                  textOverflow="ellipsis"
                                >
                                  {orgInformation?.email}
                                </Typography>
                              </Tooltip>
                            ) : (
                              <Typography
                                fontFamily="Montserrat"
                                fontWeight={500}
                                height="20px"
                                fontSize="13px"
                                lineHeight="20px"
                                color="#3E474D"
                                width="150px"
                                overflow={"hidden"}
                                textOverflow="ellipsis"
                              >
                                {orgInformation?.email}
                              </Typography>
                            )}
                          </Box>
                          <div>
                            <Box>
                              <Typography
                                fontFamily="Montserrat"
                                fontWeight={500}
                                fontSize="13px"
                                lineHeight="20px"
                                color="#5F6368"
                              >
                                CITY:
                              </Typography>
                              <Typography
                                fontFamily="Montserrat"
                                fontWeight={500}
                                fontSize="13px"
                                lineHeight="20px"
                                color="#3E474D"
                              >
                                {orgInformation?.city}
                              </Typography>
                            </Box>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="edit-settings">
                    <Button
                      onClick={() => setOpenEdit(true)}
                      sx={{
                        color: "#2e57a3",
                        fontWeight: "700",
                        fontSize: "12px",
                        fontFamily: "Montserrat",
                        textDecoration: "underline",
                        textTransform: "capitalize",
                      }}
                    >
                      Edit Settings
                    </Button>
                  </div>
                  <OrgFormContainer
                    user={user}
                    idOrg={idOrganization}
                    title="Edit Organization"
                    open={openEdit}
                    setOpen={setOpenEdit}
                    openEdit={openEdit}
                    setSuccessOrg={setSuccessOrg}
                    successOrg={successOrg}
                    ownerId={USER_ID}
                    enterpriseId={idUserEnterprise}
                    tokenUser={tokenValidate}
                  />
                  {owner && (
                    <div className="return-logo">
                      <Link to={"/"}>
                        <button className="back-to-org-button-orgs">
                          <img src={returnLogo}></img>
                          <Typography
                            color="#fff !important"
                            fontWeight="600"
                            fontSize="10px"
                            fontFamily="Montserrat"
                            lineHeight="12px"
                            marginLeft="10px"
                          >
                            Back
                          </Typography>
                        </button>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </Box>
          </Grid>
          <Grid item md={8.72}>
            <Grid
              container
              sx={{
                marginBottom: "22px",
              }}
              md={12}
              gap={3}
              wrap="nowrap"
            >
              <Grid item md={7.5}>
                <div
                    ref={parentRef}
                    style={{
                      position: "relative",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <NetworkGraph height={parentDimensions.height} width={parentDimensions.width} data={organizationNetworks} />
                  </div>
              </Grid>

              <Grid item md={4.3}>
                <div className="org-management-body-right-members">
                  <NexMember
                    title="Add Member"
                    open={open}
                    setOpen={setOpen}
                    setOpenMember={setOpenMember}
                    openMember={openMember}
                    setNewMember={setNewMember}
                    setMemberFound={setMemberFound}
                    newMember={newMember}
                    tokenUser={tokenValidate}
                    idOrg={idOrganization}
                  />
                  <MemberFormContainer
                    title="Add Member"
                    open={openMember}
                    setOpen={setOpenMember}
                    setSuccessMember={setSuccessMember}
                    successMember={successMember}
                    idOrg={idOrganization}
                    idMember={""}
                    memberFound={memberFound}
                    idMemberVerifed={newMember}
                    tokenUser={tokenValidate}
                  />
                  <div
                    className="add-member-button"
                    onClick={() => setOpen(true)}
                  >
                    <img
                      src={addIcon}
                      alt="add member icon"
                      className="add-icon"
                    />
                  </div>
                  <div className="org-management-body-right-members-title">
                    <h2
                      style={{
                        textAlign: "left",
                        marginBottom: "16px",
                        fontWeight: "700",
                      }}
                    >
                      MEMBERS
                    </h2>
                  </div>
                  <div className="counter-search">
                    <div className="counter">
                      <p>0{dataMember.length}</p>
                    </div>
                    <OutlinedInput
                      type="search"
                      autoComplete="off"
                      onChange={(event: any) => filterMembers(event)}
                      startAdornment={
                        <InputAdornment position="start">
                          <FilterRange />
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <FindFilter />
                        </InputAdornment>
                      }
                      sx={{
                        marginTop: "9px",
                        borderRadius: "31px",
                        marginRight: "22px",
                        height: "24.5px",
                        paddingInlineEnd: "10px",
                        paddingInlineStart: "9px",
                        inlineSize: "270px",
                        marginInline: "0px",
                        backgroundColor: "#fff",
                      }}
                    />
                  </div>
                  <div className="org-management-body-right-members-body">
                    {dataMember.length > 8 && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          position: "absolute",
                          right: "15px",
                          marginTop: "-22px",
                        }}
                      >
                        <KeyboardArrowUpIcon />
                      </Box>
                    )}
                    {loadingMember ? (
                      <>
                        <Box
                          sx={{
                            // display: "flex",
                            // position: "absolute",
                            // left: "130px",
                            // bottom: "10px",
                            margin: "auto",
                          }}
                        >
                          <CircularProgress />
                        </Box>
                        <Box
                          sx={{
                            // display: "flex",
                            // position: "absolute",
                            // left: "130px",
                            // bottom: "10px",
                            margin: "auto",
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      </>
                    ) : (
                      <>
                        {dataMember &&
                          dataMember.map((member) => (
                            <>
                              <div
                                key={member.id}
                                className="member-container"
                                onClick={() => openModalEdit(member.id)}
                              >
                                <Stack direction="row">
                                  <Avatar
                                    alt="profile"
                                    sx={{
                                      bgcolor: "#1781E2",
                                      height: "37.33px",
                                      width: "37.33px",
                                    }}
                                  >
                                    {member.firstName[0].toUpperCase()}
                                  </Avatar>
                                </Stack>
                                <div className="member-info">
                                  <div className="member-name">
                                    <p>
                                      {`${member.firstName[0].toUpperCase()}. ${member.lastName
                                        .split(" ")
                                        [
                                          member.lastName.split(" ").length - 1
                                        ].charAt(0)
                                        .toUpperCase()}${member.lastName
                                        .split(" ")
                                        [
                                          member.lastName.split(" ").length - 1
                                        ].slice(1)}`}
                                    </p>
                                  </div>
                                  <div className="member-title">
                                    <p>
                                      {member.jobTitle
                                        ? member.jobTitle[0].toUpperCase() +
                                          member.jobTitle.slice(1)
                                        : "Failed"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                      </>
                    )}
                    <MemberFormContainer
                      title="Edit Member"
                      open={openEditMember}
                      setOpen={setOpenEditMember}
                      idOrg={idOrganization}
                      memberFound={true}
                      idMember={idMember}
                      setSuccessMember={setSuccessMember}
                      successMember={successMember}
                      tokenUser={tokenValidate}
                    />
                  </div>
                  {dataMember.length > 8 && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        position: "absolute",
                        right: "15px",
                        marginTop: "-5px",
                      }}
                    >
                      <KeyboardArrowDownIcon />
                    </Box>
                  )}
                </div>
              </Grid>
            </Grid>

            <Grid container md={12} gap={3} wrap="nowrap">
              <Grid item md={7.5}>
                  <QuickFinancial
                    dataTable={dataTableQUickFinancial ? dataTableQUickFinancial : []}
                    orgPage={true}
                  />
              </Grid>

              <Grid item md={4.3}>
                <div className="org-management-body-right-bottom">
                  <div className="org-licenses">
                    <div>
                      <h2
                        style={{
                          fontSize: "1rem",
                          marginLeft: "23px",
                          textAlign: "left",
                          fontWeight: "700",
                        }}
                      >
                        ACTIVE LICENSES
                      </h2>
                      {licenseOrg.length > 3 && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            position: "absolute",
                            right: "17px",
                          }}
                        >
                          <KeyboardArrowUpIcon />
                        </Box>
                      )}
                      {loadingOrgLicenses ? (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              paddingTop: "10px",
                              margin: "auto",
                              width: "150px",
                              height: "142px",
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        </>
                      ) : (
                        <div className="licenses-general-container">
                          <div>
                            <div className="licenses-counter">
                              {licenseOrg.length}
                            </div>
                          </div>
                          <div className="licenses-list">
                            {licenseOrg &&
                              licenseOrg.map((license) => (
                                <Card
                                  key={license.id}
                                  sx={{
                                    backgroundColor: "#FAFAFA",
                                    borderRadius: "11.5px",
                                    marginBottom: "7px",
                                    boxShadow: 0,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      padding: "7px",
                                      alignItems: "center",
                                      position: "relative",
                                      height: "38px",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        marginRight: "24px",
                                        alignItems: "center",
                                        paddingLeft: "15px",
                                      }}
                                    >
                                      <img
                                        src={nodeIcon}
                                        alt="node icon"
                                        height={"16.5px"}
                                        width={"12.5px"}
                                      />
                                      <Typography
                                        marginLeft="12px"
                                        fontFamily={"Montserrat"}
                                        fontWeight="600"
                                        maxWidth={"128px"}
                                        fontSize="12px"
                                        lineHeight="35px"
                                        fontStyle="normal"
                                        color={"#7B8A93"}
                                        width="128px"
                                        overflow="hidden"
                                        textOverflow={"ellipsis"}
                                      >
                                        {license?.name}
                                      </Typography>
                                      <Typography
                                        marginRight="120px"
                                        fontWeight="600"
                                        fontFamily={"Montserrat"}
                                        fontSize="10px"
                                        fontStyle="normal"
                                        color="#1781E2"
                                        width={"75px"}
                                        overflow="hidden"
                                        textOverflow={"ellipsis"}
                                      >
                                        {license?.title}
                                      </Typography>
                                      <span
                                        style={{
                                          color: "#48535C",
                                          fontSize: "24px",
                                          transform: "rotate(90deg)",
                                          cursor: "pointer",
                                          position: "absolute",
                                          right: "-5px",
                                          letterSpacing: "2px",
                                        }}
                                      >
                                        ...
                                      </span>
                                    </Box>
                                  </Box>
                                </Card>
                              ))}
                          </div>
                        </div>
                      )}
                      {licenseOrg.length > 3 && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            position: "absolute",
                            right: "17px",
                            marginTop: "-3px",
                          }}
                        >
                          <KeyboardArrowDownIcon />
                        </Box>
                      )}
                    </div>
                  </div>
                </div>
              </Grid>

              {/* <Grid item md={4.3}>
                <div className="org-process">
                  <img
                    src={previewOrgProcess}
                    className="preview-org-process"
                    alt=""
                  ></img>
                </div>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </StyledOrgManagementContainer>
    </div>
  );
};

export default OrgManagement;
