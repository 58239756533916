import React, { FC } from 'react';

import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { fonts, colors } from '../../style/themes';

import { IDataPage5 } from '../../type';
import { Button, Tooltip } from '@mui/material';

interface IProps {
  userData: IDataPage5 | null;
}

export const DataProfile: FC<IProps> = (props) => {
  const { userData } = props;
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        padding: '0 39px',
      }}
    >
      <Grid container columnSpacing={'30px'} sx={{ marginLeft: '30px' }}>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: fonts.size[300],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight.secondary,
              color: colors.secondary[500],
              marginLeft: '-60px',
              textAlign: 'start',
              textTransform: 'uppercase',
            }}
          >
            INFORMATION
          </Typography>
        </Grid>
        <Grid item sm={6} sx={{ pt: '36px' }}>
          <Typography
            sx={{
              fontSize: fonts.size[100],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight[500],
              color: colors.primary[800],
              textAlign: 'start',
              mb: '34px',
            }}
          >
            First Name
          </Typography>
          <Typography
            sx={{
              fontSize: fonts.size[200],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight[500],
              color: colors.secondary[700],
              textAlign: 'start',
              pb: '34px',
            }}
          >
            {userData?.data4 || '-'}
          </Typography>
        </Grid>
        <Grid item sm={6} sx={{ pt: '36px' }}>
          <Typography
            sx={{
              fontSize: fonts.size[100],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight[500],
              color: colors.primary[800],
              textAlign: 'start',
              mb: '34px',
            }}
          >
            Last Name
          </Typography>
          <Typography
            sx={{
              fontSize: fonts.size[200],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight[500],
              color: colors.secondary[700],
              textAlign: 'start',
              pb: '34px',
            }}
          >
            {userData?.data5 || '-'}
          </Typography>
        </Grid>
        <Grid item sm={6}>
          <Typography
            sx={{
              fontSize: fonts.size[100],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight[500],
              color: colors.primary[800],
              textAlign: 'start',
              mb: '34px',
            }}
          >
            Job Title
          </Typography>
          <Typography
            sx={{
              fontSize: fonts.size[200],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight[500],
              color: colors.secondary[700],
              textAlign: 'start',
              pb: '34px',
            }}
          >
            {userData?.data6 || '-'}
          </Typography>
        </Grid>
        <Grid item sm={6}>
          <Typography
            sx={{
              fontSize: fonts.size[100],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight[500],
              color: colors.primary[800],
              textAlign: 'start',
              mb: '34px',
            }}
          >
            Mobile
          </Typography>
          <Typography
            sx={{
              fontSize: fonts.size[200],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight[500],
              color: colors.secondary[700],
              textAlign: 'start',
              pb: '34px',
            }}
          >
            {userData?.data7 || '-'}
          </Typography>
        </Grid>
        <Grid item sm={12}>
          <Typography
            sx={{
              fontSize: fonts.size[100],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight[500],
              color: colors.primary[800],
              textAlign: 'start',
              mb: '34px',
            }}
          >
            Mail
          </Typography>
          <Typography
            sx={{
              fontSize: fonts.size[200],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight[500],
              color: colors.secondary[700],
              textAlign: 'start',
              pb: '34px',
            }}
          >
            {userData?.data8 || '-'}
          </Typography>
        </Grid>
        <Grid item sm={6}>
          <Typography
            sx={{
              fontSize: fonts.size[100],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight[500],
              color: colors.primary[800],
              textAlign: 'start',
              mb: '34px',
            }}
          >
            Password
          </Typography>
          <Typography
            sx={{
              fontSize: fonts.size[200],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight[500],
              color: colors.secondary[700],
              textAlign: 'start',
              pb: '34px',
            }}
          >
            {userData?.data9 || '-'}
          </Typography>
        </Grid>
        <Grid item sm={6}>
          <Typography
            sx={{
              fontSize: fonts.size[100],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight[500],
              color: colors.primary[800],
              textAlign: 'start',
              mb: '34px',
            }}
          >
            Confirm Password
          </Typography>
          <Typography
            sx={{
              fontSize: fonts.size[200],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight[500],
              color: colors.secondary[700],
              textAlign: 'start',
              pb: '34px',
            }}
          >
            {userData?.data10 || '-'}
          </Typography>
        </Grid>

        <Grid
          container
          item
          xs={12}
          sx={{ justifyContent: 'end', margin: '10px 0 0 -60px' }}
        >
          <Tooltip title="Unable to edit information yet" placement='top' arrow>
            <Grid item sx={{}}>
                <Button
                  disabled
                  onClick={() => navigate('edit')}
                  variant="contained"
                  sx={{
                    fontSize: fonts.size[200],
                    fontFamily: fonts.family.secondary,
                    fontWeight: fonts.weight[600],
                    padding: '4px 0',
                    width: '169px',
                    height: '40px',
                    mb: '39px',
                    borderRadius: '10px',
                    textTransform: 'initial',
                  }}
                >
                  Edit Info
                </Button>
            </Grid>
          </Tooltip>
        </Grid>
      </Grid>
    </Box>
  );
};
