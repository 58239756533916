import React, { FC, useEffect, useState } from 'react';
import { FinancialService } from '../../../services/financial.service';
import { removeLocalStorage } from "../../../generalLocalStorage";
import { useSnackbar } from "notistack";

import CircularProgress from '@mui/material/CircularProgress';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { keycloak } from "../../../utils/Keycloak";

// import "@fontsource/montserrat";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import BarChart from '../../../components/barChart';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { fonts, colors } from '../../../style/themes';
import { IOverview, IDataMonth, IDataYear, IResponse } from '../../../type';

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import OverviewDayChart from './OverviewDayChart';

interface IOverviewComponent {
  tokenUser: string
}

const Overview: FC<IOverviewComponent> = ({ tokenUser }) => {
  const { enqueueSnackbar } = useSnackbar();
  const idEnt = localStorage.getItem("idEnterprise") || '';
  const navigate = useNavigate();
  const financialService = new FinancialService();
  const [dataYear, setDataYear] = useState<IOverview | null>(null);
  const [yearArray, setYearArray] = useState<null | number[]>(null);
  const [selectedYear, setSelectedYear] = useState<number>(2023);
  const [dateStart, setDateStart] = useState<string>();
  const [sizeMonth, setSizeMonth] = useState<number>(1);
  const [filteredData, setFilteredData] = useState<IDataYear | null>(null);
  const [dataCart, setDataCart] = useState<number[]>([])
  const [loadingChart, setLoadingChart] = useState<boolean>(false);

  const selectMonth = (month: string): void => {
    navigate(`./${selectedYear.toString()}/${month.replace(/^0+/, '')}`);
  }

  const normalizeData = (date: any, year: number) => {
    const chaves = Object.keys(date[year]);
    const currentDate = date[selectedYear];

    const formatted_date = format(new Date(currentDate.start_date), 'dd/MM/yyyy');
    setDateStart(formatted_date);

    const newArrayData: any = {};
    for (let i = 0; i < chaves.length; i++) {
      const novaChave: any = parseInt(chaves[i]) + 1;
      newArrayData[novaChave.toString()] = date[year][chaves[i]];
    }

    date[year] = newArrayData;
    return date;
  }

  const getDataYearOverview = async () => {
    setLoadingChart(true);
    if (idEnt && selectedYear) {
      try {
        if (!tokenUser) return;
        const response = await financialService.getDatYear(tokenUser, idEnt, selectedYear);  
        if ([404].includes(response.status)) {
          enqueueSnackbar(response.statusText,
            {
              variant: 'error'
            }
          )
        }
  
        if (response.status === 200) {
          setLoadingChart(false);
          const normalizedData = normalizeData(response.data, selectedYear);
          setSizeMonth(Object.keys(normalizedData[selectedYear]).length);
          setDataYear(normalizedData);
  
          const tempYearArray = Object.keys(normalizedData)?.map(i => +i).sort() ?? []
  
          if (tempYearArray.length) {
            setYearArray(tempYearArray)
            if (normalizedData && selectedYear) {
              const filteredData = normalizedData[selectedYear]
              setFilteredData(filteredData)
            }
          }
        }
        
      } catch (error: any) {
        if (error?.response?.data?.statusCode === 401) {
          keycloak.logout();
          removeLocalStorage();
        }
        
      }
    }

  }

  // get month labels
  const labels: string[] = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ];

  useEffect(() => {
    if (selectedYear) {
      // setFilteredData(dataYear[selectedYear.toString()])
      getDataYearOverview()
    }
  }, [selectedYear])

  useEffect(() => {
    let data: IDataMonth[] = new Array(12)
    for (let key in filteredData) {
      let index = +key - 1
      data[index] = filteredData[key]
    }
    const reduceMonth = (array: (number | boolean)[]): number => {
      let sum = 0
      array.forEach((day) => { if (typeof day !== 'boolean') { sum += day } })
      return sum
    }
    let summ = data.map(month => Object.values(month)).map((day) => reduceMonth(day))
    setDataCart(summ)
  }, [filteredData])

  const ControlYear: FC = () => {
    const disabledButton = (name: 'prev' | 'next'): boolean => {
      if (yearArray) {
        if (name === 'next') {
          return (yearArray.length - 1) === yearArray?.findIndex(element => element === selectedYear)
        } else {
          return yearArray.findIndex(element => element === selectedYear) === 0
        }
      }
      return true
    }
    const nextYear = () => {
      if (yearArray) { setSelectedYear(yearArray[yearArray.findIndex(element => element === selectedYear) + 1]) }
    }
    const prevYear = () => {
      if (yearArray) { setSelectedYear(yearArray[yearArray.findIndex(element => element === selectedYear) - 1]) }
    }
    return (
      <Box>
        <IconButton
          onClick={prevYear}
          disabled={disabledButton('prev')}
          size="small"
          aria-label="prev">
          <NavigateBeforeIcon />
        </IconButton>
        <IconButton
          onClick={nextYear}
          disabled={disabledButton('next')}
          size="small"
          aria-label="next">
          <NavigateNextIcon />
        </IconButton>
      </Box>
    )
  }

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        boxShadow: 'none',
        padding: '14px 25px',
        background: '#F1F3F4',
        border: '1px solid #CBD5E1',
        borderRadius: '30px',
        height: '336px'
      }}
    >
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="start"
              >
                <Grid
                  item
                  lg={6}
                  justifyContent="center"
                  alignItems="start"
                >
                  <Typography
                    sx={{
                      fontSize: fonts.size[300],
                      fontFamily: fonts.family.secondary,
                      fontWeight: fonts.weight.secondary,
                      color: colors.secondary[500],
                    }}
                  >
                    USAGE OVERVIEW
                  </Typography>
                  <Box sx={{ display: 'flex' }}>
                    <Typography
                      component="span"
                      sx={{
                        fontSize: fonts.size[500],
                        fontFamily: fonts.family.secondary,
                        fontWeight: fonts.weight.secondary,
                        color: colors.primary[700],
                      }}
                    >
                      {selectedYear}
                    </Typography>
                    <ControlYear />
                  </Box>
                </Grid>
                <Grid
                  item
                  lg={6}
                  justifyContent="left"
                  alignItems="start"
                >
                  <Box sx={{ display: 'flex', justifyContent: 'end', gap: '25px' }}>
                    <Box sx={{ paddingTop: '5px' }}>
                      <Typography
                        sx={{
                          fontSize: fonts.size[300],
                          fontFamily: fonts.family.secondary,
                          fontWeight: fonts.weight[600],
                          color: colors.secondary[600],
                          textAlign: 'left',
                        }}
                      >
                        {dateStart}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: fonts.size[80],
                          fontFamily: fonts.family.secondary,
                          fontWeight: fonts.weight.primary,
                          color: colors.secondary[600],
                          textAlign: 'left',
                        }}
                      >
                        STARTED DATE
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: fonts.size[500],
                          fontFamily: fonts.family.secondary,
                          fontWeight: fonts.weight.secondary,
                          color: colors.secondary[700],
                          textAlign: 'left',
                        }}
                      >
                        ACTIVE
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: fonts.size[80],
                          fontFamily: fonts.family.secondary,
                          fontWeight: fonts.weight[600],
                          color: colors.secondary[600],
                          textAlign: 'left',
                        }}
                      >
                        STATUS
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Box
                sx={{
                  width: '100%',
                  height: '85%',
                  display: 'flex',
                  alignItems: loadingChart ? 'center' : 'end',
                  justifyContent: loadingChart ? 'center' : ''
                }}
              >
                {loadingChart &&
                  <CircularProgress />
                }

                {!loadingChart &&
                  <BarChart
                    data={dataCart}
                    labels={labels}
                    selectMonth={selectMonth} />
                }
              </Box>
            </>
          }
        />
        <Route
          path=":year/:month"
          element={
            <OverviewDayChart
              filteredData={filteredData}
              sizeMonth={sizeMonth}
              selectMonth={selectMonth} />}
        />
      </Routes>
    </Paper>
  );
};

export default Overview;
