import { Typography } from "@mui/material";

import * as d3 from 'd3';
import React, { useEffect, useRef, useState } from 'react';

interface Node extends d3.SimulationNodeDatum {
  id: string;
  name: string;
  color: string;
}

interface ILinks {
  source: string;
  target: string
}

interface Network {
  nodes: Node[];
  links: ILinks[];
  center: [number, number];
  name: string; // Add name property
}

interface InputNode {
  id: string;
  name: string;
  role?: string;
  // ... other properties
}

interface INetworkGraph {
    // data: Network[];
    height: number;
    width: number;
    data: any[];
}

const NetworkGraph = ({ width, height, data}:INetworkGraph) => {
  const [componentSize, setComponentSize] = useState({ width: 745, height: 398 });
  const [networkData, setNetworkData] = useState([] as any[]);
  const [formattedNetworkData, setFormattedNetworkData] = useState<Network[]>([]);

  useEffect(()=>{
    setComponentSize({ width, height});
  },[width, height])
  
  useEffect(()=>{
    setNetworkData(data);
  },[data])


  useEffect(()=>{
    function formatNetWorkData() {
      const centersResults = calculateCoordinates(networkData.length, componentSize.width, componentSize.height)
      let local: Network[] = [];
      console.log(networkData)
      networkData.map((item, index) =>{
        let localNet: Network = {} as Network
        localNet.name = item.name
        localNet.center = centersResults[index]
        localNet.nodes = convertNodes(item.vms)
        localNet.links = transformObject(item.vms)
        local.push(localNet)
      })

      setFormattedNetworkData(local);
    }

    formatNetWorkData()
  },[networkData])
  
  const svgRef = useRef<SVGSVGElement>(null);

  function convertNodes(inputNodes: InputNode[]): Node[] {
    // Create an array to store the output format
    const output: Node[] = [];
  
    // Iterate through each input node
    inputNodes.forEach(node => {
      // Check if the output array already has an entry with the same id
      const existingNode = output.find(item => item.id === node.id);
  
      if (existingNode) {
        // If an entry exists, update the color of the existing node
        existingNode.color = getRandomColor();
      } else {
        // If no entry exists, create a new node with the current node's data
        output.push({ id: node.id, name: node.name, color: getRandomColor() });
      }
    });
  
    return output;
  }
  
  // Function to generate a random color
  function getRandomColor(): string {
    // This is a simple example; you might want to implement a more sophisticated color generation logic
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  

  function transformObject(input: InputNode[]): ILinks[] {
    console.log(input)
    let mainNode;
    if (input.find(node => node.role === 'bootnode')){
      mainNode = input.find(node => node.role === 'bootnode');
      
    }
    else{

      mainNode = input[0]
    }

    // if (!mainNode) {
    //   throw new Error('Main node not found');
    // }
    
    const links: ILinks[] = [];
  
    input
      .filter(node => node.id !== input[0].id)
      .forEach(node => {
        links.push({
          source: node.id,
          target: input[0].id,
        });
      });
  
    return links;
  }
  

  function calculateCoordinates(networksNumber: number, width: number, height: number): [number, number][] {
    const objectSize = 0; // Size of the object
    const spaceAround = (width - objectSize * networksNumber) / (networksNumber + 1);

    const coordinates: [number, number][] = [];

    for (let i = 0; i < networksNumber; i++) {
        const x = (i + 1) * spaceAround + i * objectSize;
        let y = height / 3;

        // Check the difference with the previous X coordinate
        if (i > 0) {
            const prevX = coordinates[i - 1][0];
            const diffX = x - prevX;

            if (diffX < objectSize) {
                // Adjust the Y coordinate to ensure a minimum difference of objectSize
                const offset = objectSize - diffX;
                y = height / 2 + (i % 2 === 0 ? -1 : 1) * offset;
            }
            y = height / 2 + (i % 2 === 0 ? -1 : 1) * 80;
        }

        coordinates.push([x, y]);
    }

    return coordinates;
}

  
  const centersResults = calculateCoordinates(4, componentSize.width, componentSize.height)


  useEffect(() => {
    // Sample data with three networks, each with 4 nodes and links
    // const networkData: Network[] = [
    //   {
    //     nodes: [
    //       { id: 'Z', name: 'Node A', color: 'black' },
    //     ],
    //     links: [],
    //     center: centersResults[0],
    //     name: 'Zero Network', // Name for Network 1
    //   },
    //   {
    //     nodes: [
    //       { id: 'A', name: 'Node A', color: 'red' },
    //       { id: 'B', name: 'Node B', color: 'blue' },
    //       { id: 'C', name: 'Node C', color: 'green' },
    //       { id: 'D', name: 'Node D', color: 'orange' },
    //     ],
    //     links: [
    //       { source: 'B', target: 'A' },
    //       { source: 'C', target: 'A' },
    //       { source: 'D', target: 'A' },
    //     ],
    //     center: centersResults[1],
    //     name: 'First Network', // Name for Network 1
    //   },
    //   {
    //     nodes: [
    //       { id: 'E', name: 'Node E', color: 'purple' },
    //       { id: 'F', name: 'Node F', color: 'yellow' },
    //       { id: 'G', name: 'Node G', color: 'cyan' },
    //       { id: 'H', name: 'Node H', color: 'pink' },
    //     ],
    //     links: [
    //       { source: 'E', target: 'G' },
    //       { source: 'F', target: 'E' },
    //       { source: 'H', target: 'G' },
    //     ],
    //     center: centersResults[2],
    //     name: 'Second Network', // Name for Network 2
    //   },
    //   {
    //     nodes: [
    //       { id: 'I', name: 'Node I', color: 'brown' },
    //       { id: 'J', name: 'Node J', color: 'gray' },
    //       { id: 'K', name: 'Node K', color: 'lightblue' },
    //       { id: 'L', name: 'Node L', color: 'lightgreen' },
    //     ],
    //     links: [
    //       { source: 'I', target: 'J' },
    //       { source: 'J', target: 'K' },
    //       { source: 'K', target: 'L' },
    //       { source: 'L', target: 'I' },
    //       // { source: 'I', target: 'K' },
    //     ],
    //     center: centersResults[3],
    //     name: 'Third Network', // Name for Network 3
    //   },
    // ];

    // Create SVG container
    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();


    // Create a force simulation for each network
    const simulations = formattedNetworkData.map((network) =>
      d3.forceSimulation<Node>(network.nodes)
        .force('link', d3.forceLink(network.links).id((d) => (d as Node).id).distance(60))
        .force('charge', d3.forceManyBody())
        .force('center', d3.forceCenter(network.center[0], network.center[1]))
    );

    // Create links for each network
    const link = svg
      .selectAll<SVGLineElement, d3.SimulationLinkDatum<Node>>('line')
      .data(formattedNetworkData.flatMap((network) => network.links))
      .enter()
      .append('line')
      .attr('stroke', 'black');

    // Create nodes for each network
    const node = svg
      .selectAll<SVGCircleElement, Node>('circle')
      .data(formattedNetworkData.flatMap((network) => network.nodes))
      .enter()
      .append('circle')
      .attr('r', 10)
      .attr('fill', (d) => (d as Node).color)
      .call(
        d3
          .drag<SVGCircleElement, Node>()
          .on('start', (event, d) => {
            simulations.forEach((simulation) => {
              if (!event.active) simulation.alphaTarget(0.3).restart();
            });
            d.fx = d.x!;
            d.fy = d.y!;
          })
          .on('drag', (event, d) => {
            d.fx = event.x;
            d.fy = event.y;
          })
          .on('end', (event, d) => {
            simulations.forEach((simulation) => {
              if (!event.active) simulation.alphaTarget(0);
            });
            d.fx = null;
            d.fy = null;
          })
      );

    // Create labels for each network
    const label = svg
      .selectAll<SVGTextElement, Node>('text')
      .data(formattedNetworkData.flatMap((network) => network.nodes))
      .enter()
      .append('text').text((d) => d.name)
      .attr('dy', -15)
      .attr('text-anchor', 'middle')
      .style('font-size', '12px')
      .style('fill', 'black');

    // Create names for each network (network name)
    const networkName = svg
      .selectAll<SVGTextElement, Network>('text.network-name')
      .data(formattedNetworkData)
      .enter()
      .append('text')
      .attr('class', 'network-name')
      .text((network) => network.name)
      .attr('x', (network) => network.center[0])
      .attr('y', (network) => network.center[1] + 75) // Adjust the vertical position
      .attr('text-anchor', 'middle')
      .style('font-size', '14px')
      .style('font-family', 'Montserrat')
      .style('font-weight', '700')
      .style('fill', '#666666');

    // Update node, link, and label positions on each tick of each simulation
    simulations.forEach((simulation, index) => {
      simulation.on('tick', () => {
        link
          .filter((d, i) => Math.floor(i / 4) === index)
          .attr('x1', (d) => (d.source as d3.SimulationNodeDatum).x!)
          .attr('y1', (d) => (d.source as d3.SimulationNodeDatum).y!)
          .attr('x2', (d) => (d.target as d3.SimulationNodeDatum).x!)
          .attr('y2', (d) => (d.target as d3.SimulationNodeDatum).y!);

        node
          .filter((d, i) => Math.floor(i / 4) === index)
          .attr('cx', (d) => (d as d3.SimulationNodeDatum).x!)
          .attr('cy', (d) => (d as d3.SimulationNodeDatum).y!);

        label
          .filter((d, i) => Math.floor(i / 4) === index)
          .attr('x', (d) => (d as d3.SimulationNodeDatum).x!)
          .attr('y', (d) => (d as d3.SimulationNodeDatum).y!);
      });
    });
  }, [formattedNetworkData]);

  return (
    <div
      className="info-card second-row-card node-map"
      style={{ display: "block", alignItems: "flex-start" }}
    >
      <div className="info-card-header">
        <h3 className="home-active-network">NETWORKS</h3>
        <Typography
          fontSize={55}
          fontWeight={600}
          color="#7b8a93"
          fontFamily="Montserrat"
          marginTop="-10px"
        >
          {networkData.length}
        </Typography>
      </div>
      {formattedNetworkData.length > 0 ?
        <div style={{width: '100%' , border: '0px solid red', height: '360px', position: 'absolute', top: '25px'}}>
          <svg ref={svgRef} width={'100%'} height={'100%'}>
          </svg>
        </div>
        :
        <div style={{ width: '100%', border: '0px solid red', height: '360px', position: 'absolute', top: '25px', display: 'flex'}}>
          <p style={{ margin: 'auto', fontFamily: 'Montserrat', fontWeight: '500', color: '#7B8A93', fontSize: '16px' }}>No networks</p>
        </div>
      }
    </div>
  );
};

export default NetworkGraph;
