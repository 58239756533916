import CustBaasStepper from "../../../../components/Steppers/CustBaasStepper";

interface ProcessStepperProps {
  percent: number;
  error: boolean;
  errorTicket: string;
}

export const ProcessBaasStepper = ({
  percent,
  error,
  errorTicket,
}: ProcessStepperProps) => {
  return (
    <CustBaasStepper error={error} percent={percent} errorTicket={errorTicket} />
  );
};
