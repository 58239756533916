import { Box, LinearProgress, Typography } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { DeployStepHeading } from "../../../components/Platform/Deploy/DeployWizard/WizardSteps/DeployStepHeading";
import { ProcessBaasStepper } from "./ProcessBaasStepper/ProcessBaasStepper";
import { WizardContext } from "../../../context/wizardContext";
import { OrderService } from "../../../services/order.service";
import { Percent } from "@mui/icons-material";

interface Props {
  tokenUser: string;
}

const orderService = new OrderService();

export const DeployBaasProcess = ({ tokenUser }: Props) => {
  const [progress, setProgress] = useState();
  const [processPercent, setProcessPercent] = useState(0);
  const [doneArray, setDoneArray] = useState([]);
  const [error, setError] = useState(false);
  const [errorTicket, setErrorTicket] = useState("");
  let inicialState = 0;

  // @ts-ignore
  const { deployData, setDeployData } = useContext(WizardContext);

  useEffect(() => {
    const order = {
      resourceGroup: {
        protocol: deployData.protocolType,
        version: deployData.version,
        stack: deployData.selectedStack,
        consensus: deployData.consesus
      },
      vms: deployData.vms,
      enterpriseId: deployData.enterpriseId,
      enterpriseWallet: "0x000000000000000000000000000000000000dEaD",
      organizationId: deployData.orgId,
      signedTx: deployData.signedTx,
      deployUUID: deployData.deployUUID,
      networkName: deployData.networkName
    };

    const createOrder = async (order: any) => {
      const orderRes = await orderService.createOrderBaas(order, tokenUser);
      return orderRes.data;
    };

    const createIdsArray = async (order: any) => {
      const orderRes = await createOrder(order);
      return orderRes;
    };

    const fetchStatus = async (idsArray: any) => {
      const orderStatusRes = await orderService.fetchOrderStatus(
        idsArray,
        tokenUser
      );
      return orderStatusRes.data;
    };

    const loopStatus = async (idsArray: any) => {
      let intervalId = setInterval(() => {
        fetchStatus(idsArray).then((resposta) => {
          setProgress(resposta.jobs);

          const resp = resposta.jobs.every((el: string) => el !== 'running' && el !== 'pendding');
          setDeployData({...deployData, statusButtonDone: resp })

          if (resposta.jobs.every((p: any) => p === "successful")) {
            clearInterval(intervalId);
          }
          if (resposta.jobs.some((p: any) => p === "failed" || p === "error")) {
            setErrorTicket(resposta.supportTicket);
            setError(true);

            resposta.jobs.forEach((p: any, index: number) => {
              if (p === "failed" || p === "error") {
                setProcessPercent(index * 25);
              }
            });

            clearInterval(intervalId);
          }

          let count: number = resposta.jobs.filter(
            (status: any) => status === "successful"
          ).length;

          const playbookProgress: number = (count/resposta.jobs.length) * 75;

          const finalProgress = playbookProgress + inicialState;
          setProcessPercent(finalProgress);
        });
      }, 10000);
    };

    const requests = async (order: any) => {
      const idsArray = await createIdsArray(order);
      setTimeout(() => {
        if(inicialState == 0) {

          setProcessPercent(25); //set the progress bar to 25%
          inicialState = 25;  //set the progress bar to 25%
        }
      }, 5000)
      const test = await loopStatus(idsArray);
      return test;
    };

    requests(order);
  }, []);
  return (
    <>
      <Box>
        <DeployStepHeading
          title="Deploying your new Network"
          description="This usualy takes between 5 and 8 minutes"
        />
        <Box sx={{ paddingTop: "30px", paddingBottom: "30px" }}>
          <LinearProgress
            variant="determinate"
            value={processPercent}
            color="info"
          />
        </Box>
        <ProcessBaasStepper
          percent={processPercent}
          error={error}
          errorTicket={errorTicket}
        />
        <Box sx={{ paddingTop: "70px" }}>
          <Typography
            fontFamily="Montserrat"
            fontSize="12px"
            fontWeight="500"
            lineHeight="15px"
            letterSpacing="1px"
            textAlign="left"
          >
            After completing the deployment, you can check out your network using the monitoring services.
          </Typography>
        </Box>
      </Box>
    </>
  );
};
