import React, { FC, useMemo, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { Box, Button, Grid, GridProps, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { fonts, colors } from '../../style/themes';

import { InputProfile } from '../inputProfile';

// import { B } from '../buttonContained'
import { ButtonOutlined } from '../buttonOutilined';
import { InputLabel } from './inputLabel';

import { IFormDataPage5 } from './IFormDataPage5';
import { SchemaFormProfile } from './schemaFormProfile';
import { IDataPage5, IResponse } from '../../type';
// import { PAGE_FIVE } from '../../routes/book';

interface IFormUser extends GridProps {
	userData: IDataPage5 | null;
	setUserData: Function;
	onCancel?: Function;
}

export const FormUserProfile: FC<IFormUser> = ({ userData, onCancel, setUserData }) => {
	const nagigate = useNavigate()
	const blank = {
		data4: '',
		data5: '',
		data6: '',
		data7: '',
		data8: '',
		data9: '',
		data10: '',
	}
	const initValues = useMemo(() => {
		return userData ? userData : blank
	}, [userData])

	useEffect(() => {
		if (!userData) {
			nagigate('/page-five')
		}
	}, [userData])


	const {
		control,
		handleSubmit,
		reset,
		formState: { errors, isValid },
	} = useForm<IFormDataPage5>({
		defaultValues: initValues,
		resolver: yupResolver(SchemaFormProfile),
		mode: 'all',
	});

	const onSubmit = async () => {
		console.log('IMPLEMENT SUBMIT HERE')
	};

	const handleCancel = () => {
		onCancel && onCancel();
	};

	return (
		<Box
			component={'form'}
			onSubmit={handleSubmit(onSubmit)}
			sx={{ px: '20px', display: 'block' }}
		>
			<Grid container columnSpacing={'30px'}>
				<Grid item xs={12}>
					<Typography
						sx={{
							fontSize: fonts.size[300],
							fontFamily: fonts.family.secondary,
							fontWeight: fonts.weight.secondary,
							color: colors.secondary[500],
							pb: '20px',
							textAlign: 'start',
							textTransform: 'uppercase',
						}}
					>
						INFORMATION
					</Typography>
				</Grid>
				<Grid item sm={6}>
					<InputLabel htmlFor={'input-data4.1'} >First Name</InputLabel>
					<Controller
						control={control}
						name="data4"
						render={({ field }) => (
							<>
								<InputProfile
									{...field}
									error={!!errors?.data4}
									id={'input-data4'}
									placeholder={'Data 4.1'}
									errorText={errors?.data4?.type === 'required'}
								/>
							</>
						)}
					/>
				</Grid>
				<Grid item sm={6}>
					<InputLabel htmlFor={'input-data5'} >Last Name</InputLabel>
					<Controller
						control={control}
						name="data5"
						render={({ field }) => (
							<>
								<InputProfile
									{...field}
									error={!!errors?.data5}
									id={'input-data5'}
									placeholder={'Data 5.1'}
									errorText={errors?.data5?.type === 'required'}
								/>
							</>
						)}
					/>
				</Grid>
				<Grid item sm={6}>
					<InputLabel htmlFor={'input-data6'} >Job Title</InputLabel>
					<Controller
						control={control}
						name="data6"
						render={({ field }) => (
							<>
								<InputProfile
									{...field}
									error={!!errors?.data6}
									id={'input-data6'}
									placeholder={'Data 6.1'}
									errorText={errors?.data6?.type === 'required'}
								/>
							</>
						)}
					/>
				</Grid>
				<Grid item sm={6}>
					<InputLabel htmlFor={'input-data7'} >Mobile</InputLabel>
					<Controller
						control={control}
						name="data7"
						render={({ field }) => (
							<>
								<InputProfile
									{...field}
									error={!!errors?.data7}
									id={'input-data7'}
									placeholder={'Data 7.1'}
									errorText={errors?.data7?.type === 'required'}
								/>
							</>
						)}
					/>
				</Grid>
				<Grid item sm={12}>
					<InputLabel htmlFor={'input-data8'} >Mail</InputLabel>
					<Controller
						control={control}
						name="data8"
						render={({ field }) => (
							<>
								<InputProfile
									{...field}
									error={!!errors?.data8}
									id={'input-data8'}
									placeholder={'Data 8.1'}
									errorText={errors?.data8?.type === 'required'}
								/>
							</>
						)}
					/>
				</Grid>
				<Grid item sm={6}>
					<InputLabel htmlFor={'input-data9'} >Password</InputLabel>
					<Controller
						control={control}
						name="data9"
						render={({ field }) => (
							<>
								<InputProfile
									{...field}
									error={!!errors?.data9}
									id={'input-data9'}
									placeholder={'Data 9.1'}
									errorText={errors?.data9?.type === 'required'}
								/>
							</>
						)}
					/>
				</Grid>
				<Grid item sm={6}>
					<InputLabel htmlFor={'input-data10'} >Confirm Password</InputLabel>
					<Controller
						control={control}
						name="data10"
						render={({ field }) => (
							<>
								<InputProfile
									{...field}
									error={!!errors?.data10}
									id={'input-data10'}
									placeholder={'Data 10.1'}
									errorText={errors?.data10?.type === 'required'}
								/>
							</>
						)}
					/>
				</Grid>

				<Grid
					container
					item
					columnSpacing={'60px'}
					xs={12}
					sx={{ justifyContent: 'end', margin: '10px 0' }}
				>
					<Grid item>
						<ButtonOutlined
							onClick={handleCancel}
							sx={{
								fontSize: fonts.size[200],
								fontFamily: fonts.family.secondary,
								fontWeight: fonts.weight[600],
								padding: '8px 24px',
								border: '1px solid #000000',
								borderRadius: '10px',
							}}
						>
							Cancel
						</ButtonOutlined>
					</Grid>
					<Grid item>
						<Button
							disabled={!isValid}
							type="submit"
							variant='contained'
							sx={{
								fontSize: fonts.size[200],
								fontFamily: fonts.family.secondary,
								fontWeight: fonts.weight[600],
								padding: '8px 24px',
								width: '169px',
								borderRadius: '10px',
								textTransform: 'initial',
							}}
						>
							Save Info
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
};
