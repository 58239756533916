import { Link } from "react-router-dom";
import {
  Box,
  Card,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { formatTextLimit } from "../../utils/formatTextLenght";

export type TOrganizationList = {
  orgList: [
    {
      id: string;
      name: string;
    }
  ];
  loading: boolean;
};

export const OrganizationList = ({ orgList, loading }: TOrganizationList) => {
  return (
    <Card
      sx={{
        boxShadow: "none",
        background: "#f1f3f4",
        marginTop: 7,
        height: "350px",
        overflowY: "auto",
        // "::-webkit-scrollbar": {
        //   backgroundColor: "#CBD5E1",
        //   width: "5px",
        //   borderRadius: "5px",
        // },
        // "::-webkit-scrollbar-thumb": {
        //   backgroundColor: "#7B8A93",
        //   borderRadius: "5px",
        // },
      }}
    >
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {orgList.map(({ id, name }) => {
            return (
              <>
                <Link
                  style={{ margin: "0" }}
                  to={"/organizations"}
                  state={{ idOrg: id }}
                >
                  <Box
                    key={id}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      cursor: "pointer",
                      marginRight: 1,
                      width: "90%",
                      ":hover": {
                        backgroundColor: "rgba(217, 217, 217, 0.2)",
                        borderRadius: "10px",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        padding: "12px 0",
                        display: "flex",
                        marginLeft: "6px",
                      }}
                    >
                      <Typography
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: "1",
                          WebkitBoxOrient: "vertical",
                          fontSize: "22.5px",
                          fontFamily: "Montserrat",
                          fontWeight: "300",
                        }}
                      >
                        {formatTextLimit(name.slice(4), 20)}
                      </Typography>
                    </Box>
                    <Box sx={{ padding: "24px 0" }}>
                      <ArrowRightIcon
                        sx={{
                          color: "#CBD5E1",
                        }}
                      />
                    </Box>
                  </Box>
                </Link>
                <Divider
                  light
                  color="#D8D8D8"
                  orientation="horizontal"
                  sx={{
                    margin: "0px",
                    width: "90%",
                    borderBottomWidth: "0.5px",
                  }}
                />
              </>
            );
          })}
        </>
      )}
    </Card>
  );
};
