import { useContext, useState } from "react";
import { WizardContext } from "../../../../context/wizardContext";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Card,
  Grid,
  Step,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";

import axios from "axios";

import { DeploySteps } from "../../../../pages/Deploy/DeploySteps";
import { DeployVMSetup } from "./WizardSteps/DeployVMSetup";
import { DeployAuthorization } from "../../../../components/deployAuthorization";
import { ApiPromise, WsProvider } from "@polkadot/api";
import { waitReady } from "@polkadot/wasm-crypto";
import uiKeyring from "@polkadot/ui-keyring";
import { useSnackbar } from "notistack";
import { textError } from "../../../../utils/textError";

const steps = ["", "", "", "", "", "", "", ""];

export const DeployWizard = ({ tokenUser }: any) => {
  const history = useNavigate();
  const [deployStep, setDeployStep] = useState(0);
  const [loadingAuthorization, setLoadingAuthorization] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [openAuthorization, setOpenAuthorization] = useState<boolean>(false);
  const [advancerNextStep, setAdvancerNextStep] = useState<boolean>(false);
  
  const [passwordAuthorization, setPasswordAuthorization] =
    useState<string>("");

  const [fezLoad, setFezLoad] = useState<boolean>(false);

  //@ts-ignore
  const { deployData, setDeployData } = useContext(WizardContext);

  const [selectedOrg, setSelectedOrg] = useState(
    "870894ab-51fd-4594-bdfc-07790231ad65"
  );
  const [networkName, setNetworkName] = useState("");

  const handleCloseAuthoraztion = () => {
    setOpenAuthorization(false);
  };

  const handleNextStep = () => {
    if (deployStep === 0) {
      setDeployStep(deployStep + 1);
    }

    if (
      deployStep === 2 &&
      deployData.selectedLicense !== "" &&
      deployData.licenseId !== ""
    ) {
      setDeployStep(deployStep + 1);
    }

    if (
      deployStep === 3 &&
      deployData.selectedOption !== "" &&
      deployData.selectedMode !== ""
    ) {
      setDeployStep(deployStep + 1);
    }

    if (deployStep === 4 && deployData.selectedStack !== "") {
      setDeployData({ ...deployData, buttonColor: "#7b8a93" });
      setDeployStep(deployStep + 1);
    }
    if (deployStep === 5 && deployData.currentVm === "4 - lastNode") {
      setDeployData({ ...deployData, buttonColor: "#1781E2" });
      setDeployStep(deployStep + 1);
    }

    if (deployStep === 6) {
      setDeployStep(deployStep + 1);
    }

    if (deployStep === 1 && deployData.networkName) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenUser,
        },
      };
      try {
        try {
          axios
            .get(
              `${process.env.REACT_APP_API_URL_ADDRESS}/resourceGroup/${selectedOrg}/available/${deployData.networkName}`,
              config
            )
            .then((res) => {
              const { available } = res.data;
              if (available) {
                if (deployStep === 1) {
                  setDeployData({ ...deployData, buttonColor: "#7b8a93" });
                  if (
                    deployData.networkName !== "" &&
                    deployData.selectedOrg !== "" &&
                    deployData.selectedEnterprise !== ""
                  ) {
                    // Fazer a integração da tela name and description aqui
                    setDeployStep(deployStep + 1);
                  }
                }
                return
              } else {
                enqueueSnackbar("this network name already exists!", {
                  variant: "error",
                })
              }
            });
        } catch (error) {
          setDeployStep(deployStep);
          console.log(error);
        }
      } catch (error) {
        console.log(error);
      }
    } else if (deployStep === 4) {
      const config = {
        body: {
          type: "Standard",
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenUser,
        },
      };

    } else if (deployStep === 6) {
    } else if (deployStep === 7) {
      setDeployStep(0);
      setDeployData({
        token: tokenUser,
        selectedOrg: "",
        selectedEnterprise: "",
        networkName: "",
        vmIp: "",
        vmUsername: "",
        selectedStack: "Stack 1",
        selectedVM: "",
        currentVm: "1 - Database",
        selectedRegion: "",
        selectedLicense: "License 1",
        licenseId: "",
        selectedOption: "Option 2",
        selectedMode: "Mode 2",
        currentEmpty: false,
        buttonColor: "#7B8A93",
        vms: [],
        idsArray: [],
        progress: "creating",
        deployUUID: "",
        signedTx: "",
      });
    }
  };

  const onSubmitAuthorization = async () => {
    setLoadingAuthorization(true);
    const provider = new WsProvider(process.env.REACT_APP_CHAIN_ADDRESS);
    const api = await ApiPromise.create({
      provider,
    });

    // PEGAR TODAS WALLETS
    try {
      await waitReady();
      if (!fezLoad) {
        uiKeyring.loadAll({ ss58Format: 42, type: "sr25519" });
        setFezLoad(true);
      }
    } catch (error) {
      console.log(error);
    }

    const userUUID = localStorage.getItem("owner") as string;
    const entUUID = localStorage.getItem("enterpriseId") as string;
    let userPair: any;

    try {
      userPair = uiKeyring.getPair(deployData?.user?.walletAddress);
    } catch (error) {
      enqueueSnackbar(textError, {
        variant: "error",
        autoHideDuration: 5000,
      });
      setTimeout(() => {
        history("/profile/wallet/recovery");
      }, 5000);
      setLoadingAuthorization(false);
      return;
    }

    try {
      userPair.unlock(passwordAuthorization);
    } catch (error) {
      setLoadingAuthorization(false);
      enqueueSnackbar("Invalid password", {
        variant: "error",
      });
      return;
    }

    const orgUUID = selectedOrg;

    const licenseUUID = deployData.licenseId;

    const genesisHash = api.genesisHash;
    const runtimeVersion = api.runtimeVersion;

    let nonce = await api.rpc.system.accountNextIndex(
      deployData?.user?.walletAddress
    );

    const txOrg = api.tx.multiledgersDeploy
      .requestDeployTokenizer(userUUID, entUUID, orgUUID, licenseUUID)
      .sign(userPair, {
        genesisHash,
        blockHash: genesisHash,
        nonce,
        runtimeVersion,
      });

    api.disconnect();

    setDeployData({ ...deployData, signedTx: txOrg });

    handleNextStep();
    handleCloseAuthoraztion();
    setLoadingAuthorization(false);
  };

  const authorizationSign = () => {
    setOpenAuthorization(true);
  };

  const handleBackStep = () => {
    setDeployData({ ...deployData, buttonColor: "#1781E2" });
    setDeployStep(deployStep - 1);
  };

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 0.5,
      left: "calc(-50%)",
      right: "calc(50%)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#1781E2",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#1781E2",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 8,
      border: 0,
      backgroundColor: "#A3CCF3",
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled("div")<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ theme, ownerState }) => ({
    backgroundColor: "#86B9F7",
    zIndex: 1,
    color: "#fff",
    width: 8,
    height: 8,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundColor: "#1781E2",
      height: 10,
      width: 10,
      transform: "translateY(-1px)",
    }),
    ...(ownerState.completed && {
      backgroundColor: "#86B9F7",
    }),
  }));

  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {};

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  return (
    <Card
      sx={{
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.16)",
        border: "1px solid #CBD5E1",
        borderRadius: "20px",
        width: "560px",
        height: "761px",
      }}
    >
      {deployStep > 0 && (
        <Stepper
          alternativeLabel
          activeStep={deployStep}
          connector={<ColorlibConnector />}
          sx={{
            paddingLeft: "29px",
            paddingRight: "35px",
            paddingTop: "40px",
          }}
        >
          {steps.map((label) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};

            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps} StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      )}
      <DeploySteps
        step={deployStep}
        handleNextStep={handleNextStep}
        setSelectedOrg={setSelectedOrg}
        setNetworkName={setNetworkName}
        selectedOrg={selectedOrg}
        networkName={networkName}
        tokenUser={tokenUser}
      />
      {deployStep > 0 && (
        <Grid
          container
          md={12}
          sx={{ paddingLeft: "37px", paddingRight: "43px", marginTop: '30px' }}
        >
          <Grid item md={6}>
            {deployStep === 7 || (
              <Button
                sx={{
                  width: "90%",
                  height: "40px",
                  display: "flex",
                  fontSize: "14px",
                  fontFamily: "MontSerrat",
                  fontWeight: "400",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #1C1D1F",
                  boxShadow: "none",
                  borderRadius: "10px",
                  ":hover": {
                    backgroundColor: "#FFFFFF",
                    boxShadow: "none",
                  },
                }}
                variant="contained"
                onClick={() => handleBackStep()}
              >
                <Typography
                  fontWeight="600"
                  fontSize="14px"
                  fontFamily="Montserrat"
                  color="#5F6368"
                >
                  Go Back
                </Typography>
              </Button>
            )}
          </Grid>
          <Grid item md={6}>
            <DeployAuthorization
              open={openAuthorization}
              loadingAuthorization={loadingAuthorization}
              onSubmitAuthorization={onSubmitAuthorization}
              handleCloseAuthorization={handleCloseAuthoraztion}
              setPasswordAuthorization={setPasswordAuthorization}
              title="CONFIRM DEPLOY"
              subText="This action will authorize the deployment of your network"
              confirm="Sign"
              cancel="Cancel"
            />
            {deployStep !== 6 && (
              <Button
                disabled={deployStep === 7 && !deployData.statusButtonDone}
                sx={{
                  marginLeft: "15px",
                  width: "90%",
                  height: "40px",
                  display: "flex",
                  fontSize: "14px",
                  fontFamily: "MontSerrat",
                  fontWeight: "400",
                  backgroundColor: deployData.buttonColor,
                  borderRadius: "10px",
                  ":hover": {
                    boxShadow: "none",
                    backgroundColor: deployData.buttonColor,
                  },
                }}
                variant="contained"
                onClick={() => handleNextStep()}
              >
                <Typography
                  fontWeight="600"
                  fontSize="14px"
                  fontFamily="Montserrat"
                >
                  {deployStep !== 7 ? "Next Step" : "Done"}
                </Typography>
              </Button>
            )}
            {deployStep === 6 && (
              <Button
                sx={{
                  marginLeft: "15px",
                  width: "90%",
                  height: "40px",
                  display: "flex",
                  fontSize: "14px",
                  fontFamily: "MontSerrat",
                  fontWeight: "400",
                  backgroundColor: deployData.buttonColor,
                  borderRadius: "10px",
                  ":hover": {
                    boxShadow: "none",
                    backgroundColor: deployData.buttonColor,
                  },
                }}
                variant="contained"
                onClick={authorizationSign}
              >
                <Typography
                  fontWeight="600"
                  fontSize="14px"
                  fontFamily="Montserrat"
                >
                  Next Step
                </Typography>
              </Button>
            )}
          </Grid>
        </Grid>
      )}
    </Card>
  );
};
