import Dialog from "@mui/material/Dialog";
import { Box, DialogTitle, Divider, Grid, Typography } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { CloseModal } from "~/assets";

import { MemberService } from "../../services/member.service";
import { OrganizationService } from "../../services/organization.service";
import { useSnackbar } from "notistack";
import { createBrowserHistory } from "history";
import { DeployAuthorization } from "../deployAuthorization";
import { useState } from "react";
import { ApiPromise, WsProvider } from "@polkadot/api";
import uiKeyring from "@polkadot/ui-keyring";

import { waitReady } from "@polkadot/wasm-crypto";

export type TModalRemove = {
  title: string;
  content?: string;
  setOpen: (value: boolean) => void;
  open: boolean;
  idMember?: string;
  idOrg?: string;
  handleCloseRemove: () => void;
  tokenUser: string;
};

export const ModalRemove = ({
  title,
  content,
  setOpen,
  open,
  idMember,
  idOrg,
  handleCloseRemove,
  tokenUser,
}: TModalRemove) => {
  const memberService = new MemberService();
  const organizationService = new OrganizationService();
  const history = createBrowserHistory();
  const [openAuthorization, setOpenAuthorization] = useState<boolean>(false);
  const [loadingAuthorization, setLoadingAuthorization] = useState<boolean>(false);
  const [passwordAuthorization, setPasswordAuthorization] = useState<string>('');


  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmitAuthorization = async () => {
    setLoadingAuthorization(true);
    try {
      await waitReady();
      uiKeyring.loadAll({ ss58Format: 42, type: "sr25519" });
    } catch (error) {
      console.log(error)
    }

    setOpenAuthorization(true)
    if (idMember && idOrg) {
      const provider = new WsProvider(process.env.REACT_APP_CHAIN_ADDRESS);
      const api = await ApiPromise.create({
        provider
      });

      const userWallet = localStorage.getItem("userWallet");

      const callerUUID = localStorage.getItem("owner");

      const orgUUID = idOrg;

      const userPair = uiKeyring.getPair(userWallet as string);
      
      try {
        userPair.unlock(passwordAuthorization);
        
      } catch (error) {
        setLoadingAuthorization(false);
        enqueueSnackbar('Invalid password', {
          variant: "error",
        });
        return
      }

      const genesisHash = api.genesisHash;
      const runtimeVersion = api.runtimeVersion;

      let nonce = await api.rpc.system.accountNextIndex(userWallet as string);

      const txMember = api.tx.multiledgersIdentity
      .removeMember(callerUUID, orgUUID, idMember).sign(userPair, {genesisHash, blockHash: genesisHash, nonce, runtimeVersion});
      

      await api.disconnect();


      
      const respMember = await memberService.deleteMemberById(
        idMember,
        idOrg,
        tokenUser,
        txMember,
      );
      if ([200, 201].includes(respMember.status)) {
        enqueueSnackbar("Member removed successfully!", {
          variant: "success",
        });
        setLoadingAuthorization(false);
        handleCloseRemove();
      } else {
        if (respMember.status === 403) {
          enqueueSnackbar(respMember.statusText, {
            variant: "error",
          });
          setLoadingAuthorization(false);
        }
        if (respMember.status === 401) {
          enqueueSnackbar(respMember.statusText, {
            variant: "error",
          });
          setLoadingAuthorization(false);
        }
        if (respMember.status === 400) {
          enqueueSnackbar(respMember.statusText, {
            variant: "error",
          });
          setLoadingAuthorization(false);
        }
      }
    }

    if (idOrg && !idMember) {

      // ! CHAMA O ENDPOINT

      const provider = new WsProvider(process.env.REACT_APP_CHAIN_ADDRESS);
      const api = await ApiPromise.create({
        provider
      });

      const userWallet = localStorage.getItem("userWallet");

      const callerUUID = localStorage.getItem("owner");

      const orgUUID = idOrg;

      const userPair = uiKeyring.getPair(userWallet as string);

      const memberList = await memberService.getMemberOrganization(idOrg, tokenUser);

      let members: string[] = [];

      const memberIds = memberList.data.members.map((member: any) => {
        if(member.id !== callerUUID) {
          members.push(member.id)
        }
      });

      
      try {
        userPair.unlock(passwordAuthorization);
        
      } catch (error) {
        setLoadingAuthorization(false);
        enqueueSnackbar('Invalid password', {
          variant: "error",
        });
        return
      }

      const genesisHash = api.genesisHash;
      const runtimeVersion = api.runtimeVersion;

      let nonce = await api.rpc.system.accountNextIndex(userWallet as string);

      const txOrg = api.tx.multiledgersIdentity
      .deleteOrganization(callerUUID, orgUUID, members).sign(userPair, {genesisHash, blockHash: genesisHash, nonce, runtimeVersion});
      
      await api.disconnect();
      setLoadingAuthorization(true);

      const respOrg = await organizationService.deleteOrganizationById(
        idOrg,
        txOrg,
        tokenUser
      );
      if ([200, 201].includes(respOrg.status)) {
        setLoadingAuthorization(false);
        enqueueSnackbar("Organization removed with success!", {
          variant: "success",
        });
        history.back();
      } else {
        // Mapear os error
        setLoadingAuthorization(false);
        enqueueSnackbar("Error remove organization!", {
          variant: "error",
        });
      }
    }
  };

  const handleCloseAuthoraztion = () => {
    setOpenAuthorization(false);
  };

  return (
    <>
      <DeployAuthorization
        open={openAuthorization}
        loadingAuthorization={loadingAuthorization}
        onSubmitAuthorization={onSubmitAuthorization}
        setPasswordAuthorization={setPasswordAuthorization}
        handleCloseAuthorization={handleCloseAuthoraztion}
        title={!idMember ? "REMOVE THIS ORGANIZATION" : "REMOVE THIS MEMBER"}
        subText={
          !idMember
            ? "Click here to authorize the removal of the organization."
            : "Click here to authorize the removal of the member."
        }
        confirm="Sign"
        cancel="Cancel"
      />
      <Dialog
        aria-describedby="dialogo de confirmação"
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "#F1F3F4",
            borderRadius: "18px",
          },
        }}
      >
        <DialogTitle
          sx={{
            "& .MuiTypography-root": {
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "25px",
              color: "#5F6368",
            },
          }}
        >
          <Typography fontWeight={600} fontSize={25} alignSelf="center">
            {title}
          </Typography>
        </DialogTitle>
        <Box
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: "16px",
            top: "10px",
            cursor: "pointer",
            padding: "8px",
            paddingTop: "18px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CloseModal />
        </Box>
        <Divider />
        <DialogContent>
          <Typography
            fontFamily="Montserrat"
            textAlign="center"
            fontWeight="600"
            fontSize="16px"
          >
            {content}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Grid container md={12} justifyContent="space-between" padding="23px">
            <Grid item md={4} sm={12}>
              <Button
                sx={{
                  width: "100%",
                  display: "flex",
                }}
                onClick={handleClose}
                variant="contained"
              >
                <Typography
                  fontFamily="Montserrat"
                  fontWeight="300"
                  fontSize="14px"
                >
                  No
                </Typography>
              </Button>
            </Grid>
            <Grid item md={4} sm={12}>
              <Button
                variant="contained"
                onClick={() => setOpenAuthorization(true)}
                sx={{ width: "100%" }}
                color="error"
              >
                <Typography
                  fontFamily="Montserrat"
                  fontWeight="300"
                  fontSize="14px"
                >
                  Yes
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};
