import { ChangeEvent, useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { Avatar, Backdrop, Box, Grid, Paper } from '@mui/material';
import Header from '../../components/Platform/Header/Header';
import { HomeHeader } from '../../components/Platform/Home/HomeHeader/HomeHeader';
import { fonts, colors } from '../../style/themes';
import { FormUserProfile } from '../../components/formUserProfile'
import { DataProfile } from '../../components/dataProfile';
import { CardSide } from '../../components/walletCard/cardSide';

export const Profile = ({user, userName }: any) => {
	const navigate = useNavigate();
	const [uploadedFile, setUploadedFile] = useState<File | null>(null);

	const [userData, setUserData] = useState<any>(
		{
			data4: 'user.firstName',
			data5: 'user.lastName',
			data6: 'user.jobTitle',
			data7: 'user.phone',
			data8: 'user.email',
			data9: '********',
			data10: '********',
			avatarPath: ''
		}
	);
	useEffect(() => {
		if (user){
			setUserData({
					data4: user.firstName,
					data5: user.lastName,
					data6: user.jobTitle,
					data7: user.phone,
					data8: user.email,
					data9: '********',
					data10: '********',
					avatarPath: ''
			})
		}
	},[user])

	const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
		const { files } = event.target;
		if (files?.length) {
			const file = files[0];
			setUploadedFile(file);
		}
	};

	const goToWallet = () => {
		navigate('/profile/wallet/recovery');
	}

	return (
		<>
			<Header userName={userName}/>
			<HomeHeader />
			{user ?
			<>
				<Grid
					container
					sx={{ padding: { xs: '20px', xl: '72px 152px' } }}>
					<Grid item md={12}
						sx={{
							height: '63px',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							fontSize: fonts.size[600],
							fontFamily: fonts.family.secondary,
							fontWeight: fonts.weight.secondary,
							color: colors.secondary[600],
							background: '#F1F3F4',
							border: '1px solid #CBD5E1',
							borderRadius: '30px 30px 0px 0px',
							marginBottom: '58px',
						}}>
						{`${userName}'s Profile`}
					</Grid>
					<Grid
						item
						md={2.2}
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							p: '25px 0 100px 0px',
						}}
					>
						{/* <InputLoadAvatar
							id="input-load-image"
							image={
								uploadedFile
									? URL.createObjectURL(uploadedFile)
									: userData?.avatarPath || 'http://www.gravatar.com/avatar/?d=mp'
							}
							name="fileLogo"
							textLabel="Edit Avatar"
							onChange={handleImageUpload}
							sx={{
								flexDirection: 'column',
								textAlign: 'center',
								alignSelf: 'start'
							}}
						/> */}
						<Avatar
						alt="profile"
						sx={{ bgcolor: "#1781E2", height: '150px', width: '150px', fontSize: '60px' }}
						>
						{userName ? userName.charAt(0).toUpperCase() : "Error"}
						</Avatar>
					</Grid>
					<Grid item md={9.8} display="flex" justifyContent="space-between">
						<Paper
							sx={{
								boxSizing: 'border-box',
								padding: '14px 25px',
								background: '#F1F3F4',
								boxShadow: 'none',
								border: '1px solid #CBD5E1',
								borderRadius: '30px',
								maxWidth: '707px',
								height: '584px'
							}}
						>
							<Routes>
								<Route path='/' element={<DataProfile userData={userData} />} />
								<Route path='/:edit' element={
									<FormUserProfile
										userData={userData}
										setUserData={setUserData}
										onCancel={() => navigate(-1)} />
								} />
							</Routes>
						</Paper>
						<Box>
							<CardSide
								title="Wallet"
								subTitle="You currently don´t have any wallet."
								footer="Create your first wallet to get started"
								walletAddress={user.walletAddress}
								goToWallet={goToWallet}
								dataWalletList={[{}]}
							/>
						</Box>
					</Grid>
				</Grid>
			</>
			:
			<>
				<Backdrop open={user}></Backdrop>
			</>
			}
			
		</>
	)
}