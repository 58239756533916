import Dialog from '@mui/material/Dialog';
import { Box, DialogTitle, Divider, Grid, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import { useForm } from 'react-hook-form';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
// import { useState } from 'react';
import { CloseModal } from '~/assets';
import { AddMembers } from './add-members';
import { useState } from 'react';
import { ModalRemove } from '../modal-remove';

export type TModal = {
  title: string;
  confirmButton?: string;
  cancelButton?: string;
  children: JSX.Element;
  setAddInformationOrg?: any;
  setOpen: (value: boolean) => void;
  edit?: boolean;
  removeOrganization?: boolean;
  open: boolean;
};

export const ModalGeneric = ({
  title,
  confirmButton,
  cancelButton,
  children,
  open,
  setAddInformationOrg,
  edit,
  removeOrganization,
  setOpen }: TModal) => {
  const [openDelete, setOpenDelete] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
  };

  const changeState = () => {
    handleClose();
    setAddInformationOrg(false);
  };

  const form = useForm<any>();

  return (
    <Dialog
      aria-describedby="dialogo de confirmação"
      fullWidth
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          backgroundColor: '#F1F3F4',
          borderRadius: '18px',
        },
      }}
    >
      <DialogTitle
        sx={{
          '& .MuiTypography-root': {
            fontFamily: 'Montserrat',
            fontWeight: '500',
            fontSize: '25px',
            color: '#5F6368',
          }
        }}>
        <Typography fontWeight={600} fontSize={25} alignSelf="center">
          {title}
        </Typography>
      </DialogTitle>
      <Box
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: '16px',
          top: '10px',
          cursor: 'pointer',
          padding: '8px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CloseModal />
      </Box>
      <Divider />
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginLeft: '15px',
          marginRight: '15px'
        }}>
        {/* <Button onClick={handleClose} variant="contained" color="error">{cancelButton}</Button> */}
        {!edit &&
          <>
            <Grid
              container
              md={12}
              sx={{
                marginBottom: '52px',
                justifyContent: 'center'
              }}>
              <Grid item md={8} sm={12}>
                <Button
                  sx={{
                    width: '100%',
                    display: 'flex',
                  }}
                  variant="contained"
                  onClick={changeState}
                >
                  <Typography
                    fontWeight="600"
                    fontSize="14px"
                    fontFamily="Montserrat"
                    >
                  {confirmButton}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </>

        }
        {edit &&
          <>

            <Grid
              container
              padding="10px"
              md={12}
              sm={12}
              sx={{
                justifyContent: 'space-between',
              }}
            >
              <Grid padding="10px" item md={4} sm={12} sx={{ padding: '98px 20x 0' }}>
                <Button
                  color="error"
                  onClick={() => setOpenDelete(true)}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    fontSize: '14px',
                    fontFamily: 'MontSerrat',
                    fontWeight: '400'
                  }}
                  variant="contained"
                >
                  <Typography
                    fontWeight="600"
                    fontSize="14px"
                    fontFamily="Montserrat"
                    >
                  {cancelButton}
                  </Typography>
                </Button>
              </Grid>
              <Grid item md={4} sm={12}>
                <Button
                  sx={{
                    width: '100%',
                    display: 'flex',
                    marginBottom: '44px',
                    fontSize: '14px',
                    fontFamily: 'MontSerrat',
                    fontWeight: '400'
                  }}
                  variant="contained"
                  onClick={changeState}
                >
                  <Typography
                    fontWeight="600"
                    fontSize="14px"
                    fontFamily="Montserrat"
                    >
                  {confirmButton}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </>

        }
      </DialogActions>
    </Dialog>
  );
};
