export const resourceMenu = [
  {
    id: 1,
    title: '',
    link: '#'
  },
  {
    id: 2,
    title: 'BAAS',
    link: '/resources/baas'
  },
  {
    id: 3,
    title: 'TOKENS',
    link: '/resources'
  },
  {
    id: 4,
    title: 'MONITORING',
    link: '/resources/monitoring'
  }
]