import { Box, Card, Grid, Typography } from "@mui/material";
import Header from "../../components/Platform/Header/Header";
import worldMap from "~/assets/imgs/worldMap.png";
import { DeployHeader } from "../../components/Platform/DeployHeader/DeployHeader";
import { Hamburguer } from "~/assets";
import { DeployWizard } from "../../components/Platform/Deploy/DeployWizard/DeployWizard";
import { WizardContext } from "../../context/wizardContext";
import { useState } from "react";

export const Deploy = ({ userId, userName, tokenUser, user }: any) => {
  const [deployData, setDeployData] = useState({
    token: tokenUser,
    selectedOrg: "",
    selectedEnterprise: "",
    statusButtonDone: false,
    networkName: "",
    consesus: "",
    name: "",
    mnemonic: "",
    vmIp: "",
    vmUsername: "",
    selectedStack: "Stack 1",
    selectedVM: "",
    currentVm: "1 - Database",
    selectedRegion: "",
    selectedLicense: "License 1",
    licenseId: "",
    deployUUID: "",
    selectedOption: "Option 2",
    selectedMode: "Mode 2",
    currentEmpty: false,
    buttonColor: "#7b8a93",
    vms: [],
    idsArray: [],
    nextLocked: true,
    fileSetup: null,
    progress: "creating",
    signedTx: "",
    user
  });

  return (
    <WizardContext.Provider value={{ deployData, setDeployData }}>
      <Header userName={userName} />
      <DeployHeader />
      <Grid
        container
        md={12}
        height="75vh"
        sx={{
          paddingLeft: "50px",
          paddingRight: "50px",
          paddingBottom: "57px",
          paddingTop: "58.65px",
        }}
      >
        <Grid
          item
          md={8}
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: "1290px",
          }}
        >
          <Card
            sx={{
              backgroundColor: "#FFFFFF",
              border: "1px solid #CBD5E1",
              boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.16)",
              borderRadius: "20px",
              width: '100%',
              marginRight: '10px',
              height: "100%",
              paddingTop: "23px",
              paddingLeft: "20px",
              paddingRight: "44px",
              paddingBottom: "94.66px",
            }}
          >
            <Box>
              <Grid container md={12}>
                <Grid item md={11}>
                  <Typography
                    fontFamily="Inter"
                    fontSize="24px"
                    fontWeight="400"
                    lineHeight="29px"
                    letterSpacing="0em"
                    textAlign="left"
                    color="#898585"
                  >
                    Easy Deploy - Token
                  </Typography>
                </Grid>
                <Grid item md={1}>
                  <Box sx={{ paddingLeft: "80px" }}>
                    <Hamburguer />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{}}>
              <Box>
                <img src={worldMap} alt="" />
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid
          item
          md={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <DeployWizard tokenUser={tokenUser} />
        </Grid>
      </Grid>
    </WizardContext.Provider>
  );
};
