import React, { useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// @ts-ignore
import Platform from "./components/Platform/Platform.tsx";
// @ts-ignore
import OrgManagement from "./components/Platform/OrgManagement/OrgManagement.tsx";
// @ts-ignore
import SignUp from "./components/EnterpriseSignUp/index";
import Financial from "./pages/Financial";
import { Profile } from "./pages/Profile";
import { Monitoring } from "./pages/Monitoring";
import Home from "./components/Platform/Home/Home";
import { Deploy } from "./pages/Deploy/Deploy";
import { DeployBaas } from "./pages/DeployBaas/deployBaas";
import { keycloak } from "./utils/Keycloak";
import jwt_decode from "jwt-decode";
import { SnackbarProvider } from "notistack";
import { MemberService } from "./services/member.service";
import { WizardContext } from "./context/wizardContext";
import { Wallet } from './pages/Wallet';
import { setLocalStorage, removeLocalStorage } from './generalLocalStorage.js'
import store from './stores/store.js';

export type TRouteSwitch = {
  attributes: any;
  enterprise: any;
  firstName: string;
  lastName: string;
  organizations: Array<any>;
  owner: boolean;
  totalMembers: number;
  username: string;
};

const RouteSwitch = () => {
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState<any>();
  const [loginToken, setLoginToken] = useState<boolean>(false);
  const [loginOwner, setLoginOwner] = useState<boolean>(true);
  const [tokenUser, setTokenUser] = useState<string>("");
  const [organizationsData, setOrganizationsData] = useState<Array<any>>();

  const [user, setUser] = useState<any>();
  const [totalNumbers, setTotalNumbers] = useState<number>(0);

  const memberService = new MemberService();

  const authLoginUser = async (tokenId: string) => {
    try {
      const respAuthLogin = await memberService.authUser(tokenId);
      setLoginOwner(false);

      if ((respAuthLogin.status === 200) && respAuthLogin.data) {
        const { owner, enterprise, totalMembers, organizations } =
          respAuthLogin.data;
        setOrganizationsData(organizations);
        setLoginOwner(owner);

        setTotalNumbers(totalMembers);
        setUser(respAuthLogin.data);
        setLocalStorage(owner, respAuthLogin, enterprise)
      }
      if (respAuthLogin.status === 401) {
        const logoutUser = async () => {
          removeLocalStorage();
          await keycloak.logout();
        };
        logoutUser();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const Protected = ({ isSignedIn, children }: any) => {
    const checkKeycloak = async () => {
      const authenticated = await keycloak.init({
        onLoad: "login-required",
        checkLoginIframe: false,
      });
      if (authenticated && keycloak.idToken) {
        const token: any = jwt_decode(keycloak.idToken);
        localStorage.setItem("idUser", token.sub);
        setUserId(token.sub);
        setUserName(token.given_name);
        authLoginUser(keycloak.idToken);
        setTokenUser(keycloak.idToken);

        setLoginToken(true);
        setIsAuthenticated(true);
      } else {
        return (<p>test not auth</p>);
      }
    };
    if (!isSignedIn) {
      checkKeycloak();
    }
    return children;
  }

  const PrivateRoute = ({ redirectTo, children }: any) => {
    if (loginOwner && loginToken) {
      return children;
    } else {
      return <Navigate to={redirectTo} />;
    }
  };

  return (
    <SnackbarProvider
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <WizardContext.Provider
        value={{
          loginOwner,
          tokenUser,
          userName,
          user
        }}>
        <Provider store={store}>
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={
                  <Protected isSignedIn={isAuthenticated}>
                    {/* <PrivateRoute redirectTo="/organizations"> */}
                    {isAuthenticated &&
                      <Home
                        user={user}
                        tokenUser={tokenUser}
                        userName={userName}
                        totalNumbers={totalNumbers}
                      />
                    }
                    {/* </PrivateRoute> */}
                  </Protected>
                }
              />
              <Route
                path="/organizations"
                element={
                  <Protected isSignedIn={isAuthenticated}>
                    {/* <PrivateRoute redirectTo="/"> */}
                    {isAuthenticated &&
                      <OrgManagement
                        user={user}
                        tokenUser={tokenUser}
                        userName={userName}
                        orgData={organizationsData}
                      />
                    }
                    {/* </PrivateRoute> */}
                  </Protected>
                }
              />

              <Route path="/signup" element={<SignUp />} />

              <Route
                path="/financial/*"
                element={
                  <Protected isSignedIn={isAuthenticated}>
                    <PrivateRoute redirectTo="/organizations">
                      <Financial userName={userName} tokenUser={tokenUser} />
                    </PrivateRoute>
                  </Protected>
                }
              />
              <Route
                path="/resources/monitoring/*"
                element={
                  <Protected isSignedIn={isAuthenticated}>
                    <Monitoring
                      user={user}
                      userName={userName}
                      tokenUser={tokenUser}
                    />
                  </Protected>
                }
              />
              <Route
                path="/profile/*"
                element={
                  <Protected isSignedIn={isAuthenticated}>
                    <Profile user={user} userName={userName} />
                  </Protected>
                }
              />

              <Route
                path="/profile/wallet/recovery"
                element={
                  <Protected isSignedIn={isAuthenticated}>
                    <Wallet user={user} tokenUser={tokenUser} userName={userName} />
                  </Protected>
                }
              />
              <Route
                path="/resources"
                element={
                  <Protected isSignedIn={isAuthenticated}>
                    <Deploy
                      user={user}
                      userId={userId}
                      userName={userName}
                      tokenUser={tokenUser}
                    />
                  </Protected>
                }
              />
              <Route
                path="/resources/baas"
                element={
                  <Protected isSignedIn={isAuthenticated}>
                    <DeployBaas
                      user={user}
                      userId={userId}
                      userName={userName}
                      tokenUser={tokenUser}
                    />
                  </Protected>
                }
              />
            </Routes>
          </BrowserRouter>
        </Provider>
      </WizardContext.Provider>
    </SnackbarProvider>
  );
};

export default RouteSwitch;
