import React, { FC, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';
import { fonts, colors } from '../../../style/themes';
import { IMockData7Page4 } from '../../../type';
import { customThinScroll } from '../../../style/scrollBarStyle';

export interface IData1Props {
  dataValue: IMockData7Page4 | null;
  selectedSubitem: {
    dataKey: string;
    index: number;
  } | null;
}

export const AlertSummaryCard: FC<IData1Props> = (props) => {
  const { selectedSubitem, dataValue } = props;
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  useEffect(() => {
    setIsExpanded(false)
  }, [selectedSubitem])

  const handleToggle = () => {
    setIsExpanded(!isExpanded)
  }
  const getSubItem = () => {
    if (dataValue && selectedSubitem) {
      return dataValue[selectedSubitem.dataKey]?.subitems[selectedSubitem.index]['Data8.1']
    } else return null
  }

  return (
    <Paper
      sx={{
        boxSizing: 'border-box',
        boxShadow: 'none',
        padding: '14px 25px',
        background: '#F1F3F4',
        border: '1px solid #CBD5E1',
        borderRadius: '30px',
        height: '422.09px'
      }}
    >
      <Typography
        sx={{
          fontSize: fonts.size[300],
          fontFamily: fonts.family.secondary,
          fontWeight: fonts.weight.secondary,
          color: colors.secondary[500],
          textAlign: 'left',
        }}
      >
        ALERT SUMMARY
      </Typography>
      <Box
        sx={{
          marginTop: '38px',
          height: '80%',
          background: '#5F6368',
          borderRadius: '11.4608px',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Box>
            <svg width="61" height="34" viewBox="0 0 61 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.5" d="M18.3108 9.01688C18.3108 4.03699 14.2118 0 9.15538 0C4.099 0 0 4.03699 0 9.01688V24.4777C0 29.4576 4.099 33.4946 9.15538 33.4946C14.2118 33.4946 18.3108 29.4576 18.3108 24.4777V9.01688Z" fill="white" />
              <path opacity="0.5" d="M39.6555 9.01688C39.6555 4.03699 35.5565 0 30.5001 0C25.4437 0 21.3447 4.03699 21.3447 9.01688V24.4777C21.3447 29.4576 25.4437 33.4946 30.5001 33.4946C35.5565 33.4946 39.6555 29.4576 39.6555 24.4777V9.01688Z" fill="white" />
              <path opacity="0.5" d="M61 9.01688C61 4.03699 56.901 0 51.8446 0C46.7882 0 42.6892 4.03699 42.6892 9.01688V24.4777C42.6892 29.4576 46.7882 33.4946 51.8446 33.4946C56.901 33.4946 61 29.4576 61 24.4777V9.01688Z" fill="white" />
            </svg>
          </Box>
          <Typography
            sx={{
              fontSize: fonts.size[80],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight[600],
              color: colors.primary[100],
            }}
          >
            Select a Resource
          </Typography>
        </Box>
        {/* {selectedSubitem && dataValue && !getSubItem()?.length
          ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <Typography
                sx={{
                  fontSize: fonts.size[300],
                  fontFamily: fonts.family.secondary,
                  fontWeight: fonts.weight[600],
                  color: colors.primary[100],
                }}>
                No itens were found
              </Typography>
            </Box>
          )
          : (
            <Box sx={{ display: 'flex', paddingTop: '51px', flexWrap: 'wrap', overflowY: 'hidden' }}>
              {isExpanded ? (
                <Box
                  onClick={handleToggle}
                  sx={{ marginX: '15px', cursor: 'pointer', }}
                >
                  <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.66797 6.1582L0.667969 1.1582L1.43945 0.396484L5.66797 4.61523L9.88672 0.396484L10.668 1.1582L5.66797 6.1582Z" fill="white" />
                  </svg>
                </Box>
              ) : (
                <Box
                  onClick={handleToggle}
                  sx={{ marginX: '15px', cursor: 'pointer', }}
                >
                  <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.1582 5.33203L1.1582 10.332L0.396484 9.56055L4.61523 5.33203L0.396484 1.11328L1.1582 0.332031L6.1582 5.33203Z" fill="white" />
                  </svg>
                </Box>
              )}
              <Typography
                onClick={handleToggle}
                sx={{
                  fontSize: fonts.size[300],
                  fontFamily: fonts.family.secondary,
                  fontWeight: fonts.weight[600],
                  color: colors.primary[100],
                  cursor: 'pointer',
                }}>
                Alert Details
              </Typography>
              <Collapse
                in={isExpanded}
                timeout="auto"
                unmountOnExit
                sx={{ width: '100%', maxHeight: '220px', paddingLeft: '44px', marginRight: '23px', overflowY: 'auto', ...customThinScroll }}>
                {
                  getSubItem()?.map(item => {
                    return (
                      <Typography
                        key={window.crypto.randomUUID()}
                        sx={{
                          fontSize: fonts.size[80],
                          fontFamily: fonts.family.secondary,
                          fontWeight: fonts.weight[600],
                          color: colors.primary[100],
                          lineHeight: fonts.size[300],
                          m: '30px',
                        }}
                      >{`${item.name} - ${item.description}`}
                      </Typography>
                    )
                  })
                }
              </Collapse>
            </Box>
          )
        } */}
      </Box>
    </Paper >
  );
};
