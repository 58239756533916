import React, { useState, useCallback } from "react";

import { StyledOwnerWallet } from "../../Styles/OwnerWallet.styled";
import { mnemonicGenerate } from '@polkadot/util-crypto';
import { keyring as uiKeyring } from '@polkadot/ui-keyring';
import { waitReady } from '@polkadot/wasm-crypto'

import walletIcon from "~/assets/wallet_icon.png";
import walletWarningInfo from "~/assets/warning_wallet_info.png";
import copyButton from "~/assets/copyButton.png";

function OwnerWallet(props: {
  setFormInfo: (arg0: any) => void;
  previousHandleClick: () => void;
  nextHandleClick: () => void;
  formInfo: {
    userFirstName: string;
    userLastName: string;
    userEmail: string;
    userPhone: string;
    userJobTitle: string;
    userCPF: string;
    userPassword: string;
    enterpriseName: string;
    enterpriseCNPJ: string;
    enterpriseZipCode: string;
    entepriseAddress: string;
    entepriseNumber: string;
    entepriseComplement: string;
    entepriseCountry: string;
    entepriseState: string;
    entepriseCity: string;
    paymentMethod: string;
    userWalletAddress: string;
    userWalletName: string;
    userWalletPassword: string;
    enterpriseWalletAddress: string;
    enterpriseWalletName: string;
    enterpriseWalletPassword: string;
  };
}) {
  const mnemonic = mnemonicGenerate();
  const [walletName, setWalletName] = useState(props.formInfo.userWalletName);
  const [rawEnterpriseMnemonicWords, setRawEnterpriseMnemonicWords] = useState(mnemonic);
  const [mnemonicWords, setMnemonicWords] = useState(rawEnterpriseMnemonicWords.split(' '));
  const [walletAddress, setWalletAddress] = useState("");
  const [isOwnerWalletWasStoraged, setOwnerWalletWasStoraged] = useState(false);

  const handleSubmit = async (event: { preventDefault: () => void; }) => {

    const data = {
      ...props.formInfo,
      "userWalletAddress": walletAddress,
    };
    props.setFormInfo(data);
    event.preventDefault();
    props.nextHandleClick();
  };

  const handleCheckboxClick = async (event: any) => {
    const createWallet = async () => {
      if (walletAddress) return;
      try {
        await waitReady();
        uiKeyring.loadAll({ ss58Format: 42, type: 'sr25519' });
      } catch (error) {
        console.log(error)
        setWalletAddress("");
      } finally {
        const { pair, json } = uiKeyring.addUri(rawEnterpriseMnemonicWords, `${props.formInfo.userWalletPassword}`, { name: `${props.formInfo.userWalletName}` });
        setWalletAddress(pair.address);
      }
    }

    createWallet();

    setOwnerWalletWasStoraged(event.target.checked);
  };

  const listItems = mnemonicWords.map((word) =>
    <li key={word.toString()}>
      <a>{word}</a>
    </li>
  );

  return (
    <StyledOwnerWallet>
      <div className="container-owner-wallet">
        <div className="header">
          <h1 className="header-title">
            Personal Wallet Generation
          </h1>
          <h5 className="header-subtitle">
            Please write your mnemonic seed and keep it in a safe place. The mnemonic can be used to restore your wallet.
          </h5>
        </div>
        <div className="content">
          <div className="wallet-info">
            <div className="wallet-info-icon">
              <img src={walletIcon} alt="walletIcon" />
            </div>
            <div className="wallet-more-info">
              <div className="wallet-more-info-name">{walletName == "" ? "<unknown> wallet" : `${walletName} wallet`}</div>
              <div className="wallet-address-button">
                {walletAddress ? <div className="wallet-more-info-address">{walletAddress}</div>
                  :
                  <div className="wallet-more-info-address">Click in "I have saved my mnemonic..." to see your wallet address</div>
                }
                <button className="wallet-address-copy-button" onClick={() => { navigator.clipboard.writeText(walletAddress.toString()) }}>
                  <img src={copyButton} alt="walletIcon" />
                </button>
              </div>
            </div>
          </div>
          <div className="mnemonic-info">
            <div className="mnemonic-title">GENERATED 12 WORD MNEMONIC SEED:</div>
            <div className="mnemonic-words">
              <ul>{listItems}</ul>
            </div>
            <button className="mnemonic-copy-button" onClick={() => { navigator.clipboard.writeText(mnemonicWords.toString().split(",").join(" ")) }}>Copy mnemonic words</button>
          </div>
          <div className="warning-info">
            <div className="warning-icon">
              <img src={walletWarningInfo} alt="walletIcon" />
            </div>
            <div className="warning-text">Keep it carefully to not lose your assets.</div>
          </div>
        </div>
        <div className="footer">
          <div className="footer-checkbox-mnemonic">

            <input className="checkbox-input" onClick={handleCheckboxClick} type="checkbox" id="topping" name="topping" value="Paneer" />I have saved my mnemonic seed safely
          </div>
          <div className="footer-next-step">
            {isOwnerWalletWasStoraged && !!walletAddress
              ?
              <button onClick={handleSubmit} className="footer-next-step-button">Next Step</button>
              :
              <button disabled className="footer-next-step-button-disabled">Next Step</button>
            }
          </div>
        </div>
      </div>
    </StyledOwnerWallet>
  );
}

export default OwnerWallet;
