import React, { useState, useCallback, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useSearchParams } from "react-router-dom";
import { TbAlertCircle } from 'react-icons/tb';
import InputMask from 'react-input-mask';

function MemberInformationForm(props: {
  formInfo: {
    userFirstName: string;
    userLastName: string;
    userEmail: string;
    userPhone: string;
    userToken: string;
    userJobTitle: string;
    userCPF: string;
    userPassword: string;
    userWalletAddress: string;
    userWalletName: string;
    userWalletPassword: string;
};
  setFormInfo: (arg0: any) => void;
  nextHandleClick: () => void;
  // setUserJobTitle: (arg0: any) => void;
  // setUserCPF: (arg0: any) => void;
  // setUserPassword: (arg0: any) => void;
}) 
{
  const [searchParams, setSearchParams] = useSearchParams();
  const [jobTitle , setJobTitle] = useState(props.formInfo.userJobTitle);
  const [isRecaptchaClicled , setisRecaptchaClicled] = useState(false);
  const [CPF , setCPF] = useState(props.formInfo.userCPF);
  const [password , setPassword] = useState("");
  const [confirmPassword , setConfirmPassword] = useState("");
  const [isAllDataFilled, setIsAllDataFilled] = useState(false);
  
  useEffect(()=>{
    setIsAllDataFilled(!anyEmptyField())
  },[jobTitle, CPF, password, confirmPassword, isRecaptchaClicled])

  const anyEmptyField = () => (
      jobTitle === ''
      || CPF === ''
      || password === ''
      || confirmPassword === '' || !isRecaptchaClicled
  );

  const recaptchaChange = () => {
    setisRecaptchaClicled(!isRecaptchaClicled)
  };

  const handleSubmit = (event: { preventDefault: () => void; }) => {
    if (password !== confirmPassword) {
      alert("Passwords don't match");
      setPassword('');
      setConfirmPassword('');
      return;
    }

    setIsAllDataFilled(!anyEmptyField())

    event.preventDefault(); // 👈️ prevent page refresh

    // "userFirstName": props.formInfo.firstName,
    // "userLastName": props.formInfo.lastName,
    // "userEmail": props.formInfo.phone,
    // "userPhone": props.formInfo.email,
    // "userJobTitle": "",
    // "userCPF": "",
    // "userPassword": "",


    const data = {
      ...props.formInfo,
      "userJobTitle": jobTitle ,
      "userCPF": CPF.replace(/[.-]/g, "") ,
      "userPassword": password,
      "userEmail" : searchParams.get("email"),
      "userFirstName" : searchParams.get("firstName"),
      "userLastName" : searchParams.get("lastName"),
      "userPhone" : searchParams.get("phone"),
      "userToken" : searchParams.get("token")
    };
    props.setFormInfo(data);

    props.nextHandleClick();
  };

  return (
    <div className='content-owner-info'>
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <h2 className='inside-title-header'>MEMBER INFORMATION</h2>
          <div className="input-horizontal">
            <div className="input-initial">
              <label>JOB TITLE</label>
              <input
                type="text"
                onChange={(event) => setJobTitle(event.target.value)}
                value={jobTitle}
              />
            </div>
            <div className="input-initial">
              <label>CPF</label>
              <InputMask mask="999.999.999-99"
                onChange={(event) => setCPF(event.target.value)}
                value={CPF}>
              </InputMask>
            </div>
          </div>
          <h2 className='inside-title-header'>SECURITY</h2>
          <div className="input-initial">
            <label>PASSWORD</label>
            <div className='password-tooltip'>
              <input
                type="password"
                onChange={(event) => setPassword(event.target.value)}
                value={password}
              />
              <div className='tooltip-icon'>
                  <TbAlertCircle size={24} style={{marginTop: "5px"}}/>
                  <ul>
                    <li>8 - Characteres minimum</li>
                    <li>1 - Number</li>
                    <li>1 - Lowercase letter</li>
                    <li>1 - Special character</li>
                    <li>1 - Uppsercase letter</li>
                  </ul>
              </div>
            </div>
          </div>
          <div className="input-initial">
            <label>REPEAT PASSWORD</label>
            <div className='password-tooltip'>
              <input
                type="password"
                onChange={(event) => setConfirmPassword(event.target.value)}
                value={confirmPassword}
              />
              <div className='tooltip-icon'>
                <TbAlertCircle size={24} style={{marginTop: "5px"}}/>
                  <ul>
                    <li>8 - Characteres minimum</li>
                    <li>1 - Number</li>
                    <li>1 - Lowercase letter</li>
                    <li>1 - Special character</li>
                    <li>1 - Uppsercase letter</li>
                  </ul>
              </div>
            </div>
            <br>
            </br>
            <br>
            </br>
            <PasswordStrengthBar password={password}/>
            
          </div>
          <ReCAPTCHA onChange={recaptchaChange} sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}/>
          <div className='agree-text-label'>
            <label>By clicking you agree to be bound by these Terms of Use.</label>
          </div>
          {isAllDataFilled ?
            <button type="button" onClick={handleSubmit} style={{cursor: "pointer"}}>
              Next - Create Wallet
            </button>
            :
            <button type="button" disabled style={{cursor:'not-allowed'  , backgroundColor: "#7B8A93", color: "#fff", border: "0px"}}>
              Next - Create Wallet
            </button>
          }
        </div>
      </form>
    </div>
  );
}

export default MemberInformationForm;
