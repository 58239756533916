export const setLocalStorage = (owner, respAuthLogin, enterprise) => {
  localStorage.setItem('ow', owner)
  localStorage.setItem("userWallet", respAuthLogin.data.walletAddress);
  localStorage.setItem("enterpriseId", respAuthLogin.data.enterprise.id);
  localStorage.setItem("entepriseServiceAuthId", enterprise.serviceAuthId);
  localStorage.setItem("owner", enterprise.ownerId);
  localStorage.setItem("idEnterprise", enterprise.id);
}

export const removeLocalStorage = (owner, respAuthLogin, enterprise) => {
  localStorage.removeItem("owner");
  localStorage.removeItem("idEnterprise");
  localStorage.removeItem("idUser");
  localStorage.removeItem("enterpriseId");
  localStorage.removeItem("userWallet");
  localStorage.removeItem("entepriseServiceAuthId");
  localStorage.removeItem("idUser");
  localStorage.removeItem("ow");
}