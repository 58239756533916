import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Header.css";
import { headerView, headerViewNotOwner } from "./HeaderView";
import person from '../../../assets/icons/person.png';
import logout from '../../../assets/icons/logout.png';
// @ts-ignore
import { Avatar, Button, Grid, IconButton, Tab, Tabs } from "@mui/material";
import { Box, Card, Typography } from "@mui/material";
import { LogoHeader, SunHeader } from "~/assets";
import { keycloak } from "../../../utils/Keycloak";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useSelector } from "react-redux";
import { removeLocalStorage } from "../../../generalLocalStorage";
interface IHeader {
  id: number;
  title: string;
  link: string;
}

const Header = ({ userName }: any) => {
  const navigate = useNavigate();
  const url = window.location.href;
  const SLUG = url.substring(url.lastIndexOf('/') + 1);

  let owner: boolean, loginOwnerProfile: boolean;
  const menuMainClicked = parseInt(localStorage.getItem("menuMainclick") || "");
  const [date, setDate] = useState<string>("");
  const [hover, setHover] = useState(false);
  const [handleMenuOpen, setHandleMenuOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>(
    menuMainClicked ? menuMainClicked : 0
  );

  const profileUser: any = useSelector(
    (state: any) => state.profileUser
  );

  try {
    owner = localStorage.getItem("owner") !== "";
    loginOwnerProfile = JSON.parse(localStorage.getItem('ow') || '')
  } catch (error) {
    owner = false;
    loginOwnerProfile = false
  }

  const getCurrentDate = () => {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    const resultDate = `${date}/${month < 10 ? `0${month}` : `${month}`
      }/${year}`;
    setDate(resultDate);
  };

  useEffect(() => {
    getCurrentDate();
  }, []);

  const logoutUser = async () => {
    removeLocalStorage()
    await keycloak.logout();
  };

  useEffect(() => {
    switch (SLUG) {
      case '':
        setSelectedItem(1)
        break;
      case 'organizations':
        setSelectedItem(2)
        break;
      case 'resources':
        setSelectedItem(3)
        break;
      case 'financial':
        setSelectedItem(4)
        break;
      case 'baas':
        setSelectedItem(3)
        break;
      case 'profile':
        setSelectedItem(0)
      case 'recovery':
        setSelectedItem(0)
    }
  }, [SLUG])

  const handleItemClick = (idMenu: number, title: string) => {
    localStorage.setItem("menuMainclick", idMenu.toString());

    if (title === 'RESOURCE') {
      localStorage.setItem("menuClick", '3');
    }

    setSelectedItem(idMenu);
  };

  const handleMenu = () => {
    setHandleMenuOpen(!handleMenuOpen);
  }

  return (
    <header
      className="platform-header"
      style={{ position: "relative", height: "89px", width: "100%" }}
    >
      <Grid container md={12} sm={12}>
        <Grid md={10.25} sm={11}>
          <Card
            sx={{
              boxShadow: "none",
              background: "#1781E2",
              display: "flex",
              justifyContent: "space-between",
              marginRight: "84px",
            }}
          >
            <Box display="flex" alignItems="center">
              <Box marginRight="47px">
                <LogoHeader />
              </Box>
              <Box
                border={"1px solid"}
                borderRadius="30px"
                borderColor={"#EEE"}
                height={"35px"}
                width="264px"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  fontFamily="Montserrat"
                  color="#EEE"
                  fontSize="16px"
                  fontWeight="200"
                >
                  <>Rio de Janeiro, {date}</>
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "800px",
                justifyContent: "space-between",
                alignItems: "center",
                marginRight: "20px",
              }}
            >
              {headerViewNotOwner.map((value: IHeader) => (
                <Box
                  key={value.id}
                  onClick={() => handleItemClick(value.id, value.title)}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    lineHeight: "0px",
                  }}
                >
                  {!loginOwnerProfile &&
                    <>
                      <Link to={value.link} style={{ margin: "6px" }}>
                        <Typography fontFamily="Montserrat" color="#fff">
                          {value.title}
                        </Typography>
                      </Link>
                      <span
                        style={{
                          display: "block",
                          alignSelf: "center",
                          width: "92px",
                          borderRadius: "3px",
                          borderBottom:
                            selectedItem === value.id
                              ? "3px solid #fafafa"
                              : "none",
                        }}
                      ></span>
                    </>
                  }
                </Box>
              ))}

              {headerView.map((value: IHeader) => (
                <Box
                  key={value.id}
                  onClick={() => handleItemClick(value.id, value.title)}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    // lineHeight: "0px",
                  }}
                >
                  {loginOwnerProfile &&
                    <>
                      <Link to={value.link} style={{ margin: "0px" }}>
                        <Typography fontFamily="Montserrat" color="#fff">
                          {value.title}
                        </Typography>
                      </Link>
                      <span
                        style={{
                          display: "block",
                          alignSelf: "center",
                          width: "92px",
                          borderRadius: "3px",
                          borderBottom:
                            selectedItem === value.id
                              ? "3px solid #fafafa"
                              : "none",
                        }}
                      ></span>
                    </>
                  }
                </Box>
              ))}
            </Box>
          </Card>
        </Grid>
        <Grid item md={1} sm={1} display="flex">
          <Box
            display="flex"
            alignSelf="flex-end"
            sx={{
              width: "240px",
              position: 'relative',
              zIndex: '100',
            }}>
            <Box
              sx={{
                marginTop: "2px",
                width: "200px",
                height: "60px",
                backgroundColor: "#f1f3f4",
                display: "flex",
                paddingLeft: "17px",
                alignItems: "center",
                borderTopLeftRadius: "30px",
              }}
            >
              <Avatar
                alt="profile"
                sx={{ bgcolor: "#1781E2", marginRight: "22px" }}
              >
                {userName ? userName.charAt(0).toUpperCase() : "Error"}
              </Avatar>
              <Box>
                <Typography
                  fontFamily="Montserrat"
                  color="#5F6368"
                  fontWeight="600"
                  fontSize="16px"
                  fontStyle="italic"
                >
                  Hi,{" "}
                  {userName
                    ? userName.charAt(0).toUpperCase() + userName.slice(1)
                    : "Failed"}
                </Typography>
                <Typography
                  fontFamily="Montserrat"
                  color="#5F6368"
                  fontWeight="400"
                  fontSize="10px"
                  fontStyle="italic"
                >
                  {profileUser
                    ? profileUser.charAt(0).toUpperCase() +
                    profileUser.slice(1)
                    : ""}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                marginTop: "2px",
                marginLeft: "1px",
                width: "28px",
                height: "60px",
                backgroundColor: "#f1f3f4",
                borderRadius: "2px 30px 0 0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={handleMenu}
            >
              <IconButton disableRipple aria-label="delete">
                <ArrowDropDownIcon sx={{ color: "#1781E2" }} />
              </IconButton>
            </Box>
            <Box
              width="230px"
              height={handleMenuOpen ? '105px' : '0'}
              sx={{
                display: 'block',
                backgroundColor: '#fff',
                position: 'absolute',
                top: '64px',
                borderBottomLeftRadius: "30px",
                borderBottomRightRadius: "30px",
                transition: 'height .4s',
                overflow: handleMenuOpen ? 'visible' : 'hidden'
              }}
            >
              <Box
                sx={{
                  paddingLeft: '10px',
                  backgroundColor: '#f1f3f4',
                  alignItems: 'center',
                  display: 'flex',
                  height: '50px',
                }}>
                <Box>
                  <img src={person} alt="person"></img>
                </Box>
                <Typography
                  fontSize="18px"
                  sx={{
                    marginLeft: '15px',
                    cursor: 'pointer',
                    color: '#5F6368',
                    fontFamily: 'Montserrat',
                    fontSize: '18px'
                  }}
                  onClick={() => navigate('/profile')}>
                  Profile
                </Typography>
              </Box>
              <Box
                sx={{
                  marginTop: '2px',
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '10px',
                  backgroundColor: '#f1f3f4',
                  height: '50px',
                }}>
                <Box>
                  <img src={logout} alt="logout"></img>
                </Box>
                <Typography
                  fontSize="22px"
                  sx={{
                    marginLeft: '15px',
                    cursor: 'pointer',
                    color: '#5F6368',
                    fontFamily: 'Montserrat',
                    fontSize: '18px'
                  }}
                  onClick={logoutUser}>
                  Logout
                </Typography>
              </Box>
            </Box>
          </Box>
          {/* <Box marginLeft="4px" marginBottom="20px">
            <Button
              onClick={logoutUser}
              variant="outlined"
              startIcon={<SunHeader />}
            ></Button>
          </Box> */}
        </Grid>
      </Grid>
    </header>
  );
};

export default Header;
