import Api from "./api/axios-config";

export class FinancialService {
  async getResourceVm(tokenUser: string, entid: string) {
    return Api(tokenUser).get(`/financial/enterprise/${entid}`);
  }

  async getDatYear(tokenUser: string, entId: string, year: number) {
    return Api(tokenUser).get(`/financial/enterprise/${entId}/year/${year}`);
  }

  async getQuickFinancialEnt(tokenUser: string, idEnt: string) {
    return Api(tokenUser).get(`financial/quick/enterprise/${idEnt}`);
  }

  async getQuickFinancialOrg(tokenUser: string, idOrg: string) {
    return Api(tokenUser).get(`financial/quick/organization/${idOrg}`);
  }

}
