// Core
import React, { FC, forwardRef } from 'react';
import { TextField, TextFieldProps, Typography } from '@mui/material';

import { fonts, colors, border } from '../../style/themes';

export const InputProfile: FC<TextFieldProps & { inputStyles?: object, errorText?: boolean }> =
  forwardRef(({ inputStyles, errorText, ...props }, ref) => {
    return (
      <>
        <TextField
          ref={ref}
          {...props}
          fullWidth
          sx={{
            mt: '14px',
            fieldset: {
              border: `1px solid ${colors.secondary[500]}`,
              borderRadius: border.radius.primary,
              boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
              '&:hover .css-10aqn60-MuiFormControl-root-MuiTextField-root': {
                border: `4px solid ${colors.secondary[500]}`,
              },
            },
            input: {
              padding: '19px',
              fontSize: fonts.size[100],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight[500],
              color: colors.primary[800],
              background: colors.primary[150],
              ...inputStyles,
            },
          }}
        />
        {errorText
          ? (
            <Typography sx={{
              fontSize: fonts.size[100],
              fontFamily: fonts.family.secondary,
              fontWeight: fonts.weight[500],
              color: colors.error['100'],
              margin: '0'
            }}>
              Required field
            </Typography>)
          : <Typography sx={{ height: '20px' }}></Typography>}
      </>
    );
  });
