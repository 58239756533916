export const SunHeader: React.FC = () => (
  <svg
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M14.4987 22.637C10.0104 22.637 6.35961 18.9863 6.35961 14.4979C6.35961 10.0096 10.0103 6.35889 14.4987 6.35889C18.987 6.35889 22.6377 10.0096 22.6377 14.4979C22.6377 18.9863 18.987 22.637 14.4987 22.637Z" fill="white" />
    <path d="M14.4989 4.06797C13.9696 4.06797 13.5417 3.64008 13.5417 3.1108V0.957172C13.5417 0.427876 13.9696 0 14.4989 0C15.0282 0 15.4561 0.427889 15.4561 0.957172V3.1108C15.4561 3.63655 15.0282 4.06797 14.4989 4.06797Z" fill="white" />
    <path d="M23.2285 7.1202C23.0401 7.30861 22.7971 7.39939 22.5505 7.39939C22.3039 7.39939 22.0612 7.30506 21.8725 7.1202C21.499 6.74669 21.499 6.14128 21.8725 5.76423L23.3951 4.24156C23.7687 3.86805 24.3741 3.86805 24.7511 4.24156C25.1246 4.61508 25.1246 5.22049 24.7511 5.59753L23.2285 7.1202Z" fill="white" />
    <path d="M28.0428 15.4554H25.8892C25.3599 15.4554 24.932 15.0275 24.932 14.4982C24.932 13.9689 25.3599 13.541 25.8892 13.541H28.0428C28.5721 13.541 29 13.9689 29 14.4982C29 15.0275 28.5721 15.4554 28.0428 15.4554Z" fill="white" />
    <path d="M21.8764 21.8758C22.2499 21.5023 22.8553 21.5023 23.2323 21.8758L24.755 23.3985C25.1285 23.772 25.1285 24.3774 24.755 24.7545C24.5666 24.9429 24.3236 25.0337 24.077 25.0337C23.8304 25.0337 23.5877 24.9393 23.399 24.7545L21.8763 23.2318C21.4993 22.8545 21.4993 22.2491 21.8763 21.8758H21.8764Z" fill="white" />
    <path d="M14.4989 28.9996C13.9696 28.9996 13.5417 28.5717 13.5417 28.0424V25.8888C13.5417 25.3595 13.9696 24.9316 14.4989 24.9316C15.0282 24.9316 15.4561 25.3595 15.4561 25.8888V28.0424C15.4561 28.5717 15.0282 28.9996 14.4989 28.9996Z" fill="white" />
    <path d="M4.92061 25.0332C4.67404 25.0332 4.43127 24.9389 4.24259 24.754C3.86908 24.3805 3.86908 23.7751 4.24259 23.398L5.76526 21.8754C6.13878 21.5018 6.74419 21.5018 7.12123 21.8754C7.49475 22.2489 7.49475 22.8543 7.12123 23.2313L5.59856 24.754C5.41016 24.9391 5.16713 25.0332 4.92054 25.0332H4.92061Z" fill="white" />
    <path d="M3.11167 15.4554H0.958053C0.428756 15.4554 0.000882149 15.0275 0.000882149 14.4982C0.000882149 13.9689 0.428769 13.541 0.958053 13.541H3.11167C3.64097 13.541 4.06885 13.9689 4.06885 14.4982C4.06859 15.0275 3.63716 15.4554 3.11167 15.4554Z" fill="white" />
    <path d="M6.44621 7.40365C6.19964 7.40365 5.95687 7.30932 5.76819 7.12446L4.24552 5.60179C3.87201 5.22827 3.87201 4.62286 4.24552 4.24582C4.61904 3.86877 5.22445 3.87231 5.60149 4.24582L7.12416 5.76849C7.49768 6.14201 7.49768 6.74742 7.12416 7.12446C6.9355 7.30958 6.68894 7.40365 6.44614 7.40365H6.44621Z" fill="white" />
  </svg>
);

