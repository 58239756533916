import { Card } from "@mui/material";
import { DeployBaasConfirmation } from "./DeployBaasConfirmation/DeployBaasConfirmation";
import { DeployBaasNameDesc } from "../../components/Platform/DeployBaas/DeployBaasWizard/WizardBaasSteps/DeployBaasNameDesc";
import { DeployBaasStack } from "../../components/Platform/DeployBaas/DeployBaasWizard/WizardBaasSteps/DeployBaasStack";
import { DeployBaasInitial } from "../../components/Platform/DeployBaas/DeployBaasWizard/WizardBaasSteps/DeployBaasInitial";
import { DeployBaasMode } from "../../components/Platform/DeployBaas/DeployBaasWizard/WizardBaasSteps/DeployBaasMode";
import { DeployBaasVMSetup } from "../../components/Platform/DeployBaas/DeployBaasWizard/WizardBaasSteps/DeployBaasVMSetup";
import { DeployBaasProcess } from "./DeployBaasProcess/DeployBaasProcess";
import { DeployBaasLicense } from "./DeployBaasLicense/DeployBaasLicense";

export type DeployStepProps = {
  step: number;
  handleNextStep: () => void;
  setSelectedOrg: (org: string) => void;
  setNetworkName: (name: string) => void;
  selectedOrg: string;
  networkName: string;
  tokenUser: string;
};

export const DeployBaasSteps = ({
  step,
  handleNextStep,
  setSelectedOrg,
  setNetworkName,
  selectedOrg,
  networkName,
  tokenUser,
}: DeployStepProps) => {
  let stepComponent;
  let cardHeight = "600px";
  if (step === 0) {
    stepComponent = <DeployBaasInitial handleNextStep={handleNextStep} />;
    cardHeight = "761px";
  } else if (step === 1) {
    stepComponent = <DeployBaasMode />;
  } else if (step === 2) {
    stepComponent = stepComponent = (
      <DeployBaasNameDesc
        setSelectedOrg={setSelectedOrg}
        setNetworkName={setNetworkName}
        selectedOrg={selectedOrg}
        networkName={networkName}
        tokenUser={tokenUser}
      />
    );
  } else if (step === 3) {
    stepComponent = <DeployBaasStack />;
  } else if (step === 4) {
    stepComponent = <DeployBaasVMSetup tokenUser={tokenUser} />;;
  } else if (step === 5) {
    stepComponent =  <DeployBaasConfirmation />;
  } else if (step === 6) {
    stepComponent = <DeployBaasProcess tokenUser={tokenUser} />;
  }

  return (
    <Card
      sx={{
        backgroundColor: "#FFFFFF",
        boxShadow: "none",
        borderRadius: "20px",
        width: "560px",
        height: "100%",
        paddingLeft: "50px",
        paddingRight: "43px",
        paddingTop: "20px",
      }}
    >
      {stepComponent}
    </Card>
  );
};
