import { Box, Typography } from "@mui/material";

export type TSelectBox = {
  heading: string;
  provisioner: string;
  selected: boolean;
  enabled: boolean;
  handleSelect: () => void;
};

export const SelectBox = ({
  heading,
  provisioner,
  selected,
  handleSelect,
  enabled,
}: TSelectBox) => {
  let backgroundColor = "#F5F5F5";
  let border = "1px solid #7B8A93";
  let textColor = "#48535C";
  let textColorSecondary = "#5F6368";

  if (selected) {
    backgroundColor = "#d9e8f5";
    border = "1px solid #1781E2";
  }

  if (!enabled) {
    backgroundColor = "#F8F9FA";
    border = "1px solid #BDC5C9";
    textColor = "#7B8A93";
    textColorSecondary = "#7B8A93";
  }

  return (
    <Box
      sx={{
        height: "139px",
        width: "182px",
        backgroundColor: backgroundColor,
        border: border,
        paddingTop: "50px",
        paddingLeft: "15px",
        paddingRight: "15px",
        ":hover": {
          cursor: enabled ? "pointer" : "not-allowed",
        },
      }}
      onClick={handleSelect}
    >
      <Typography
        fontFamily="Montserrat"
        fontWeight="600"
        align="center"
        color={textColor}
        fontSize={"24px"}
        lineHeight={"26px"}
      >
        {heading}
      </Typography>
      <Typography
        fontFamily="Montserrat"
        fontWeight="600"
        align="center"
        color={textColorSecondary}
        fontSize={"12px"}
        lineHeight={"12px"}
      >
        {provisioner}
      </Typography>
    </Box>
  );
};
