import { Box, Card, Grid, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import { WizardContext } from "../../../../context/wizardContext";
import substrate from "../../../../assets/imgs/substrate.png";
import besu from "../../../../assets/imgs/besu.png";
import nameSubstrate from "../../../../assets/imgs/nameSubstrate.png";

export const ConfirmationBaasList = () => {
  // @ts-ignore
  const { deployData, setDeployData } = useContext(WizardContext);

  useEffect(() => {
    setDeployData({ ...deployData, buttonColor: "#1976d2" });
  }, []);

  return (
    <>
      <Box
        sx={{
          paddingBottom: "20px",
          paddingTop: "20px",
          borderTop: "1px dashed #000000",
          borderBottom: "1px dashed #000000",
          width: "100%",
        }}
      >
        <Box>
          <Box>
            <Grid container md={12}>
              <Grid item md={4} sx={{ paddingBottom: "9px" }}>
                <Typography
                  fontFamily="Montserrat"
                  fontSize="13px"
                  fontWeight="600"
                  lineHeight="35px"
                  color="#3E474D"
                >
                  Enterprise
                </Typography>
                <Typography
                  fontFamily="Montserrat"
                  fontSize="12px"
                  fontWeight="500"
                  lineHeight="14.63px"
                  color="#5F6368"
                >
                  {deployData.selectedEnterprise.slice(4)}
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography
                  fontFamily="Montserrat"
                  fontSize="13px"
                  fontWeight="600"
                  lineHeight="35px"
                  color="#3E474D"
                >
                  Organization
                </Typography>
                <Typography
                  fontFamily="Montserrat"
                  fontSize="12px"
                  fontWeight="500"
                  lineHeight="14.63px"
                  color="#5F6368"
                >
                  {deployData.selectedOrg.slice(4)}
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography
                  fontFamily="Montserrat"
                  fontSize="13px"
                  fontWeight="600"
                  lineHeight="35px"
                  color="#3E474D"
                >
                  Network Name
                </Typography>
                <Typography
                  fontFamily="Montserrat"
                  fontSize="12px"
                  fontWeight="500"
                  lineHeight="14.63px"
                  color="#5F6368"
                >
                  {deployData.networkName}
                </Typography>
              </Grid>
              <Grid item md={4}>
                <Typography
                  fontFamily="Montserrat"
                  fontSize="13px"
                  fontWeight="600"
                  lineHeight="20px"
                  color="#3E474D"
                >
                  Protocol
                </Typography>
                <Card sx={{ boxShadow: "none", display: "flex" }}>
                  {deployData.protocolType === "besu" ? (
                    <Box>
                      <img src={besu} alt="besu" />
                    </Box>
                  ) : (
                    <>
                      <Box>
                        <img src={substrate} alt="substrate" />
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        justifyContent="center"
                      >
                        <Typography
                          fontFamily="Montserrat"
                          fontSize="9px"
                          fontWeight="400"
                          lineHeight="5px"
                          color="#1C1D1D"
                        >
                          multiledgers
                        </Typography>
                        <Box>
                          <img src={nameSubstrate} alt="name substrate" />
                        </Box>
                      </Box>
                    </>
                  )}
                </Card>
              </Grid>
              <Grid item md={4}>
                <Typography
                  fontFamily="Montserrat"
                  fontSize="13px"
                  fontWeight="600"
                  lineHeight="35px"
                  color="#3E474D"
                >
                  Version
                </Typography>
                <Typography
                  fontFamily="Montserrat"
                  fontSize="12px"
                  fontWeight="500"
                  lineHeight="14.63px"
                  color="#5F6368"
                >
                  {deployData.version}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};
