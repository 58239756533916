import React, { useState } from "react";
import axios from "axios";

import { mnemonicGenerate } from "@polkadot/util-crypto";
import { keyring as uiKeyring } from "@polkadot/ui-keyring";
import { waitReady } from "@polkadot/wasm-crypto";

import { StyledEnterpriseWallet } from "../../Styles/EnterpriseWallet.styled";
import walletIcon from "~/assets/wallet_icon.png";
import walletWarningInfo from "~/assets/warning_wallet_info.png";
import copyButton from "~/assets/copyButton.png";
import { DeployAuthorization } from "../../../components/deployAuthorization";

import { ApiPromise, WsProvider } from "@polkadot/api";
import { useSnackbar } from "notistack";

function EnterpriseWallet(props: {
  setFormInfo: (arg0: any) => void;
  previousHandleClick: () => void;
  nextHandleClick: () => void;
  formInfo: {
    userFirstName: string;
    userLastName: string;
    userEmail: string;
    userPhone: string;
    userJobTitle: string;
    userCPF: string;
    userPassword: string;
    enterpriseName: string;
    enterpriseCNPJ: string;
    enterpriseZipCode: string;
    entepriseAddress: string;
    entepriseNumber: string;
    entepriseComplement: string;
    entepriseCountry: string;
    entepriseState: string;
    entepriseCity: string;
    paymentMethod: string;
    userWalletAddress: string;
    userWalletName: string;
    userWalletPassword: string;
    enterpriseWalletAddress: string;
    enterpriseWalletName: string;
    enterpriseWalletPassword: string;
  };
}) {
  const mnemonic = mnemonicGenerate();
  const { enqueueSnackbar } = useSnackbar();
  const [walletName, setWalletName] = useState(
    props.formInfo.enterpriseWalletName
  );
  const [passwordAuthorization, setPasswordAuthorization] = useState<string>('');
  const [rawEnterpriseMnemonicWords, setRawEnterpriseMnemonicWords] =
    useState(mnemonic);
  const [enterpriseMnemonicWords, setEnterpriseMnemonicWords] = useState(
    rawEnterpriseMnemonicWords.split(" ")
  );
  const [enterpriseWalletAddress, setEnterpriseWalletAddress] = useState("");
  const [isEnterpriseWalletWasStoraged, setEnterpriseWalletWasStoraged] =
    useState(false);
  const [openAuthorization, setOpenAuthorization] = useState<boolean>(false);
  const [loadingAuthorization, setLoadingAuthorization] =
    useState<boolean>(false);

  const handleSubmit = () => {
    setOpenAuthorization(true);
  };

  const handleCloseAuthoraztion = () => {
    setOpenAuthorization(false);
  };

  const onSubmitAuthorization = async (event?: any) => {
    setLoadingAuthorization(true);
    // if (anyEmptyField()) {
    //   alert("Please fill all fields");
    // }
    const data = {
      ...props.formInfo,
      enterpriseWalletAddress: enterpriseWalletAddress,
    };

    props.setFormInfo(data);

    try {
      await waitReady();
      uiKeyring.loadAll({ ss58Format: 42, type: "sr25519" });
    } catch (error) {
      console.log(error)
    } finally {
      const provider = new WsProvider(process.env.REACT_APP_CHAIN_ADDRESS);
      const api = await ApiPromise.create({provider});

      try {
        const userPair = uiKeyring.getPair(data.userWalletAddress);
        const entPair = uiKeyring.getPair(data.enterpriseWalletAddress);
        try {
          userPair.unlock(passwordAuthorization);
        } catch (error) {
          console.log(error)
          enqueueSnackbar('The password of your wallet is invalid, try again', {
            variant: 'error'
          });
          setLoadingAuthorization(false);
          return;
        }
        try {
          entPair.unlock(data.enterpriseWalletPassword);
        } catch (error) {
          console.log(error)
          enqueueSnackbar('The password of your wallet is invalid, try again', {
            variant: 'error'
          });
          setLoadingAuthorization(false);
          return;
        }
    
        const userUUID = window.crypto.randomUUID();
    
        const entUUID = window.crypto.randomUUID();
    
        const genesisHash = api.genesisHash;
        const runtimeVersion = api.runtimeVersion;

        let nonce = await api.rpc.system.accountNextIndex(data.userWalletAddress);
    
        const txUser = api.tx.multiledgersIdentity
        .signUp(userUUID, data.userWalletAddress).sign(userPair, {genesisHash, blockHash: genesisHash, nonce, runtimeVersion});
    
        event.preventDefault(); // 👈️ prevent page refresh
    
        const ownerInfo = {
          id: userUUID,
          firstName: data.userFirstName,
          lastName: data.userLastName,
          phone: data.userPhone.replace(/[./-]/g, ""),
          email: data.userEmail,
          cpf: data.userCPF.replace(/[./-]/g, ""),
          zipCode: "20090001",
          password: data.userPassword,
          jobTitle: data.userJobTitle,
          walletAddress: data.userWalletAddress,
          walletName: data.userWalletName,
          signedTx: txUser,
        };
        let ownerCreated;
        try {
          ownerCreated = await axios.post(
            `${process.env.REACT_APP_API_URL_ADDRESS}` + "/user",
            ownerInfo
            );
        } catch (error: any) {
            setLoadingAuthorization(false);
            enqueueSnackbar(error.message, {
              variant: "error",
            });
            return;
        }
        nonce = await api.rpc.system.accountNextIndex(data.userWalletAddress);

        const txEnt = api.tx.multiledgersIdentity
        .createEnterprise(userUUID, entUUID, data.enterpriseWalletAddress).sign(userPair, {genesisHash, blockHash: genesisHash, nonce, runtimeVersion});

        const entepriseInfo = {
          id: entUUID,
          ownerId: ownerCreated?.data[0].id || '',
          name: data.enterpriseName,
          cnpj: data.enterpriseCNPJ.replace(/[./-]/g, ""),
          zipCode: data.enterpriseZipCode.replace(/[-]/g, ""),
          number: data.entepriseNumber,
          address: data.entepriseAddress,
          complement: data.entepriseAddress,
          country: data.entepriseCountry,
          state: data.entepriseState,
          city: data.entepriseCity,
          walletName: data.enterpriseWalletName,
          walletAddress: data.enterpriseWalletAddress,
          signedTx: txEnt,
        };

        const enterpriseCreated: { id: string } = await axios.post(
          `${process.env.REACT_APP_API_URL_ADDRESS}` + "/enterprise",
          entepriseInfo
        );

        handleCloseAuthoraztion();
        setLoadingAuthorization(false);
        props.nextHandleClick();
      }
      catch (error: any) {
        console.log(error)
        setLoadingAuthorization(false);
        enqueueSnackbar('General Error', {
          variant: 'error'
        });
        return;
      }
      await api.disconnect();
    };
  }
  const listItems = enterpriseMnemonicWords.map((word) => (
    <li key={word.toString()}>
      <a>{word}</a>
    </li>
  ));

  const handleClick = async (event: any) => {
    const data = {
      ...props.formInfo,
      enterpriseWalletAddress: enterpriseWalletAddress,
    };
    // props.setFormInfo(data);

    
    const createWallet = async () => {
      try {
        await waitReady();
        uiKeyring.loadAll({ ss58Format: 42, type: "sr25519" });
      } catch (error) {
        setEnterpriseWalletAddress("");
      } finally {
        const { pair, json } = uiKeyring.addUri(
          rawEnterpriseMnemonicWords,
          `${props.formInfo.enterpriseWalletPassword}`,
          { name: `${props.formInfo.enterpriseWalletName}` }
          );
          setEnterpriseWalletAddress(pair.address);
        }
      };
      
      createWallet();
      setEnterpriseWalletWasStoraged(event.target.checked);
  };

  return (
    <>
      <DeployAuthorization
        open={openAuthorization}
        loadingAuthorization={loadingAuthorization}
        onSubmitAuthorization={onSubmitAuthorization}
        handleCloseAuthorization={handleCloseAuthoraztion}
        setPasswordAuthorization={setPasswordAuthorization}
        title="CREATE USER"
        subText="This action will authorize the creation of a new user"
        confirm="Sign"
        cancel="Cancel"
      />
      <StyledEnterpriseWallet>
        <div className="container-owner-wallet">
          <div className="header">
            <h1 className="header-title">Enterprise Wallet Generation</h1>
            <h5 className="header-subtitle">
              Please write your mnemonic seed and keep it in a safe place. The
              mnemonic can be used to restore your wallet.
            </h5>
          </div>
          <div className="content">
            <div className="wallet-info">
              <div className="wallet-info-icon">
                <img src={walletIcon} alt="walletIcon" />
              </div>
              <div className="wallet-more-info">
                <div className="wallet-more-info-name">
                  {walletName == ""
                    ? "<unknown> wallet"
                    : `${walletName} wallet`}
                </div>
                <div className="wallet-address-button">
                  {enterpriseWalletAddress ? (
                    <div className="wallet-more-info-address">
                      {enterpriseWalletAddress}
                    </div>
                  ) : (
                    <div className="wallet-more-info-address">
                      Click in "I have saved my mnemonic..." to see your wallet
                      address
                    </div>
                  )}

                  <button
                    className="wallet-address-copy-button"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        enterpriseWalletAddress.toString()
                      );
                    }}
                  >
                    <img src={copyButton} alt="walletIcon" />
                  </button>
                </div>
              </div>
            </div>
            <div className="mnemonic-info">
              <div className="mnemonic-title">
                GENERATED 12 WORD MNEMONIC SEED:
              </div>
              <div className="mnemonic-words">
                <ul>{listItems}</ul>
              </div>
              <button
                className="mnemonic-copy-button"
                onClick={() => {
                  navigator.clipboard.writeText(
                    enterpriseMnemonicWords.toString().split(",").join(" ")
                  );
                }}
              >
                Copy mnemonic words
              </button>
            </div>
            <div className="warning-info">
              <div className="warning-icon">
                <img src={walletWarningInfo} alt="walletIcon" />
              </div>
              <div className="warning-text">
                Keep it carefully to not lose your assets.
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="footer-checkbox-mnemonic">
              <input
                className="checkbox-input"
                onClick={handleClick}
                type="checkbox"
                id="topping"
                name="topping"
                value="Paneer"
              />
              I have saved my mnemonic seed safely
            </div>
            <div className="footer-next-step">
              {isEnterpriseWalletWasStoraged && !!enterpriseWalletAddress ? (
                <button
                  onClick={handleSubmit}
                  className="footer-next-step-button"
                >
                  Next Step
                </button>
              ) : (
                <button
                  disabled
                  onClick={handleSubmit}
                  className="footer-next-step-button-disabled"
                >
                  Next Step
                </button>
              )}
            </div>
          </div>
        </div>
      </StyledEnterpriseWallet>
    </>
  );
}

export default EnterpriseWallet;
