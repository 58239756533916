import { Box, Button, Card, Typography, colors } from '@mui/material';
import Plus from '../../assets/imgs/plus.png';
import { WalletCard } from './index';
import Badge from '@mui/material/Badge';
import { useState } from 'react';
import { cryptoWaitReady } from '@polkadot/util-crypto';
import { keyring as uiKeyring } from "@polkadot/ui-keyring";


export type TCardSide = {
  title: string;
	subTitle: string;
	footer: string;
  walletAddress: string;
  goToWallet: () => void;
  dataWalletList: Array<any>;
};

export const CardSide = ({
  title,
	subTitle,
	footer,
  walletAddress,
  goToWallet,
  dataWalletList
}: TCardSide) => {

  const [isWalletLoaded, setWalletLoaded] = useState(true);

  const getWallet = async () => {
    try{
      await cryptoWaitReady();
      uiKeyring.loadAll({ ss58Format: 42, type: 'sr25519' });
    } catch (error) {
      console.log(error)
    } finally{
      try {
        const userPair = uiKeyring.getPair(walletAddress);
        if(userPair){
          setWalletLoaded(false)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  getWallet();

  return (
    <>
      <Card
        sx={{
          padding: '42px 28px 62px 28px',
          boxShadow: 'none',
          backgroundColor: '#F1F3F4',
          border: '1px solid #CBD5E1',
          width: '474px',
          maxHeight: '424px',
          borderRadius: '32px'
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Typography
              fontWeight="700"
              fontFamily="Montserrat"
              fontSize="2rem"
              lineHeight="48px"
              color="#1781E2"
              marginBottom={'32px'}
            >
              {title}
            </Typography>
            {!walletAddress && (
              <Typography
                fontSize="1rem"
                fontWeight="400"
                lineHeight="24px"
                color="#7B8A93"
                marginBottom="59px"
              >
                {subTitle}
              </Typography>
            )}
          </Box>
          <Box>
            <Badge badgeContent={''} color='success' invisible={isWalletLoaded}>
              <Button>
              </Button>
            </Badge>
          </Box>

        </Box>
        <Box
          onClick={goToWallet}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          {!walletAddress ? (
						<>
							<Box
								onClick={goToWallet}
								width="83px"
								height="83px"
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									backgroundColor: '#F1F3F4',
									border: '1px solid #6F7075',
									borderRadius: '50%',
									marginBottom: '72px',
									cursor: 'pointer'
								}}
							>
								<img src={Plus} alt="plus" />
							</Box>
							<Box>
								<Typography
									fontSize="1rem"
									fontWeight="400"
									lineHeight="24px"
									color="#fff"
								>
									{footer}
								</Typography>
							</Box>
						</>
					) : (
						// <ListWallet dataWalletList={dataWalletList} />
            <WalletCard dataWalletList={dataWalletList} walletAddress={walletAddress} />
					)}
        </Box>
      </Card>
    </>
  );
};
