import React, { FC, useContext, useState } from 'react';

import { Routes, Route, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Overview from './Overview';
import { UsageAmount } from './UsageAmount';
import Resources from './Resources';
import Licenses from './Licenses';
import { Wrapper } from '../../components/wrapper';
import Header from '../../components/Platform/Header/Header';
import { HomeHeader } from '../../components/Platform/Home/HomeHeader/HomeHeader';
import { WizardContext } from "../../context/wizardContext";

interface IFinancial {
  tokenUser: string
  userName: string;
}
// export type TFinancial = {
//   tokenUser: string;
// };

const Financial = ({ userName, tokenUser }: IFinancial) => {
  const [dataFinancial, setDataFinancial] = useState({
    totalValue: 0,
    totalEstimateValue: 0
  })
  // @ts-ignore
  // const { tokenUser } = useContext(WizardContext);

  return (
    <>
      <WizardContext.Provider value={{ dataFinancial, setDataFinancial }}>
        <Header userName={userName} />
        <HomeHeader />
        <Wrapper
          sx={{ padding: { xs: '20px', xl: '0px 153px' } }}>
          <Box sx={{ flexGrow: 1, padding: '73px 0 0' }}>
            <Grid container spacing={2.75}>
              <Grid item lg={8.4}>
                <Overview tokenUser={tokenUser}/>
              </Grid>
              <Grid item lg={3.6}>
                <UsageAmount />
              </Grid>
              <Grid item lg={8.4}>
                <Resources tokenUser={tokenUser}/>
              </Grid>
              <Grid item lg={3.6}>
                <Licenses tokenUser={tokenUser}/>
              </Grid>
            </Grid>
          </Box>
        </Wrapper>
      </WizardContext.Provider>
    </>
  );
};

export default Financial;
