import { useContext, useState } from "react";
import { WizardContext } from "../../context/wizardContext";
import { formatTextLimit } from "../../utils/formatTextLenght";

import {
  Box,
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  Card,
  Grid,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Tooltip,
  FormHelperText,
} from "@mui/material";
import { useSnackbar } from "notistack";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import LoadingButton from "@mui/lab/LoadingButton";


import CopyIcon from "../../assets/imgs/copy.png";

interface ImodalGeneric {
  open: boolean;
  idMember?: string;
  onSubmitAuthorization?: () => void;
  title: string;
  subText: string;
  handleCloseAuthorization: () => void;
  loadingAuthorization: boolean;
  confirm: string;
  cancel: string;
  setPasswordAuthorization: (value: string) => void;
  passwordAuthorization?: string;
  userData?: any;
}

interface IState {
  password: string;
  showPassword: boolean;
}

export const DeployAuthorization: React.FC<ImodalGeneric> = ({
  open,
  confirm,
  cancel,
  onSubmitAuthorization,
  title,
  subText,
  handleCloseAuthorization,
  loadingAuthorization,
  setPasswordAuthorization,
  passwordAuthorization,
  userData
}) => {
  const { deployData, user } = useContext<any>(WizardContext);
  const { enqueueSnackbar } = useSnackbar();
  const [validateButton, setValidateButton] = useState<string>("");
  const [values, setValues] = useState<IState>({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const clipBoard = (walletCopy: string) => {
    navigator.clipboard.writeText(walletCopy);
    enqueueSnackbar("hash copied", {
      variant: "info",
    });
  };

  const changePasswordAuthorization = (e: any) => {
    setValidateButton(e.target.value);
    setPasswordAuthorization(e.target.value);
  };

  return (
    <>
      <Dialog
        sx={{
          "& .MuiPaper-root": {
            border: "none",
            position: "relative",
            borderRadius: "20px !important"
          },
        }}
        aria-describedby="dialogo de confirmação"
        open={open}
        onClose={handleCloseAuthorization}
        PaperProps={{
          style: {
            borderRadius: "18px",
            width: "551px",
          },
        }}
      >
        <DialogTitle
          sx={{
            position: "relative",
            backgroundColor: "rgb(162, 203, 253, .3)",
            padding: "64px 23px 63px 48px",
          }}
        >
          <Box
            onClick={handleCloseAuthorization}
            position="absolute"
            right="20px"
            top="20px"
            sx={{ cursor: "pointer" }}
          >
            <Typography fontSize="20px">X</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "transparent",
              "&.MuiPaper-root": {
                border: "none",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "32px",
                minWidth: "87px",
                minHeight: "87px",
                borderRadius: "50%",
                backgroundColor: "#1781E2",
              }}
            >
              <DoneIcon sx={{ color: "#fff", fontSize: "34px" }} />
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography
                color="#1781E2"
                fontWeight="700"
                fontSize="20px"
                fontFamily="Montserrat"
                marginBottom="3px"
              >
                {title}
              </Typography>
              <Typography
                sx={{
                  color: "#6F7075",
                  fontSize: "16px",
                  lineHeight: "25px",
                  textAlign: "start",
                  fontFamily: "Montserrat",
                }}
              >
                {subText}
              </Typography>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            marginTop: "25px",
            marginBottom: "26px",
            padding: 0,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              paddingLeft: "48px",
              marginBottom: "27px",
              alignContent: "center",
              "&.MuiPaper-root": {
                border: "none",
                // backgroundColor: "inherit",
              },
            }}
          >
            <Box marginRight="60px">
              <Box display="flex">
                <Typography
                  color="#2E57A3"
                  marginRight="11px"
                  fontFamily="Montserrat"
                >
                  Name:
                </Typography>
                <Typography
                  fontFamily="Montserrat"
                  fontWeight="400"
                  color="#000"
                >
                  <Tooltip title={deployData?.user?.firstName || user?.firstName || userData?.firstName} placement="top-start">
                    <Typography>{formatTextLimit(deployData?.user?.firstName || user?.firstName || userData?.firstName, 8)}</Typography>
                  </Tooltip>
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box display="flex" onClick={() => clipBoard(deployData?.user?.walletAddress || user?.walletAddress || userData?.walletAddress)}>
                <Typography
                  color="#2E57A3"
                  marginRight="11px"
                  fontFamily="Montserrat"
                >
                  Wallet:{" "}
                </Typography>
                <Tooltip title={deployData?.user?.walletAddress || user?.walletAddress || userData?.walletAddress} arrow>
                  <Typography marginRight="8px" fontFamily="Montserrat">
                    {formatTextLimit(deployData?.user?.walletAddress || user?.walletAddress || userData?.walletAddress, 10)}
                  </Typography>

                </Tooltip>
                <img
                  src={CopyIcon}
                  alt="copy"
                  style={{ cursor: "pointer" }}
                ></img>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              paddingLeft: "48px",
              "&.MuiPaper-root": {
                border: "none",
                // backgroundColor: "inherit",
              },
            }}
          >
            <Grid container md={12}>
              <Grid item md={11}>
                <InputLabel error={false} shrink htmlFor="password">
                  <Typography
                    color="#2E57A3"
                    fontSize="1.25rem"
                    fontFamily="Montserrat"
                  >
                    Type your wallet password:
                  </Typography>
                </InputLabel>
                <OutlinedInput
                  sx={{ maxWidth: "451px", marginTop: "0px !important" }}
                  fullWidth
                  error={false}
                  placeholder="password"
                  id="outlined-adornment-password"
                  type={values.showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end" sx={{ marginRight: "25px" }}>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {values.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  onChange={(event) => changePasswordAuthorization(event)}
                />
                {false &&
                  <FormHelperText error>
                    <Typography>Incorrect password!</Typography>
                  </FormHelperText>
                }
              </Grid>
            </Grid>
          </Box>
        </DialogContent>

        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "39px",
          }}
        >
          <Button
            onClick={handleCloseAuthorization}
            variant="outlined"
            color="success"
            sx={{
              width: "124px",
              height: "43px",
              marginRight: "73px",
              padding: "4px 24px",
              borderRadius: "25px",
              color: "#000",
              borderColor: "#000",
              textTransform: "initial",
              fontWeight: "400",
              "&:hover": {
                borderColor: "#000",
              },
            }}
          >
            <Typography fontSize="20px" fontFamily="Montserrat">
              {" "}
              {cancel}
            </Typography>
          </Button>
          <LoadingButton
            loading={loadingAuthorization}
            disabled={!validateButton}
            onClick={onSubmitAuthorization}
            variant="outlined"
            color="error"
            sx={{
              width: "124px",
              height: "43px",
              padding: "4px 24px",
              borderRadius: "25px",
              color: "#000",
              borderColor: "#000",
              fontSize: "20px",
              backgroundColor: "#E4F0FF",
              textTransform: "initial",
              fontFamily: "Montserrat",
              fontWeight: "400",
              "&:hover": {
                borderColor: "#000",
                backgroundColor: "#E4F0FF",
              },
            }}
          >
            {confirm}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};