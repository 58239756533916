import React, { FC, useState, useEffect } from 'react';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

import { License } from '../../../components/License';
import { LicenseService } from "../../../services/license.service";
import { keycloak } from "../../../utils/Keycloak";

import { fonts, colors } from '../../../style/themes';
import { customThinScroll } from '../../../style/scrollBarStyle';
import { useSnackbar } from "notistack";
import { removeLocalStorage } from '../../../generalLocalStorage';

interface ITLicenses {
  tokenUser: string
}

interface IDataValue {
  dataValue: {
    id: string;
    type: string;
    createdAt: string;
    expires: string;
  }
}

const Licenses: FC<ITLicenses> = ({ tokenUser }) => {
  const idEnt = localStorage.getItem("idEnterprise") || '';
  const licenseService = new LicenseService();
  const [dataValue, setDataValue] = useState<IDataValue | null>(null)

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const respGetLicense = async () => {
      try {
        if (idEnt && tokenUser) {
          const resultLicense = await licenseService.getEnterpriseLicense(idEnt, tokenUser)
          if ([403, 404, 500].includes(resultLicense.status)) {
            enqueueSnackbar(resultLicense.statusText, {
              variant: "error",
            })
          }
          if (resultLicense.status === 200) {
            setDataValue(resultLicense.data);
          }
        }
      } catch (error: any) {
        if (error?.response?.status === 401) {
          removeLocalStorage()
          await keycloak.logout();
        }
      }

    }

    respGetLicense()

  }, [])

  if (dataValue) {
    return (
      <Paper
        sx={{
          padding: '14px 23px 0 12px',
          boxShadow: 'none',
          background: '#F1F3F4',
          border: '1px solid #CBD5E1',
          borderRadius: '30px',
          height: '345px',
        }}
      >
        <Typography
          sx={{
            paddingLeft: '13px',
            paddingBottom: '37px',
            fontSize: fonts.size[300],
            fontFamily: fonts.family.secondary,
            fontWeight: fonts.weight.secondary,
            color: colors.secondary[500],
          }}
        >
          LICENSES
        </Typography>
        <Box sx={{
          boxSizing: 'border-box',
          padding: '0 13px 10px',
          height: '80%',
          overflowY: 'auto',
          ...customThinScroll
        }}>
          {dataValue
            ? Object.values(dataValue).map(item => <License dataValue={item} key={window.crypto.randomUUID()} />)
            : (<Box
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              No itens were found
            </Box>)
          }
        </Box>
      </Paper>
    );
  } else {
    return (
      <Skeleton
        variant="rectangular"
        sx={{
          width: '100%',
          height: '345px',
          boxSizing: 'border-box',
          border: '1px solid #CBD5E1',
          borderRadius: '30px',
        }} />
    )
  }
};

export default Licenses;
