export const Clip: React.FC = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    Î<path d="M18.8185 9.29195L10.3943 17.7161C9.36229 18.7481 7.96256 19.3279 6.50306 19.3279C5.04356 19.3279 3.64383 18.7481 2.61181 17.7161C1.57978 16.6841 1 15.2844 1 13.8249C1 12.3654 1.57978 10.9656 2.61181 9.93361L10.4676 2.07778C11.1557 1.38855 12.0893 1.00086 13.0632 1C14.037 0.999142 14.9713 1.38518 15.6606 2.0732C16.3498 2.76121 16.7375 3.69485 16.7383 4.66871C16.7392 5.64257 16.3532 6.57688 15.6651 7.26611L7.79098 15.1219C7.44697 15.466 6.98039 15.6592 6.49389 15.6592C6.00739 15.6592 5.54082 15.466 5.19681 15.1219C4.8528 14.7779 4.65954 14.3114 4.65954 13.8249C4.65954 13.3384 4.8528 12.8718 5.19681 12.5278L12.9793 4.75445" stroke="#7B8A93" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);
