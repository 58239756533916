import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Avatar, Box, Grid, Typography, Tooltip } from '@mui/material';
import recover from '../../assets/imgs/dowload.png';
import profileFace from '../../assets/imgs/pfofileexemplo.jpeg';

interface IWalletCard {
  dataWalletList: Array<any>;
  walletAddress: string;
}

export const WalletCard = ({ dataWalletList, walletAddress }: IWalletCard) => {

  return (
    <>
      <Grid container md={12}>
        <Box
          sx={{
            overflow: 'auto',
            width: '100%',
            height: '453px',
            '::-webkit-scrollbar': {
              border: '1px solid #D4D7DE',
              width: '15px',
              borderRadius: '15px'
            },
            '::-webkit-scrollbar-thumb': {
              backgroundColor: '#D9D9D9',
              borderRadius: '15px',
              height: '170px',
              width: '15px'
            }
          }}
        >
          {dataWalletList?.length &&
            dataWalletList.map((value: any) => (
              <Grid
                item
                md={10}
                display="flex"
                alignItems="center"
                marginBottom="16px"
              >
                <>
                  <Box
                    sx={{
                      marginRight: '9px',
                      borderRadius: '55px',
                      width: '41px',
                      height: '41px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#fff',
                    }}>
                    {/* <img
                      width="47px"
                      height="47px"
                      style={{
                        marginLeft: '4px',
                        borderRadius: '25px',
                        marginRight: '4px'
                      }}
                      src={profileFace}
                    ></img> */}
                    <Avatar
                      alt="profile"
                      sx={{ bgcolor: "#1781E2" }}
                    >
                      {'Matheus Silva' ? 'Matheus Silva'.charAt(0).toUpperCase() : "Error"}
                    </Avatar>
                  </Box>
                  <Box sx={{ position: 'relative', flexGrow: '2' }} width="90%">
                    <Box
                      sx={{
                        position: 'relative',
                        zIndex: '10',
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: '20px',
                        width: '311px',
                        height: '48px',
                        backgroundColor: '#d9d9d9',
                        borderRadius: '100px'
                      }}
                    >
                      <Tooltip title={walletAddress} arrow>
                        <Typography>{walletAddress.substring(0,26) + '...'}</Typography>
                      </Tooltip>
                    </Box>
                    <Box
                      //onClick={goToRecoveryWallet}
                      sx={{
                        cursor: 'pointer',
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        paddingRight: '20px',
                        zIndex: '1',
                        top: '0',
                        right: '-48px',
                        width: '96px',
                        height: '48px',
                        backgroundColor: '#F32053',
                        borderRadius: '100px'
                      }}
                    >
                      <img src={recover}></img>
                    </Box>
                  </Box>
                </>
              </Grid>
            ))}
        </Box>
      </Grid>
    </>
  )
}
