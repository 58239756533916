import CustStepper from "../../../../components/Steppers/CustStepper";

interface ProcessStepperProps {
  percent: number;
  error: boolean;
  errorTicket: string;
}

export const ProcessStepper = ({
  percent,
  error,
  errorTicket,
}: ProcessStepperProps) => {
  return (
    <CustStepper error={error} percent={percent} errorTicket={errorTicket} />
  );
};
