import { Box, Grid, Typography } from "@mui/material";
import { useContext } from "react";
import awsLogo from "~/assets/imgs/awsLogo.png";
import googleCloud from "~/assets/imgs/googleCloud.png";
import { WizardContext } from "../../../../context/wizardContext";

export const ConfirmationBaasStack = () => {
  // @ts-ignore
  const { deployData } = useContext(WizardContext);

  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#FAFAFA",
        border: "1px solid #A2CBFE",
        borderRadius: "10px",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
      }}
    >
      <Grid container md={12}>
        <Grid item md={4}>
          <Typography
            fontFamily="Montserrat"
            fontSize="13px"
            fontWeight="600"
            color="#3E474D"
          >
            MVP
          </Typography>
        </Grid>
        <Grid item md={8}>
          <Grid container md={12}>
            <Grid item md={2}>
              <Typography
                fontFamily="Montserrat"
                fontSize="8px"
                fontWeight="500"
                lineHeight="15px"
                letterSpacing="1px"
                color="#5F6368"
              >
                CLOUD
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography
                fontFamily="Montserrat"
                fontSize="8px"
                fontWeight="500"
                letterSpacing="1px"
                lineHeight="15px"
                color="#5F6368"
              >
                REGION
              </Typography>
            </Grid>
            <Grid item md={2}>
              <Typography
                fontFamily="Montserrat"
                fontSize="8px"
                fontWeight="500"
                letterSpacing="1px"
                lineHeight="15px"
                color="#5F6368"
              >
                VCPU
              </Typography>
            </Grid>
            <Grid item md={2}>
              <Typography
                fontFamily="Montserrat"
                fontSize="8px"
                fontWeight="500"
                lineHeight="15px"
                letterSpacing="1px"
                color="#5F6368"
              >
                RAM
              </Typography>
            </Grid>
            <Grid item md={2}>
              <Typography
                fontFamily="Montserrat"
                fontSize="8px"
                fontWeight="500"
                lineHeight="15px"
                letterSpacing="1px"
                color="#5F6368"
              >
                DISK
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container md={12} sx={{ paddingTop: "33.6px" }}>
        <Grid item md={4}>
          <ul>
            {deployData.vms &&
              deployData.vms.map((value: any) => (
                <li>
                  <Typography
                    fontFamily="Montserrat"
                    fontSize="12px"
                    fontWeight="600"
                    lineHeight="14.63px"
                    color="#7B8A93"
                    paddingBottom="25px"
                  >
                    • {value.name}
                  </Typography>
                </li>
              ))}
          </ul>
        </Grid>

        <Grid item md={8}>
          <Grid container md={12}>
            <Grid item md={2}>
              {deployData.protocolType === "besu" ? (
                <img src={googleCloud} alt="cloud" />
              ) : (
                <img src={awsLogo} alt="cloud" />
              )}
            </Grid>
            <Grid item md={3}>
              <Typography
                fontFamily="Montserrat"
                fontSize="8px"
                fontWeight="600"
                letterSpacing="1px"
                lineHeight="15px"
                color="#48535C"
              >
                N. America
              </Typography>
            </Grid>
            <Grid item md={2}>
              <Typography
                fontFamily="Montserrat"
                fontSize="8px"
                fontWeight="600"
                letterSpacing="1px"
                lineHeight="15px"
                color="#48535C"
              >
                4vCPU
              </Typography>
            </Grid>
            <Grid item md={2}>
              <Typography
                fontFamily="Montserrat"
                fontSize="8px"
                fontWeight="600"
                lineHeight="15px"
                letterSpacing="1px"
                color="#48535C"
              >
                16Gb
              </Typography>
            </Grid>
            <Grid item md={2}>
              <Typography
                fontFamily="Montserrat"
                fontSize="8px"
                fontWeight="600"
                lineHeight="15px"
                letterSpacing={"1px"}
                color="#48535C"
              >
                50Gb
              </Typography>
            </Grid>
          </Grid>

          {deployData.protocolType !== "besu" && (
            <>
              <Grid container md={12} sx={{ paddingTop: "18px" }}>
                <Grid item md={2}>
                  <img src={awsLogo} alt="awsLogo" />
                </Grid>
                <Grid item md={3}>
                  <Typography
                    fontFamily="Montserrat"
                    fontSize="8px"
                    fontWeight="600"
                    letterSpacing="1px"
                    lineHeight="15px"
                    color="#48535C"
                  >
                    N. America
                  </Typography>
                </Grid>
                <Grid item md={2}>
                  <Typography
                    fontFamily="Montserrat"
                    fontSize="8px"
                    fontWeight="600"
                    letterSpacing="1px"
                    lineHeight="15px"
                    color="#48535C"
                  >
                    4vCPU
                  </Typography>
                </Grid>
                <Grid item md={2}>
                  <Typography
                    fontFamily="Montserrat"
                    fontSize="8px"
                    fontWeight="600"
                    lineHeight="15px"
                    letterSpacing="1px"
                    color="#48535C"
                  >
                    16Gb
                  </Typography>
                </Grid>
                <Grid item md={2}>
                  <Typography
                    fontFamily="Montserrat"
                    fontSize="8px"
                    fontWeight="600"
                    lineHeight="15px"
                    letterSpacing={"1px"}
                    color="#48535C"
                  >
                    50Gb
                  </Typography>
                </Grid>
              </Grid>
              <Grid container md={12} sx={{ paddingTop: "18px" }}>
                <Grid item md={2}>
                  <img src={awsLogo} alt="awsLogo" />
                </Grid>
                <Grid item md={3}>
                  <Typography
                    fontFamily="Montserrat"
                    fontSize="8px"
                    fontWeight="600"
                    letterSpacing="1px"
                    lineHeight="15px"
                    color="#48535C"
                  >
                    N. America
                  </Typography>
                </Grid>
                <Grid item md={2}>
                  <Typography
                    fontFamily="Montserrat"
                    fontSize="8px"
                    fontWeight="600"
                    letterSpacing="1px"
                    lineHeight="15px"
                    color="#48535C"
                  >
                    4vCPU
                  </Typography>
                </Grid>
                <Grid item md={2}>
                  <Typography
                    fontFamily="Montserrat"
                    fontSize="8px"
                    fontWeight="600"
                    lineHeight="15px"
                    letterSpacing="1px"
                    color="#48535C"
                  >
                    16Gb
                  </Typography>
                </Grid>
                <Grid item md={2}>
                  <Typography
                    fontFamily="Montserrat"
                    fontSize="8px"
                    fontWeight="600"
                    lineHeight="15px"
                    letterSpacing={"1px"}
                    color="#48535C"
                  >
                    50Gb
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
