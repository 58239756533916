import { useContext, useEffect, useState } from "react";
import { WizardContext } from "../../../../context/wizardContext";
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Card,
  Grid,
  Step,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";

import { DeployBaasSteps } from "../../../../pages/DeployBaas/DeployBaasSteps";
import { DeployAuthorization } from "../../../deployAuthorization";
import { ApiPromise, WsProvider } from "@polkadot/api";
import { cryptoWaitReady } from "@polkadot/util-crypto";
import uiKeyring from "@polkadot/ui-keyring";
import { UserService } from "../../../../services/user.services";
import { useSnackbar } from "notistack";
import { textError } from '../../../../utils/textError';


const steps = ["", "", "", "", "", "", ""];

export const DeployBaasWizard = ({ tokenUser, user }: any) => {
  
  const history = useNavigate();
  const userService = new UserService();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false)
  const [deployStep, setDeployStep] = useState(0);
  const [openAuthorization, setOpenAuthorization] = useState<boolean>(false);
  const [passwordAuthorization, setPasswordAuthorization] = useState<string>('');
  
  const [fezLoad, setFezLoad] = useState<boolean>(false);
  
  //@ts-ignore
  const { deployData, setDeployData } = useContext(WizardContext);

  const [stepNode, setStepNode] = useState<number>()

  useEffect(() => {
    if(!deployData.valueNode || deployData.valueNode === 'Unlimited') return;
    const numberNode = Number(deployData?.valueNode?.match(/\d+/)[0])
    if (numberNode > 0) {
      setStepNode(numberNode)
    }
  }, [deployData.valueNode])


  const [selectedOrg, setSelectedOrg] = useState(
    "870894ab-51fd-4594-bdfc-07790231ad65"
  );
  const [networkName, setNetworkName] = useState("");

  const handleCloseAuthoraztion = () => {
    setOpenAuthorization(false);
  };

  const submitNameAndDescription = async () => {
    try {
      if (deployData.networkName) {
        const respValidateNameNetwork = await userService.userAreThere(deployData.orgId, deployData.networkName, deployData.token)
        if (respValidateNameNetwork.status === 200) {
          return respValidateNameNetwork.data;
        } else {
          enqueueSnackbar("unable to register network", {
            variant: "error",
          })
          return respValidateNameNetwork.data;
        }
      }
    } catch (error: any) {
      const { statusCode, message } = error?.response?.data;
      if ([403, 400, 404].includes(statusCode)) {
        enqueueSnackbar(message, {
          variant: "error",
        })
      }
      console.error(error)
    }
  }

  const handleNextStep = async () => {
    if (deployStep === 0) {
      setDeployStep(deployStep + 1);
    }

    if (deployStep === 1) {
      setDeployData({ ...deployData });
      if (deployData.selectedMode !== "") {
        setDeployStep(deployStep + 1);
      }
    }
    if (
      deployStep === 2 &&
      deployData.selectedOrg !== "" &&
      deployData.enterpriseId !== "" &&
      deployData.networkName !== "" &&
      deployData.protocolType !== "" &&
      deployData.version !== ""
    ) {
      setDeployData({...deployData, consesus: "", selectedStack: "" });
      const result = await submitNameAndDescription();

      if (result) {
        setDeployStep(deployStep + 1);
      } else {
        enqueueSnackbar("this network name already exists!", {
          variant: "error",
        })
        setDeployStep(deployStep);

      }
    }
    if (deployData.protocolType !== 'substrate') {
      if (
        deployStep === 3 &&
        deployData.selectedStack === "mvp" || deployData.selectedStack === "development" &&
        deployData.consesus === "QBFT"
        
      ) {
        setDeployStep(deployStep + 1);
      }
    } else {
      if (
        deployStep === 3 &&
        deployData.selectedStack === "mvp"
      ) {
        setDeployStep(deployStep + 1);
      }
    }

    if (deployStep === 4 &&
      deployData.currentVm === `${stepNode} - lastNode` && deployData.fileSetup
    ) {
      setDeployData({ ...deployData });
      setDeployStep(deployStep + 1);
    }

    if (deployStep === 5) {
      setDeployData((prevState: any) => { return { ...prevState, buttonColor: "#1781E2" } })
      setDeployStep(deployStep + 1);
    }

    if (deployStep === 6) {
      setDeployStep(0);
      setDeployData({
        token: tokenUser,
        selectedOrg: "",
        selectedEnterprise: "",
        consesus: "",
        networkName: "",
        valueNode: "0 node",
        vmIp: "",
        vmUsername: "",
        name: "",
        mnemonic: "",
        selectedStack: "",
        selectedVM: "",
        currentVm: "1 - Node",
        selectedRegion: "",
        selectedLicense: "License 1",
        licenseId: "",
        version: '4.0',
        selectedOption: "Option 2",
        selectedMode: "",
        protocolType: "",
        currentEmpty: false,
        buttonColor: "#7b8a93",
        vms: [],
        idsArray: [],
        nextLocked: true,
        progress: "creating",
        deployUUID: "",
        signedTx: "",
        fileSetup: null
      });
    }
  };

  const onSubmitAuthorization = async () => {
    setLoading(true);
    const provider = new WsProvider(process.env.REACT_APP_CHAIN_ADDRESS);
    const api = await ApiPromise.create({
      provider
    });

    // PEGAR TODAS WALLETS
    try {
      await cryptoWaitReady();
      if (!fezLoad) {
        uiKeyring.loadAll({ ss58Format: 42, type: "sr25519" });
        setFezLoad(true);
      }
    } catch (error) {
      console.log(error)
    }

    const userUUID = localStorage.getItem("owner") as string;
    const entUUID = localStorage.getItem("enterpriseId") as string;
    let userPair: any

    try {
      userPair = uiKeyring.getPair(deployData?.user?.walletAddress ||  user?.walletAddress);
    } catch (error) {
      enqueueSnackbar(textError, {
        variant: 'error',
        autoHideDuration: 5000,
      });
      setTimeout(() => {
        history('/profile/wallet/recovery')
      }, 5000);
      setLoading(false);
      return
    }

    try {
      userPair.unlock(passwordAuthorization);

    } catch (error) {
      setLoading(false);
      enqueueSnackbar('Invalid password', {
        variant: "error",
      });
      return
    }

    const orgUUID = selectedOrg;

    const genesisHash = api.genesisHash;
    const runtimeVersion = api.runtimeVersion;

    const deployUUID = window.crypto.randomUUID();


    let nonce = await api.rpc.system.accountNextIndex(deployData?.user?.walletAddress);


    const txBaas = api.tx.multiledgersDeploy
      .requestDeployBaas(userUUID, entUUID, orgUUID, deployUUID).sign(userPair, { genesisHash, blockHash: genesisHash, nonce, runtimeVersion });

    await api.disconnect();



    setDeployData({ ...deployData, signedTx: txBaas, deployUUID: deployUUID });

    setDeployData((prevState: any) => { return { ...prevState, signedTx: txBaas, deployUUID: deployUUID } })

    setLoading(false);
    handleNextStep();
    handleCloseAuthoraztion()
  };

  const authorizationSign = () => {
    setOpenAuthorization(true);
  }

  const handleBackStep = () => {
    setDeployData({ ...deployData, buttonColor: "#1781E2" });
    setDeployStep(deployStep - 1);
  };

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 0.5,
      left: "calc(-50%)",
      right: "calc(50%)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#1781E2",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#1781E2",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 8,
      border: 0,
      backgroundColor: "#A3CCF3",
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled("div")<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ theme, ownerState }) => ({
    backgroundColor: "#86B9F7",
    zIndex: 1,
    color: "#fff",
    width: 8,
    height: 8,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundColor: "#1781E2",
      height: 10,
      width: 10,
      transform: "translateY(-1px)",
    }),
    ...(ownerState.completed && {
      backgroundColor: "#86B9F7",
    }),
  }));

  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {};

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  return (
    <Card
      sx={{
        position: "relative",
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.16)",
        border: "1px solid #CBD5E1",
        borderRadius: "20px",
        width: "560px",
        height: "761px",
      }}
    >
      {deployStep > 0 && (
        <Stepper
          alternativeLabel
          activeStep={deployStep}
          connector={<ColorlibConnector />}
          sx={{
            paddingLeft: "29px",
            paddingRight: "35px",
            paddingTop: "40px",
          }}
        >
          {steps.map((label) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};

            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps} StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      )}
      <DeployBaasSteps
        step={deployStep}
        handleNextStep={handleNextStep}
        setSelectedOrg={setSelectedOrg}
        setNetworkName={setNetworkName}
        selectedOrg={selectedOrg}
        networkName={networkName}
        tokenUser={tokenUser}
      />
      {deployStep > 0 && (
        <Grid
          container
          md={12}
          sx={{ paddingLeft: "37px", paddingRight: "43px", position: 'absolute', bottom: '30px' }}
        >
          <Grid item md={6} >
            {deployStep === 6 || (
              <Button
                sx={{
                  marginTop: '20px',
                  width: "90%",
                  height: "40px",
                  display: "flex",
                  fontSize: "14px",
                  fontFamily: "MontSerrat",
                  fontWeight: "400",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #1C1D1F",
                  boxShadow: "none",
                  borderRadius: "10px",
                  ":hover": {
                    backgroundColor: "#FFFFFF",
                    boxShadow: "none",
                  },
                }}
                variant="contained"
                onClick={() => handleBackStep()}
              >
                <Typography
                  fontWeight="600"
                  fontSize="14px"
                  fontFamily="Montserrat"
                  color="#5F6368"
                >
                  Go Back
                </Typography>
              </Button>
            )}
          </Grid>
          <Grid item md={6}>
            <DeployAuthorization
              open={openAuthorization}
              loadingAuthorization={loading}
              onSubmitAuthorization={onSubmitAuthorization}
              handleCloseAuthorization={handleCloseAuthoraztion}
              setPasswordAuthorization={setPasswordAuthorization}
              title="CONFIRM DEPLOY"
              subText="This action will authorize the deployment of your network"
              confirm="Sign"
              cancel="Cancel"
            />
            {deployStep !== 5 && (
              <Button
                disabled={deployStep === 6 && !deployData.statusButtonDone}
                sx={{
                  marginLeft: "15px",
                  marginTop: '20px',
                  width: "90%",
                  height: "40px",
                  display: "flex",
                  fontSize: "14px",
                  fontFamily: "MontSerrat",
                  fontWeight: "400",
                  backgroundColor: deployData.buttonColor,
                  borderRadius: "10px",
                  ":hover": {
                    boxShadow: "none",
                    backgroundColor: deployData.buttonColor,
                  },
                }}
                variant="contained"
                onClick={() => handleNextStep()}
              >
                <Typography
                  fontWeight="600"
                  fontSize="14px"
                  fontFamily="Montserrat"
                >
                  {deployStep !== 6 ? "Next Step" : "Done"}
                </Typography>
              </Button>
            )}
            {deployStep === 5 && (
              <Button
                sx={{
                  marginLeft: "15px",
                  marginTop: '20px',
                  width: "90%",
                  height: "40px",
                  display: "flex",
                  fontSize: "14px",
                  fontFamily: "MontSerrat",
                  fontWeight: "400",
                  backgroundColor: deployData.buttonColor,
                  borderRadius: "10px",
                  ":hover": {
                    boxShadow: "none",
                    backgroundColor: deployData.buttonColor,
                  },
                }}
                variant="contained"
                onClick={authorizationSign}
              >
                <Typography
                  fontWeight="600"
                  fontSize="14px"
                  fontFamily="Montserrat"
                >
                  Next step
                </Typography>
              </Button>
            )}
          </Grid>
        </Grid>
      )}
    </Card>
  );
};
