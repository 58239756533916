export const headerView = [
  {
    id: 1,
    title: 'ENTERPRISE',
    link: '/'
  },
  {
    id: 2,
    title: 'ORGANIZATION',
    link: '/organizations'
  },
  {
    id: 3,
    title: 'RESOURCE',
    link: '/resources'
  },
  {
    id: 4,
    title: 'FINANCIAL',
    link: '/financial'
  }
]

export const headerViewNotOwner = [

  {
    id: 2,
    title: 'ORGANIZATION',
    link: '/organizations'
  },
  {
    id: 3,
    title: 'RESOURCE',
    link: '/resources'
  },

]