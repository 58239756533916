// Core
import React, {FC} from 'react';
import {Box, BoxProps, InputLabel as InputLabelM} from '@mui/material';

// Assets
import { fonts, colors } from '../../../style/themes';

// Type
interface IInputLabel extends BoxProps {
  htmlFor: string;
}

export const InputLabel: FC<IInputLabel> = ({children, htmlFor, ...props}) => {
  return (
    <Box {...props}>
      <InputLabelM
        htmlFor={htmlFor}
        sx={{
          display: 'inline-block',
          marginBottom: {xs: '0px', xl: '20px'},
          fontSize: fonts.size[300],
          fontFamily: fonts.family.primary,
          fontWeight: fonts.weight.secondary,
          color: colors.primary[400],
          cursor: 'pointer',
          float: 'left',
          '@media (max-height: 1100px)': {
            marginBottom: '0px',
          },
          '@media (min-height: 1100px)': {
            marginBottom: '15px',
          },
        }}
      >
        {children}
      </InputLabelM>
    </Box>
  );
};
