import { useEffect } from "react";
import { CreateOrganization } from "../../CreateOrganization/create-organization";

export type TCreateOrganizationPage = {
  open: boolean;
  setOpen: (value: boolean) => void;
}

export const CreateOrganizationPage = ({
  open,
  setOpen,
}: TCreateOrganizationPage) => {
  return (
    <CreateOrganization open={open} setOpen={setOpen}/>
  );
};
