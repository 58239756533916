import * as Yup from 'yup';
import YupPassword from 'yup-password';

YupPassword(Yup);

export const SchemaMemberDefault = Yup.object().shape({
  firstName: Yup.string().required('type your first name'),
  lastName: Yup.string().required('type your last name'),
  jobTitle: Yup.string().required('type your job'),
  email: Yup.string().email().required('type an valid email'),
  phone: Yup.string().required('type you phone number'),
  // password: Yup.string().password()
  //   .minNumbers(0)
  //   .minSymbols(0)
  //   .min(5)
  //   .minUppercase(1)
  //   .minLowercase(1),
  // permission: Yup.string().required('type your permission'),
})