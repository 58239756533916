import {
  Box,
  InputAdornment,
  InputBase,
  MenuItem,
  OutlinedInput,
  Select,
  styled,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { DeployStepHeading } from "./DeployStepHeading";
import { useLocation } from "react-router-dom";
import { SelectChangeEvent } from "@mui/material";
import jwt_decode from "jwt-decode";

import { UserService } from "../../../../../services/user.services";
import { WizardContext } from "../../../../../context/wizardContext";

interface TOrganizationList {
  id: string;
  name: string;
}

interface TEnterpriseList {
  id: string;
  name: string;
  orgs: Array<any>;
}

const USER_ID = localStorage.getItem("idUser") || "";
const userService = new UserService();

export const DeployNameDesc = ({ tokenUser, setSelectedOrg }: any) => {
  const [organizationList, setOrganizationList] = useState<
    Array<TOrganizationList>
  >([]);

  const [enterpriseList, setEnterpriseList] = useState<Array<TEnterpriseList>>(
    []
  );

  // @ts-ignore
  const { deployData, setDeployData } = useContext(WizardContext);

  const location = useLocation();
  const { idOrg } = location?.state || "";
  const [idOrganization, setIdOrganization] = useState<string | any>(idOrg);
  const [tokenValidate, setTokenValidate] = useState<string>(tokenUser);
  const decodedToken: any = jwt_decode(tokenUser || "");

  const BootstrapInput = styled(InputBase)(() => ({
    "& .MuiInputBase-input": {
      display: "flex",
      padding: '8px 15px',
      alignItems: "center",
      textAlign: "start",
      borderRadius: "10px",
      backgroundColor: "#FAFAFA",
      border: "1px solid #A2CBFE",
      fontWeight: "500",
      fontFamily: "Montserrat",
      fontSize: "12px",
      color: "#7B8A93",
      letterSpacing: "1px",
      "&:focus": {
        borderRadius: "10px",
      },
    },
    ".MuiSvgIcon-root": {
      right: "10px",
    },
  }));

  useEffect(() => {
    const { selectedOrg, enterpriseId, networkName } = deployData;

    if (selectedOrg && enterpriseId && networkName) {
      setDeployData({ ...deployData, buttonColor: "#1976d2", })
    } else {
      setDeployData({ ...deployData, buttonColor: "#7b8a93", })
    }

  }, [deployData.selectedOrg, deployData.enterpriseId, deployData.networkName ])

  const changeOrganization = (event: SelectChangeEvent) => {
    const orgNameSelect = event.target.value;

    const idOrgSelect = organizationList.find(
      (org) => org.name === orgNameSelect
    )?.id;

    setIdOrganization(idOrgSelect);
    setSelectedOrg(idOrgSelect);
    setDeployData({
      ...deployData,
      selectedOrg: orgNameSelect,
      orgId: idOrgSelect,
    });
  };

  const changeEnterprise = (event: SelectChangeEvent) => {
    setOrganizationList([])
    const enterpriseNameSelect = event.target.value;

    const idEnterpriseSelect = enterpriseList.find(
      (enterprise) => enterprise.name === enterpriseNameSelect
    )?.id;

    const orgsSelected = enterpriseList.find(
      (enterprise) => enterprise.name === enterpriseNameSelect
    )?.orgs;

    setDeployData({
      ...deployData,
      selectedEnterprise: enterpriseNameSelect,
      enterpriseId: idEnterpriseSelect,
    });

    if (orgsSelected) {
      const respFilter = orgsSelected.filter(
        (orgs: any) => orgs.permission !== 'stakeholder'
      )

      respFilter.map((org: any) => {
        setOrganizationList((oldArray) => [
          ...oldArray,
          { id: org.id, name: org.name },
        ]);
      });
    }
  };

  const getOrgList = async () => {
    try {
      const respOrganizations = await userService.getUserOrgList(
        decodedToken.sub,
        tokenValidate
      );
      if (respOrganizations.data.length) {
        const respFilter = respOrganizations.data.filter(
          (orgs: any) => orgs.permission !== 'stakeholder'
        )
        respFilter.map((org: any)=>{
          setOrganizationList(oldArray => [...oldArray, {id: org.id, name: org.name}]);
        })
      }
    } catch (error) {}
  };

  const getEnterpriseList = async () => {
    try {
      const respEnterprise = await userService.getUserEnterpriseList(
        decodedToken.sub,
        tokenValidate
      );
      if (respEnterprise.data.length) {
        respEnterprise.data.map((ent: any)=>{
          setEnterpriseList(oldArray => [...oldArray, {id: ent.id, name: ent.name, orgs: ent.orgs}]);
        })
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (deployData.selectedEnterprise) {
      getOrgList();
    }
    getEnterpriseList();
  }, []);

  return (
    <Box>
      <DeployStepHeading
        title="Name and Description"
        description="Select the organization responsable for your network and the protocol you need to run on"
      />
      <Box>
        <Typography
          fontFamily="Montserrat"
          fontWeight="600"
          color="#3E474D"
          fontSize={"13px"}
          sx={{
            paddingBottom: "10px",
          }}
        >
          Enterprise
        </Typography>
        <Box>
          <Select
            fullWidth
            defaultValue={1}
            input={<BootstrapInput />}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            variant="standard"
            value={deployData.selectedEnterprise}
            label="Age"
            onChange={(e) => changeEnterprise(e)}
            endAdornment={
              <InputAdornment
                position="end"
                sx={{
                  position: "absolute",
                  right: "40px",
                }}
              >
                <span
                  style={{
                    transform: "rotate(180deg)",
                    display: "block",
                    borderRight: "1px solid #A2CBFE",
                    height: "40px",
                  }}
                ></span>
              </InputAdornment>
            }
            sx={{
              ":before": {
                borderRight: "1px solid #A2CBFE",
                height: "100%",
              },
            }}
          >
            {enterpriseList.map(({ id, name }) => (
              <MenuItem key={id} value={name}>
                {name.slice(4)}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Box></Box>
      <Box sx={{ paddingTop: "31.41px" }}>
        <Typography
          fontFamily="Montserrat"
          fontWeight="600"
          color="#3E474D"
          fontSize={"13px"}
          sx={{
            paddingBottom: "10px",
          }}
        >
          Organization
        </Typography>
        <Box>
          <Select
            fullWidth
            defaultValue={1}
            input={<BootstrapInput />}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            variant="standard"
            value={deployData.selectedOrg}
            label="Age"
            onChange={(e) => changeOrganization(e)}
            endAdornment={
              <InputAdornment
                position="end"
                sx={{
                  position: "absolute",
                  right: "40px",
                }}
              >
                <span
                  style={{
                    transform: "rotate(180deg)",
                    display: "block",
                    borderRight: "1px solid #A2CBFE",
                    height: '40px'
                  }}
                ></span>
              </InputAdornment>
            }
            sx={{
              ":before": {
                borderRight: "1px solid #A2CBFE",
                height: "100%",
              },
            }}
          >
            {organizationList.length > 0 && organizationList.map(({ id, name }) => (
              <MenuItem key={id} value={name}>
                {name.slice(4)}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Box>
        <Typography
          fontFamily="Montserrat"
          fontWeight="600"
          color="#3E474D"
          fontSize={"13px"}
          sx={{
            paddingTop: "31.41px",
            paddingBottom: "10px",
          }}
        >
          Network Name
        </Typography>
        <OutlinedInput
          placeholder="e.g NetworkName (no spaces)"
          type="string"
          value={deployData.networkName}
          onChange={(e) =>
            setDeployData({
              ...deployData,
              networkName: e.target.value.replace(' ', '').replace(/[^\w\s]/gi, '').replace('_',''),
            })
          }
          sx={{
            width: "470px",
            height: "40px",
            border: "none",
            backgroundColor: "#FAFAFA",
            borderRadius: "10px",
            padding: "0",
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
          }}
        />
      </Box>
    </Box>
  );
};
