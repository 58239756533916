import { Card, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import { resourceMenu } from "./resoureceMenu";

interface IResourceMenu {
  id: number;
  title: string;
  link: string;
}

export const DeployHeader = () => {
  const numRecuperado = parseInt(localStorage.getItem("menuClick") || "");
  const [selectedItem, setSelectedItem] = useState<any>(
    numRecuperado ? numRecuperado : 3
  );

  const handleItemClick = (idMenu: number) => {
    localStorage.setItem("menuClick", idMenu.toString());
    setSelectedItem(idMenu);
  };

  return (
    <Grid
      container
      md={12}
      sx={{
        backgroundColor: "#2E57A3",
        paddingLeft: "581px",
        paddingRight: "692px",
        height: "35.35px",
      }}
    >
      <Grid item md={12} sx={{ paddingTop: "0.21px", display: "flex", flexWrap: 'nowrap', justifyContent: 'space-between' }}>
        {resourceMenu.map((value: IResourceMenu, index: number) => (
          <Card
            onClick={() => handleItemClick(value.id)}
            key={value.id}
            sx={{
              boxShadow: "none",
              backgroundColor:
                selectedItem === value.id
                  ? "rgba(220, 232, 242, .1)"
                  : "#2E57A3",
              width: "162px",
              minWidth: "92px",
              transition: "background-color 0.3s ease-in-out",
            }}
          >
            <Link to={value.link}>
              <Typography
                color="#FAFAFA"
                lineHeight="35px"
                fontFamily="Montserrat"
                fontSize={selectedItem === value.id ? "16px" : "13px"}
                sx={{ transition: "font-size 0.3s ease-in-out" }}
                fontWeight={"400px"}
                textAlign="center"
              >
                {value.title}
              </Typography>
            </Link>
          </Card>
        ))}
      </Grid>
    </Grid>
  );
};
