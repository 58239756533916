import React, { FC, useEffect, useState, useContext } from 'react';

import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { WizardContext } from "../../../context/wizardContext";

import { styled } from '@mui/material/styles';
import { fonts, colors } from '../../../style/themes';
import { IUsageAmount, IResponse } from '../../../type';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: '13px !important',
  borderRadius: 20,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#CAD8E6',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: '5px !important',
    backgroundColor: '#0096FF !important',
  },
}));

interface IDataFinancial {
  totalValue: number
}

export const UsageAmount: FC = () => {
  // @ts-ignore
  const { dataFinancial } = useContext<IDataFinancial>(WizardContext);
  const [data2Value, setData2Value] = useState<IUsageAmount | null>(null)

  if (dataFinancial) {
    return (
      <Paper
        sx={{
          boxSizing: 'border-box',
          padding: '14px 12px',
          boxShadow: 'none',
          background: '#F1F3F4',
          border: '1px solid #CBD5E1',
          borderRadius: '30px',
          height: '336px'
        }}
      >
        <Typography
          sx={{
            paddingLeft: '13px',
            fontSize: fonts.size[300],
            fontFamily: fonts.family.secondary,
            fontWeight: fonts.weight.secondary,
            color: colors.secondary[500],
          }}
        >
          USAGE AMOUNT
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="start"
          sx={{ padding: '45px 22px 0 36px' }}
        >
          <Grid
            item
            lg={6}
            justifyContent="center"
            alignItems="start"
          >
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '11px',
                fontSize: fonts.size[80],
                fontFamily: fonts.family.secondary,
                fontWeight: fonts.weight.primary,
                color: colors.secondary[600],
              }}
            >
              <SvgIcon viewBox="-3 -3 24 24">
                <circle cx="9" cy="9" r="9" fill="#1781E2" />
              </SvgIcon>
              CURRENT VALUE
            </Typography>
            <Typography
              sx={{
                marginTop: '32px',
                fontSize: fonts.size[700],
                fontFamily: fonts.family.secondary,
                fontWeight: fonts.weight[600],
                color: colors.secondary[600],
              }}
            >
              {dataFinancial.totalValue.toLocaleString('pr-BR', {
                style: 'currency',
                currency: 'BRL'
              })}
            </Typography>
          </Grid>
          <Grid
            item
            lg={6}
            justifyContent="center"
            alignItems="start"
          >
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '11px',
                fontSize: fonts.size[80],
                fontFamily: fonts.family.secondary,
                fontWeight: fonts.weight.primary,
                color: colors.secondary[600],
              }}
            >
              <SvgIcon viewBox="-3 -3 24 24">
                <circle cx="9" cy="9" r="9" fill="#CAD8E6" />
              </SvgIcon>
              ESTIMATED VALUE
            </Typography>
            <Typography
              sx={{
                marginTop: '32px',
                fontSize: fonts.size[700],
                fontFamily: fonts.family.secondary,
                fontWeight: fonts.weight[600],
                color: colors.secondary[600],
              }}
            >
              {dataFinancial.totalEstimateValue.toLocaleString('pr-BR', {
                style: 'currency',
                currency: 'BRL'
              })}
            </Typography>
          </Grid>
        </Grid>
        <Box
          sx={{ padding: '45px 22px 0 36px' }}
        >
          <BorderLinearProgress
            variant="determinate"
            value={!!dataFinancial.totalEstimateValue ? (dataFinancial.totalValue / dataFinancial.totalEstimateValue * 100) : 0} />
        </Box>
      </Paper>
    );
  } else {
    return (
      <Skeleton
        variant="rectangular"
        sx={{
          width: '100%',
          height: '336px',
          boxSizing: 'border-box',
          border: '1px solid #CBD5E1',
          borderRadius: '30px',
        }} />
    )
  }
};
