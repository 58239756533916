export const Hamburguer: React.FC = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M32 9H4C3.44772 9 3 8.55228 3 8C3 7.44772 3.44772 7 4 7H32C32.5523 7 33 7.44772 33 8C33 8.55228 32.5523 9 32 9ZM32 19H4C3.44772 19 3 18.5523 3 18C3 17.4477 3.44772 17 4 17H32C32.5523 17 33 17.4477 33 18C33 18.5523 32.5523 19 32 19ZM4 29H32C32.5523 29 33 28.5523 33 28C33 27.4477 32.5523 27 32 27H4C3.44772 27 3 27.4477 3 28C3 28.5523 3.44772 29 4 29Z"
      fill="#737373"
    />
  </svg>
);
